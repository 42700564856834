import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffd700',
        },
        secondary: {
            main: '#A3BCFF',
            contrastText: '#000000'
        },

        neutral: {
            greyLight: '#ddd',
            black: '#000000',
            primary90: '#2a2a2c',
            primary80: '#58585a',
            primary60: '#7f7f7f',
            primary40: '#cccccc',
            primary20: '#dedede',
            primary10: '#f6f7f8',
            primary0: '#ededed',
            white: '#ffffff',
        },

        background: {
            default: '#f6f7f8',
        }
    },

    spacing: 4,

    typography: {
        fontFamily: [
            'FrutigerNext',
            'FrutigerNext-Bd',
            'Roboto',
            'sans-serif',
        ].join(','),

        h1: {
            fontSize: '24px',
            fontFamily: 'FrutigerNext-Bd',
            lineHeight: "1.25",
            color: '#2a2a2c'
        },
        labelRegular: {
            fontSize: '14px',
            fontFamily: 'FrutigerNext',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: 0.3,
            color: '#767676'
        },
        bodyText: {
            fontSize: '14px',
            fontFamily: 'FrutigerNext',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: 0.3,
            color: '#000'
        },
        subtitle1: {
            fontSize: '14px',
            fontFamily: 'FrutigerNext',
            lineHeight: 1,
            fontWeight: 700,
            letterSpacing: 0.3,
            color: '#000000'
        },
        subtitle2: {
            fontSize: '13px',
            fontFamily: 'FrutigerNext',
            lineHeight: '13px',
            letterSpacing: 0.3,
            color: '#58585a'
        },
        subtitle3: {
            fontSize: '13px',
            fontFamily: 'FrutigerNext',
            lineHeight: '13px',
            letterSpacing: 0.3,
            color: '#89898c'
        },
        body1: {
            fontSize: '13px',
            fontFamily: 'FrutigerNext-Bd',
            lineHeight: 1,
            color: '#000'
        },
        body2: {
            fontSize: '13px',
            fontFamily: 'FrutigerNext',
            lineHeight: 1,
            letterSpacing: 0.3,
            color: '#ccc'
        },
        button: {
            fontSize: '13px',
            fontFamily: 'FrutigerNext-Bd',
            color: '#000',
            textTransform: 'none'
        }
    },

    components: {
        MuiOutlinedInput: { // oder MuiOutlinedInput, je nachdem, welches Element genau Sie stylen möchten
            styleOverrides: {
                root: {
                    borderRadius: "2px",
                    fontFamily: 'FrutigerNext',
                    fontSize: '13px',
                    backgroundColor: '#ffffff',
                },
                input: {
                    padding: '13px 12px',
                    fontWeight: 400,
                    color: '#000000 !important',
                },
                notchedOutline: {
                    //borderColor: theme.palette.neutral.primary40,
                }
            }
        }
    }
});

export default theme;
