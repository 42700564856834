import axios from "axios";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const getStacking = createAsyncThunk('stacking/get', async ({projectId}) => {
    try {
        console.log('Get stacking: ', projectId)
        return await axios.get(`/api/projects/${projectId}/stacking`)
    } catch (err) {
        return err.message
    }
})

export const updateStacking = createAsyncThunk('stacking/updateStacking', async ({projectId, stacking}) => {
    try {
        console.log('Update stacking: ', projectId, stacking)
        return await axios.put(`/api/projects/${projectId}/stacking`, stacking)
    } catch (err) {
        return err.message
    }
})

const stackingSlice = createSlice({
    name: 'stacking',
    initialState: {
        stacking: [],
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getStacking.pending, (state) => {
                state.loading = true
            })
            .addCase(getStacking.fulfilled, (state, {payload}) => {
                state.stacking = payload.data
                state.loading = false
            })
            .addCase(getStacking.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateStacking.pending, (state) => {
                state.loading = true
            })
            .addCase(updateStacking.fulfilled, (state, {payload}) => {
                state.stacking = payload.data
                state.loading = false
            })
            .addCase(updateStacking.rejected, (state) => {
                state.loading = false
            })
    }
})

export default stackingSlice.reducer