import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import StoTextInput from "./StoTextInput";
import {makeStyles} from "@mui/styles";
import {ArrowDropDown} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {updateSinglePanelTypePricing} from "../../pricingLevel/pricingSlice";

const useStyle = makeStyles(() => ({
    root: {
        display: 'grid',
        //paddingBottom: '2rem',
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        },
        width: '80rem',

    },
    box: {
        display: 'grid',

        gridTemplateColumns: '1fr 1fr 1fr',
        justifyContent: 'center',
        "&.MuiButtonBase-root-MuiAccordionSummary-root": {
            alignItems: 'flex'
        }

    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        height: '55px',
    },
    focusVisible: {
        backgroundColor: 'white!important',
    },
    backup: {
        paddingTop: '0.5rem',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: '50px',
        paddingBottom: '1rem'
       // paddingLeft: '7px'
    },

}));

function useForceUpdate() {
    //eslint-disable-next-line
    const [value, setValue] = React.useState(0);
    return () => setValue(value => value + 1);
}

function StoPricingMenu({name, projectId, unit, key, basePriceParent, isAccordion, id, ...props}) {

    const classes = useStyle({...props.theme});
    const priceTypes = useSelector(state => state.pricing.panelTypePrices).find(type => type.id === id).lookTypePrices
    const basePriceDB = useSelector(state => state.pricing.panelTypePrices).find(type => type.id === id).basePrice.toString()
    const isBackupPanel = name === 'StoPanel BackupPanel'
    const [price, setPrice] = useState('0')
    const [basePrice, setBasePrice] = useState(basePriceDB || 0)
    const dispatch = useDispatch()
    const [timer, setTimer] = React.useState(null)
    const forceUpdate = useForceUpdate()

    useEffect(() => {
        const t = priceTypes.find(type => type.lookTypeName === name)
        if (t !== undefined) {
            setPrice(t.additionallyPrice.toString())
        }
        if (isAccordion) {
            setBasePrice(basePriceDB)
        }

        //eslint-disable-next-line
    }, [priceTypes, basePriceDB]);

    const handlePriceChange = (name, price) => {
        clearTimeout(timer)
        if (name === 'base' || name === 'StoPanel BackupPanel') {
            setBasePrice(price)
            forceUpdate()
        } else {
            setPrice(price)
        }
        const newTimer = setTimeout(() => {
            dispatch(updateSinglePanelTypePricing({
                projectId: projectId,
                panelTypeId: id,
                panelType: name,
                panelTypePrice: price
            }))

        }, 500)
        setTimer(newTimer)
    }
    const getTotalPrice = () => {
        if (price === undefined && basePriceParent !== undefined) {
            if (!isNaN(Number(basePrice.replace(',', '.')))) {
                return basePrice
            } else {
                return 0
            }
        }
        if (basePriceParent === undefined && price !== undefined) {
            if (!isNaN(Number(price.replace(',', '.')))) {
                return price
            } else {
                return 0
            }
        }
        if (basePriceParent === undefined && price === undefined) {
            return 0
        }
        if (isNaN(Number(price)) || isNaN(Number(basePriceParent))) {
            return 0
        } else {
            return (Number(price.replace(',', '.')) + Number(basePriceParent.replace(',', '.')))
        }
    }
    const getUnit = () => {
        if (unit === 'IMPERIAL') {
            return 'per sft'
        } else {
            return 'per \u33A1'
        }

    }

    const getContent = () => {
        return "Total Price: " + getTotalPrice().toFixed(2) + " $ " + getUnit()
    }
    return (
        (isAccordion) ? (
            (isBackupPanel) ? (
                <div className={classes.backup}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography width={'25rem'} paddingLeft={'8px'} variant={'h5'}>{name}</Typography>
                        <Typography variant={'body2'} width={'5rem'} textAlign={'right'} paddingRight={'1rem'}>Base Price</Typography>

                        <StoTextInput title={''} padding={'5px'} alignment={'right'} width={'7rem'} endText={'$'} value={basePrice}
                                      maxLength={7}
                                      placeholder={'0.00'}
                                      onChange={(event) => handlePriceChange(name, event.target.value)}></StoTextInput>
                        <Typography variant={'body2'} paddingLeft={'1rem'}>{getUnit()}</Typography>
                    </div>
                </div>
            ) : (
                <Accordion className={classes.root} elevation={0} disableGutters={true}>

                    <AccordionSummary
                        expandIcon={<ArrowDropDown/>}
                        aria-controls={'panel-content' + key}
                        id={'panel-header' + key}
                        focusVisibleClassName={classes.focusVisible}
                        style={{height: '6rem'}}
                    >
                        <div className={classes.box}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography width={'25rem'} variant={'h5'}>{name} </Typography>
                                <Typography variant={'body2'} width={'5rem'} textAlign={'right'} paddingRight={'1rem'}>Base Price</Typography>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <StoTextInput title={''} padding={'5px'} alignment={'right'} width={'7rem'} endText={'$'} maxLength={7}
                                              placeholder={'0.00'}
                                              value={basePrice}
                                              onChange={(event) => handlePriceChange('base', event.target.value)}
                                              onClick={event => event.stopPropagation()}></StoTextInput>
                                <Typography variant={'body2'} paddingLeft={'1rem'}>{getUnit()} </Typography>
                            </div>
                        </div>

                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            priceTypes.map((type, i) => {
                                return <StoPricingMenu isAccordion={false} projectId={projectId}
                                                       name={type.lookTypeName} unit={unit}
                                                       key={i}
                                                       basePriceParent={basePrice}
                                                       id={id}></StoPricingMenu>

                            })}
                    </AccordionDetails>

                </Accordion>
            )) : (
            <div className={classes.innerBox}>
                <Typography width={'30rem'} variant={'h5'} style={{textAlign: 'right'}}>{name}</Typography>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <StoTextInput title={''} alignment={'right'} padding={'1rem 1rem 1rem 1.5rem'} width={'7rem'} endText={'$'} placeholder={'0.00'}
                                  value={price} maxLength={7}
                                  onChange={(event) => handlePriceChange(name, event.target.value)}></StoTextInput>
                    <Typography variant={'body2'} paddingLeft={'1rem'}>{getUnit()}</Typography>
                    <Typography variant={'subtitle3'} paddingLeft={'3rem'}
                                textAlign={'left'}>{getContent()}</Typography>
                </div>
            </div>
        ))
}

export default StoPricingMenu