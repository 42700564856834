// StoRoundCheckboxGroup.js
import React, {useEffect, useState} from 'react';
import StoRoundCheckbox from './StoRoundCheckbox';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    checkboxContainer: {
        display: 'flex',
        height: '3.2rem',
        gap: '1rem',
    }
}))

const StoRoundCheckboxGroup = ({
                                   options, initialOptions = [], onChange = () => {
    }
                               }) => {
    const classes = useStyles();

    initialOptions = initialOptions ? initialOptions : []

    const [activeOptions, setActiveOptions] = useState(initialOptions)

    useEffect(() => {
        setActiveOptions(initialOptions)
    }, [initialOptions])

    // Function to handle checkbox state changes
    function handleCheckboxChange(option) {
        const newOptions = activeOptions.includes(option)
            ? activeOptions.filter(item => item !== option)
            : [...activeOptions, option]

        setActiveOptions(newOptions)
        onChange(newOptions)
    }

    return (
        <div className={classes.checkboxContainer}>
            {
                options.map((option, index) =>
                    <StoRoundCheckbox
                        key={index}
                        title={option}
                        initialChecked={initialOptions.includes(option)}
                        onCheckboxChange={(checked) => handleCheckboxChange(option, checked)}
                    />
                )
            }
        </div>
    );
};

export default StoRoundCheckboxGroup;
