import MouldingPreview from "./MouldingPreview";
import {IconButton, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {MeasurementUtils} from "../../utils/MeasurementUtils";
import EditIcon from "../../utils/icons/edit.svg";
import DeleteIcon from "../../utils/icons/trash.svg";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {deleteMouldingPresetFromCompany} from "../../mouldingType/mouldingTypeSlice";

const useStyle = makeStyles((theme) => ({

    nameDefault: {
        color: theme.palette.neutral.primary10,
        justifyContent: 'space-between',
        height: '25px'
    },
    nameAdmin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

    },

    icon: {
        width: '20px',
        height: '20px',

    }
    ,
    nameUser: {
        justifyContent: 'space-between',

    },
    content: {
        display: 'flex',
    },
    info: {
        paddingTop: '4px',
        paddingLeft: '10px',

    }
}));

function MouldingPreset({previewItem, companyId, width, height, unitSystem, isAdmin, itemForEdit, ...props}) {
    const classes = useStyle(props.theme);
    const [showIcons, setShowIcons] = useState(false)
    const dispatch = useDispatch()

    const getUnit = () => {
        if (unitSystem === MeasurementUtils.IMPERIAL) {
            return 'feet'
        } else {
            return 'cm'
        }
    }

    const deletePreset = (event) => {
        event.stopPropagation()
        console.log(previewItem)
        dispatch(deleteMouldingPresetFromCompany({companyId: companyId, mouldingTypeId: previewItem.id}));
    }

    const editPreset = (event) => {
        event.stopPropagation()
        itemForEdit(previewItem)
    }

    return (
        <div onMouseEnter={() => setShowIcons(true)} onMouseLeave={() => setShowIcons(false)}>
            <div className={`${classes.nameDefault} ${isAdmin ? classes.nameAdmin : classes.nameUser}`}>
                <Typography>{previewItem.name}</Typography>
                <div>
                    {(isAdmin && showIcons) && <div style={{justifyContent: 'space-between'}}>
                        <IconButton size="small" className={classes.icon} style={{paddingRight: '20px'}}>
                            <img src={EditIcon} alt={'edit preset'} onClick={(event) =>  editPreset(event)}/>
                        </IconButton>
                        <IconButton size="small" className={classes.icon}>
                            <img src={DeleteIcon} alt={'delete preset'} onClick={(event) => deletePreset(event)}/>
                        </IconButton>
                        </div>
                    }
                </div>

            </div>
                <div className={classes.content}>
                    <MouldingPreview previewItem={previewItem} width={width} height={height}></MouldingPreview>
                    <div className={classes.info}>
                        <Typography style={{paddingBottom: '2px'}}
                                    variant={'subtitle2'}>Depth: {previewItem.depth} {getUnit()}</Typography>
                        <Typography variant={'subtitle2'}>Cost / {getUnit()}: {previewItem.level1Cost} $</Typography>
                    </div>
                </div>
            </div>
            )

            }


export default MouldingPreset
