import React from 'react'
import {getArea, getLength, nicerNumber, selectText} from './Helpers'
import './export.css'


export const ExportProjectData = (props) => {

    const exportData = props.data
    const elevation = props.elevation
    console.log(exportData.pricingSummary)

    const result = elevation === "building" ? exportData.panelizationResult : exportData.panelizationResult.elevationResults.find(r => r.location === elevation)

    /*function getPanelizerArea(){
        let area = 0
        for (let i = 0; i< result.panelTypes.length; i++) {
            area += result.panelTypes[i].panelArea
        }
        return area
    }*/

    function getLinearLength() {
        return result.panelWidth *2 + result.slabWidthBehindMultiStoryPanel
    }

    const title = elevation === "building" ? "Project Data" : "Elevation Data"

    let resultPrice = 0
    if (exportData.settings.pricingLevel === "LEVEL_1")
        resultPrice = elevation === "building" ? exportData.pricingSummary.result[0].summaries[0].price :
            exportData.pricingSummary.pricingSummaryElevations.find(r => r.location === elevation).result[0].summaries[0].price
    else
        resultPrice = elevation === "building" ? exportData.pricingSummary.result[3].summaries[0].price :
            exportData.pricingSummary.pricingSummaryElevations.find(r => r.location === elevation).result[3].summaries[0].price

    return (
        <div>
            <table id="projectTable" className={"export-table"}>
                <tbody>
                <tr>
                    <td colSpan="2" className="infoblockTitle copytable" onMouseDown={() => selectText("projectTable")} >
                        {title}
                    </td>
                </tr>
                <tr>
                    <td className="tlabel"><b>Total Price</b></td>
                    <td className="tcontent"><b>{nicerNumber(resultPrice, 2)} $</b></td>
                </tr>
                <tr>
                    <td className="tlabel">Panel Count</td>
                    <td className="tcontent">{result.amountOfPanels}</td>
                </tr>
                <tr>
                    <td className="tlabel">Days needed on site</td>
                    <td className="tcontent">{Math.round(result.daysNeeded)}</td>
                </tr>
                <tr>
                    <td className="tlabel">Connection Linear Length</td>
                    <td className="tcontent">{getLength(getLinearLength(), exportData.unitSystem)}</td>
                </tr>
                <tr>
                    <td className="tlabel" colSpan={2}><b>Areas</b></td>

                </tr>
                <tr>
                    <td className="tlabel">Elevation Area</td>
                    <td className="tcontent">{getArea(result.areaCovered, exportData.unitSystem)}</td>
                </tr>
                <tr>
                    <td className="tlabel">Panel Side Areas</td>
                    <td className="tcontent">{getArea(result.panelSidesArea, exportData.unitSystem)}</td>
                </tr>
                <tr>
                    <td className="tlabel"><b>Total Elevation Area</b></td>
                    <td className="tcontent"><b>{getArea(result.totalElevationArea, exportData.unitSystem)}</b></td>
                </tr>
                <tr>
                    <td className="tlabel">Panel Side Areas</td>
                    <td className="tcontent">- {getArea(result.panelSidesArea, exportData.unitSystem)}</td>
                </tr>

                {result.fieldAppliedArea > 0.008 && (
                    <tr>
                        <td className="tlabel">Field Applied Area</td>
                        <td className="tcontent">- {getArea(result.fieldAppliedArea, exportData.unitSystem)}</td>
                    </tr>
                )}
                {result.hiddenFaces > 0.008 && (
                    <tr>
                        <td className="tlabel">Hidden Faces</td>
                        <td className="tcontent">- {getArea(result.hiddenFaces, exportData.unitSystem)}</td>
                    </tr>
                )}
                <tr>
                    <td className="tlabel"><b>Panelface Area</b></td>
                    <td className="tcontent"><b>{getArea(result.panelizedAreaInclHiddenFaces-result.hiddenFaces, exportData.unitSystem)}</b></td>
                </tr>
                <tr>
                    <td className="tlabel">Panel Cutout Area</td>
                    <td className="tcontent">- {getArea(result.openingArea, exportData.unitSystem)}</td>
                </tr>
                <tr>
                    <td className="tlabel">Panel Side Areas</td>
                    <td className="tcontent">{getArea(result.panelSidesArea, exportData.unitSystem)}</td>
                </tr>
                <tr>
                    <td className="tlabel"><b>Total Finish Area</b></td>
                    <td className="tcontent"><b>{getArea(result.finishArea, exportData.unitSystem)}</b></td>
                </tr>



                </tbody>
            </table>
        </div>
    );
};