import {makeStyles} from "@mui/styles";
import React from "react";
import {viewTypes} from "../view/viewSlice";
import ClientInfo from "../clientInfo/clientInfo";
import ProjectInfo from "../projectInfo/projectInfo";
import PricingLevel from "../pricingLevel/components/pricingLevel";
import PanelTypes from "../panelType/panelTypes";
import Dimension from "../settings/dimension";
import PanelSettings from "../settings/panelSettings";
import SchedulingSettings from "../scheduling/Settings/SchedulingSettings";
import Scheduling from "../scheduling/Scheduling";
import PricingSummary from "../pricingSummary/components/pricingSummary";
import {useSelector} from "react-redux";
import Stacking from "../stacking/Stacking";
import CsvExportSetup from "../exportCsv/components/CsvExportSetup";

const useStyle = makeStyles(theme => ({
    root: {
        height: 'calc(100vh - 100px)',
        position: 'absolute',
        top: '100px',
        padding: '0',
        overflowY: 'clip',
        "&::-webkit-scrollbar": {
            margin: '1rem',
            width: "1rem"
        },
        "&::-webkit-scrollbar-thumb": {
            background: theme.palette.primary.main,
        },
    },
    content: {
        background: theme.palette.neutral.white,
        width: '100%',
        height: '100%',
        textAlign: 'left',
        boxSizing: 'border-box',
        overflowY: 'auto',
        "&::after": {
            minWidth: "1rem",
            height: "10rem",
            content: '""arn star'
        }
    },
    contentPricing: {
        background: '#ffffff',
        width: '100%',
        height: '100%',
        paddingTop: '0',
        paddingLeft: '0',
        textAlign: 'left',
        boxSizing: 'border-box',
        "&::after": {
            minWidth: "1rem",
            height: "10rem",
            content: '""arn star'
        }
    },
}))

function UnityOverlay(props) {
    const classes = useStyle(props.theme);

    const view = useSelector(state => state.view.currentView)
    const isMainScreen = useSelector(state => state.unity.isMainScreen)

    function getContent() {
        switch (view) {
            case viewTypes.ClientInfo: {
                return <div className={classes.content}><ClientInfo/></div>
            }
            case viewTypes.ProjectInfo: {
                return <div className={classes.content}><ProjectInfo/></div>
            }
            case viewTypes.PricingLevel: {
                return <PricingLevel/>
            }
            case viewTypes.SettingsPanelTypes: {
                return <div className={classes.content}><PanelTypes/></div>
            }
            case viewTypes.Dimensions: {
                return <div className={classes.content}><Dimension/></div>
            }
            case viewTypes.Stacking: {
                return isMainScreen ? null : <Stacking/>
            }
            case viewTypes.PanelSettings: {
                return <PanelSettings/>
            }
            case viewTypes.SchedulingSettings: {
                return <SchedulingSettings/>
            }
            case viewTypes.SchedulingPlanning: {
                return <Scheduling/>
            }
            case viewTypes.PricingSummary: {
                return <PricingSummary/>
            }
            case viewTypes.CsvExportSetup: {
                return <CsvExportSetup/>
            }
            default:
                return null
        }
    }

    const content = getContent()

    const paddingLeft = view === viewTypes.SchedulingSettings || view === viewTypes.SchedulingPlanning || view === viewTypes.PricingLevel
        ? '0rem'
        : '4.5rem'
    const paddingTop = view === viewTypes.SchedulingSettings || view === viewTypes.SchedulingPlanning || view === viewTypes.PricingLevel
        ? '0rem'
        : '5.8rem'
    const width = view === viewTypes.SchedulingPlanning
        ? '100%'
        : 'calc(100vw - 360px)'

    return content ?
        <div className={classes.root} style={{width:width}}>
            <div className={classes.content} style={{
                paddingLeft: paddingLeft,
                paddingTop: paddingTop
            }}>
                {content}
            </div>
        </div> : null
}

export default UnityOverlay;