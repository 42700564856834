import {makeStyles} from "@mui/styles";
import {Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography} from "@mui/material";
import React from "react";
import {ArrowDropDown} from "@mui/icons-material";
import StoPricingItem from "./StoPricingItem";
import infoIcon from "../../utils/icons/info.svg"


const useStyle = makeStyles(() => ({
    root: {
        display: 'grid',
        //paddingBottom: '2rem',
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        },
        //width: '80rem',
    },
    box: {
        display: 'grid',

        gridTemplateColumns: '1fr 1fr 1fr',
        justifyContent: 'center',
        "&.MuiButtonBase-root-MuiAccordionSummary-root": {
            alignItems: 'flex'
        }

    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        paddingLeft: '1.5em',
    },
    focusVisible: {
        backgroundColor: 'red !important',

    },
    backup: {
        paddingTop: '0.5rem',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: '50px',
        paddingBottom: '1rem'
        // paddingLeft: '7px'
    },
    level3: {},
    pricingCategoryFont: {
        fontFamily: 'FrutigerNext-Bd',
        fontSize: '16px !important',
        display: 'flex',
        alignItems: 'center',
    },
    tooltipIcon: {
        marginLeft: '0.5rem',
    },
    pricingItemFont: {

        fontSize: '16px !important',
        fontFamily: 'FrutigerNext !important',
        fontWeight: '400 !important'

    },
    placeHolder: {
        width: '16rem',
        height: '1rem',
    }

}));

function StoPricingCategory({category, key, tooltips, ...props}) {
    const classes = useStyle({...props.theme});


    return (
        <Accordion className={classes.root} elevation={0} disableGutters={true}>
            <AccordionSummary
                expandIcon={<ArrowDropDown/>}
                aria-controls={'panel-content' + key}
                id={'panel-header' + key}
                focusVisibleClassName={classes.focusVisible}
                style={{height: '6rem', flexDirection: 'row-reverse'}}
            >
                <div className={classes.box}>

                    <Typography width={'25rem'} paddingLeft={'8px'}
                                className={classes.pricingCategoryFont}>{category.name ? category.name : "Field application"}

                        {tooltips && tooltips.map((tooltip, i) => {
                            if (tooltip.id === category.id) {

                                const toolTipContent = [];

                                if (tooltip.referenceUnit) {
                                    toolTipContent.length > 0 && toolTipContent.push(<br/>);
                                    toolTipContent.push("Reference unit: " + tooltip.referenceUnit);
                                }
                                if (tooltip.text) {
                                    toolTipContent.length > 0 && toolTipContent.push(<br/>);
                                    toolTipContent.push(tooltip.text);
                                }

                                return (
                                    <span key={i}>
                                    <Tooltip title={<><h1>{tooltip.title}</h1><span
                                        className={"tooltipText"}>{toolTipContent}</span></>} arrow={true}
                                             placement={"bottom-start"}>
                                        <img src={infoIcon} className={classes.tooltipIcon} alt={tooltip.title}/>
                                    </Tooltip></span>
                                )
                            }
                            return null
                        })}

                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>

                {category.returnDepth &&
                    <div><StoPricingItem category={category}/></div>
                }

                {category.pricingItems && category.pricingItems.length > 0 && category.pricingItems.map((cat, i) => {

                    return (
                        <div key={i}>
                            <StoPricingItem category={cat} tooltips={tooltips}
                                            backgroundColor={i % 2 === 0 ? '#ffffff' : 'rgba(244, 246, 247, 0.50)'}/>
                        </div>
                    )
                })}
            </AccordionDetails>
        </Accordion>


    );
}

export default StoPricingCategory;