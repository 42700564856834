import {
    getExactAmountOfWorkingDay,
    getEndDateByDurationAndWorkingDays3,
    getStartDateByDurationAndWorkingDays3
} from "../../utils/timeUtils";

export function updateTasksExpandStart(tasks, factor, start, workingDays) {
    tasks.forEach(task => {
        updateTasksExpandStart(task.subTasks, factor, start, workingDays)
        start = updateTaskExpandStart(task, factor, start, workingDays)
    })
}

function updateTaskExpandStart(task, factor, start, workingDays) {
    const duration = getExactAmountOfWorkingDay(task.start, task.end, workingDays)

    task.duration = duration * factor
    task.start = start
    task.end = getEndDateByDurationAndWorkingDays3(start, task.duration, workingDays)
    return task.end
}

export function updateTasksExpandEnd(tasks, factor, end, workingDays) {
    for (let i = tasks.length - 1; i >= 0; i--) {
        const task = tasks[i]
        updateTasksExpandEnd(task.subTasks, factor, end, workingDays)
        end = updateTaskExpandEnd(task, factor, end, workingDays)
    }
}

function updateTaskExpandEnd(task, factor, end, workingDays) {
    const duration = getExactAmountOfWorkingDay(task.start, task.end, workingDays)

    task.duration = duration * factor
    task.end = end
    task.start = getStartDateByDurationAndWorkingDays3(end, task.duration, workingDays)
    return task.start
}