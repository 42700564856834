import {makeStyles} from "@mui/styles";
import {Typography} from "@mui/material";
import React from "react";
import StoInput from "../../utils/components/StoInput";
import {useDispatch} from "react-redux";
import {updatePricingSummaryItem} from "../pricingSummarySlice";
import {useParams} from "react-router-dom";


const useStyle = makeStyles((theme) => ({
    root: {
        display: 'grid',
        //paddingBottom: '2rem',
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        },
        //width: '80rem',


    },
    box: {
        display: 'grid',

        gridTemplateColumns: '1fr 1fr 1fr',
        justifyContent: 'center',
        "&.MuiButtonBase-root-MuiAccordionSummary-root": {
            alignItems: 'flex'
        }

    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        paddingLeft: '1.5em',
        justifyContent: 'flex-start',
        borderTop: '1px solid',
        borderRight: '1px solid',
        borderColor: theme.palette.neutral.primary20,
        borderRightColor: theme.palette.neutral.primary20,
        width: '82rem',
        /*add borderbottom to last element*/
    },
    lastBox: {
        borderColor: theme.palette.neutral.primary20,
        borderBottomColor: theme.palette.neutral.primary20,
        borderTop: '1px solid',
        borderBottom: '1px solid',
    },
    focusVisible: {
        backgroundColor: 'white!important',
    },
    backup: {
        paddingTop: '0.5rem',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: '50px',
        paddingBottom: '1rem'
        // paddingLeft: '7px'
    },
    level3: {},

    pricingCategoryFont: {
        fontFamily: 'FrutigerNext-Bd',
        fontSize: '16px !important',
        display: 'flex',
        alignItems: 'center',

    },
    tooltipIcon: {
        marginLeft: '0.5rem',
    },
    pricingItemFont: {

        fontSize: '14px !important',
        fontFamily: 'FrutigerNext !important',
        fontWeight: '400 !important',
        display: 'flex',
        alignItems: 'center',
        color: '#767676 !important',

    },
    pricingItemFontBold: {

        fontSize: '14px !important',
        fontFamily: 'FrutigerNext-Bd !important',
        fontWeight: '400 !important',
        display: 'flex',
        alignItems: 'center',
        color: '#767676 !important',

    },

    placeHolder: {
        width: '14rem',
        height: '1rem',
    },
    pricingInput: {

        textAlign: 'right',
        display: 'inline',
        color: '#767676 !important'
    },
    pricingLabel: {
        width: '300px',
    },
    vspacer: {
        width: '1px',
        height: '100%',
        backgroundColor: theme.palette.neutral.primary20,
        margin: '0 1rem 0 1rem'
    },
    vspacerFat: {
        width: '2px',
        height: '100%',
        backgroundColor: theme.palette.neutral.primary20,
        margin: '0 1rem 0 1rem'
    },
    inlineInput: {
        '& .MuiOutlinedInput-root': {
            borderRadius: "2px",
            backgroundColor: theme.palette.neutral.white,
            fontSize: '13px',
            marginTop: '0 !important',
            width: '8rem',
        },
    }


}));

function PricingSummaryItem({item, key, backgroundColor, unitSystem, lastElement, isResult, ...props}) {
    const classes = useStyle({...props.theme});
    const dispatch = useDispatch()
    const {projectId} = useParams();


    const getUnit = (item) => {
        switch (item.unit) {
            case 'AREA':
            case 'WINDOWAREA':
                return unitSystem === 'METRICAL' ? 'm²' : 'ft²';
            case 'PERCENTAGE':
                return '%';
            case 'LENGTH':
            case 'MOULDING':
                return unitSystem === 'METRICAL' ? 'm' : 'ft';
            default:
                return '';
        }

    }

    const updatePricingSummaryItemCall = (item, value) => {
        const newItem = {...item}
        newItem.value = value
        dispatch(updatePricingSummaryItem({projectId: projectId, pricingSummaryItem: newItem}))
    }

    const getFont = (item) => {
        if (isResult) {
            return classes.pricingItemFontBold;
        }
        switch (item.method) {
            case 'SALES':
                return classes.pricingItemFontBold;
            default:
                return classes.pricingItemFont;
        }
    }

    const getBox = (item) => {
        if (lastElement) {
            return classes.lastBox;
        }
        switch (item.method) {
            case 'SALES':
                return classes.lastBox;
            default:
                return '';
        }
    }

    return (


        <div className={`${classes.innerBox} ${getBox(item)}`} style={{backgroundColor: backgroundColor}} key={key}>
            <Typography className={`${getFont(item)} ${classes.pricingLabel}`}>{item.name}</Typography>
            {((item.method === 'SUM' || item.method === 'COST' || item.method === 'SALES') && !(isResult && item.unit === "PERCENTAGE")) &&
                <>


                    <Typography className={`${getFont(item)} ${classes.pricingInput} `}
                                style={{width: '8rem'}}>{parseFloat(item.value).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</Typography>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '2rem'}}>{getUnit(item)}</Typography>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '5rem'}}>x</Typography>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`} style={{
                        width: '12rem',
                        marginRight: '1rem'
                    }}>{parseFloat(!isNaN(item.referenceValue) ? item.referenceValue : 0).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })} $ per {getUnit(item)}</Typography>
                    <div className={classes.vspacerFat}></div>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '12rem'}}>{parseFloat(item.price).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })} $</Typography>
                    <div className={classes.vspacer}></div>


                </>}

            {
                (isResult && item.unit === "PERCENTAGE") && <>
                    <div style={{width: '28rem'}}></div>
                    <div className={classes.vspacerFat}></div>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '12rem'}}>{parseFloat(item.price).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })} $</Typography>
                    <div className={classes.vspacer}></div>
                </>
            }

            {(item.method === 'COST' || item.method === 'SALES' || isResult) &&
                <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                            style={{width: '7rem'}}>{item.percentage.toFixed(2)} %</Typography>
            }


            {(item.method === 'CONTINGENCY' || item.method === 'OVERHEAD' || item.method === 'PROFIT') &&
                <>

                    {(item.method === 'CONTINGENCY' || item.method === 'PROFIT') &&

                        <StoInput title={''} onBlur={(event) => updatePricingSummaryItemCall(item, event.target.value)}
                                  className={classes.inlineInput} value={item.value.toFixed(2)} style={{
                            width: '6rem',
                            paddingRight: 0,
                            textAlign: 'right',
                            marginLeft: '2rem'
                        }}></StoInput>

                    }
                    {(item.method === 'OVERHEAD') &&
                        <Typography className={`${getFont(item)} ${classes.pricingInput} `}
                                    style={{width: '8rem'}}>{parseFloat(item.value).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}</Typography>
                    }
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '2rem'}}>{getUnit(item)}</Typography>
                    <div style={{width: '18rem'}}></div>
                    <div className={classes.vspacerFat}></div>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '12rem'}}>{parseFloat(item.price).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })} $</Typography>
                    <div className={classes.vspacer}></div>

                </>}


        </div>


    );
}

export default PricingSummaryItem;