import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

import CollapseIcon from "../../utils/icons/collapse.svg";
import {useState} from "react";

const useStyle = makeStyles(_ => ({
    item: {
        height: "4.8rem",
        display: "flex",
        alignItems: "center",
        gap: ".8rem",

        borderBottom: "solid .1rem #EDEDED",

        "&:hover": {
            backgroundColor: "#F4F6F7",
        }
    },
    text: {
        paddingTop: ".2rem",
        fontSize: "1.4rem",
    },
    icon: {
        width: "1.6rem",
    },
}));

function StoSidebarItem({
                            name, toggleCollapse, collapse, activeItem, setSidebarItem = () => {
    }, style = {}, textStyle = {}                        }) {
    const classes = useStyle();

    const [hover, setHover] = useState(false);

    const backgroundColor = (!toggleCollapse && (activeItem === name || hover)) ? "#F4F6F7" : "white";
    const fontWeight = !toggleCollapse && (activeItem === name) ? "600" : "500"

    const itemStyle = {
        paddingLeft: toggleCollapse ? ".8rem" : "3.2rem",
        backgroundColor: backgroundColor,
    }

    const mergedItemStyle = {...itemStyle, ...style}

    const innerTextStyle = {fontWeight: fontWeight}

    const mergedTextStyle = {...innerTextStyle, ...textStyle}

    return (
        <div className={classes.item} style={mergedItemStyle}
             onMouseEnter={_ => setHover(true)} onMouseLeave={_ => setHover(false)}
             onClick={_ => setSidebarItem(name)}>
            {toggleCollapse && <img className={classes.icon} src={CollapseIcon} alt={'icon'} onClick={toggleCollapse}
                                    style={{transform: collapse ? "rotate(0deg)" : "rotate(270deg)"}}/>}
            <Typography className={classes.text} variant={toggleCollapse ? "button" : "h6"}
                        style={mergedTextStyle}>{name}</Typography>
        </div>)
}

export default StoSidebarItem