import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@mui/styles";
import {TableCell, TableRow, Tooltip, Typography} from "@mui/material";
import StoIconButton from "../../utils/components/StoIconButton";
import TrashIcon from "../../utils/icons/trash.svg";
// import ArchiveIcon from "../../utils/icons/archive.svg";
import DuplicateIcon from "../../utils/icons/duplicate.svg";
import ShareIcon from "../../utils/icons/share.svg";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    duplicateProject,
    shareProject,
    validateEmail,
    renameProject,
    moveProjectToBin,
    // deleteProject
} from "../projectsSlice";
import StoDialog from "../../utils/components/StoDialog";
import StoPrimaryButton from "../../utils/components/StoPrimaryButton";
import StoTextInput from "../../utils/components/StoTextInput";
import RenameIcon from "../../utils/icons/rename.svg"
//import StoTag from "../../utils/components/StoTag";


const useStyle = makeStyles(theme => ({
        tableRow: {
            '& .MuiTableRow-root': {
                height: "5rem",
            },
            '& .MuiTableCell-root': {
                padding: "0 16px",
                height: "5rem",
                width: "14rem",
            },
            '&:hover': {
                backgroundColor: `${theme.palette.neutral.primary0}!important`
            },
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.neutral.white,
            },
        },
        icons: {
            display: "flex"
        },
        divider: {
            margin: "0 14px!important"
        },
        dialogActions: {
            height: "8.4rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        },
        deleteProjectTitle: {
            textAlign: "center",
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        cell: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        namecell: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "inline-flex",
            verticalAlign: "middle"
        },
        renameIcon: {
            verticalAlign: "baseline",
            display: "inline-block",
            fontSize: "16px"

        }
    }))
;

function ProjectTableRow({project, ...props}) {
    const classes = useStyle(props.theme);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showIcons, setShowIcons] = React.useState(false)
    const [currentProjectId, setCurrentProjectId] = React.useState(project.id)
    // const [deleteOpen, setDeleteOpen] = React.useState(false)
    const [trashBinOpen, setTrashBinOpen] = React.useState(false)
    const [timer, setTimer] = React.useState(null)
    const [shareOpen, setShareOpen] = React.useState(false)
    const [shareOpenDone, setShareOpenDone] = React.useState(false)
    const [userEmail, setUserEmail] = React.useState("")
    const [userId, setUserId] = React.useState("")
    const [icon, setIcon] = React.useState("")
    const [lastChanged, setLastChanged] = React.useState("")
    const [renameOpen, setRenameOpen] = React.useState(false)
    const [newName, setNewName] = React.useState("")
    const [name, setName] = React.useState("")
    const isSupport = useSelector(state => state.projects.userRoles).some(role => role === 'admin:support')
    const [isDeleted, setIsDeleted] = React.useState(false)


    //const [client, setClient] = React.useState("")

    /*useEffect(() => {
        console.log(project)
        dispatch(getClientInfo({projectId: project.id})).then(result => setClient(result.payload.data.companyName))
        //eslint-disable-next-line
    }, [])*/

    useEffect(() => {
        setName(project.name)
        const date = new Date(project.lastChangedWithTimestamp)
        const now = new Date()
        const diff = now.getTime() - date.getTime()
        const dayDiff = Math.floor(diff / (1000 * 3600 * 24))
        if (dayDiff === 1) {
            setLastChanged(dayDiff + " Day ago")
        } else if (dayDiff === 0) {
            setLastChanged("today")
        } else if (dayDiff < 5)
            setLastChanged(dayDiff + " Days ago")
        else {
            let tmp = new Date(project.lastChangedWithTimestamp).toISOString().substring(0, 10)
            setLastChanged(tmp)
        }
        //eslint-disable-next-line
    }, [])

    // const openDeleteDialog = (event, project) => {
    //     event.stopPropagation()
    //     setCurrentProjectId(project.id)
    //     setDeleteOpen(true)
    // }

    // const closeDeleteDialog = (event) => {
    //     event.stopPropagation()
    //     setDeleteOpen(false)
    // }

    // const handleDelete = async (event) => {
    //     event.stopPropagation()
    //     await dispatch(deleteProject({projectId: currentProjectId}))
    //     closeDeleteDialog(event)
    //     dispatch(fetchAllProjects())
    // }

    // const openArchiveDialog = (event, project) => {
    //     event.stopPropagation()
    //     setCurrentProjectId(project.id)
    //     setArchiveOpen(true)
    // }

    // const closeArchiveDialog = (event) => {
    //     event.stopPropagation()
    //     setArchiveOpen(false)
    // }
    // function AxiosInterceptorNotification() {
    //     const { showNotification } = useNotification();
    //     setupResponseInterceptor(showNotification);
    //     return <></>
    // }
    const handleMoveToBin = async (event, project) => {
        setTrashBinOpen(true)
        event.stopPropagation()
        setCurrentProjectId(project.id)
        await dispatch(moveProjectToBin({projectId: currentProjectId}));
        setIsDeleted(true)


        setTimeout(() => {
            setTrashBinOpen(false);
        }, 500);
    }

    const handleDuplicateProject = async (event, project) => {
        event.stopPropagation()
        await dispatch(duplicateProject({projectId: project.id}))
    }

    const handleSetUserEmail = async (email) => {
        setUserEmail(email)
        clearTimeout(timer)
        //if the current user is a support user, he can share with all users regardless of company
        const newTimer = setTimeout(async () => {
            setIcon("loading")
            await dispatch(validateEmail({email: email, isSupport: isSupport})).then(result => {
                setUserId(result.payload.data)
                if (result.payload.data.length > 0) {
                    setIcon("valid")
                } else {
                    setIcon("invalid")
                }
            })
        }, 750)
        setTimer(newTimer)
    }

    const closeShareDialog = (event) => {
        event.stopPropagation()
        setUserEmail('')
        setIcon('')
        setShareOpen(false)
    }

    const openShareDialog = (event) => {
        setShareOpenDone(false)
        event.stopPropagation()
        setShareOpen(true)
    }

    const handleShareProject = async (event, project) => {
        event.stopPropagation()
        setShareOpen(true)
        if (userId !== null && userId.length > 0) {
            setShareOpenDone(true)
            if (isSupport) {
                await dispatch(shareProject({projectId: project.id, userId: userId, sharedFromSupport: true})).then(() => {
                    closeShareDialog(event)
                })
            } else {
                await dispatch(shareProject({projectId: project.id, userId: userId, sharedFromSupport: false})).then(() => {
                    closeShareDialog(event)
                })
            }
        }
    }

    const handleClick = (project) => {
        if (!shareOpen && !renameOpen && !trashBinOpen) {
            goToProject(project)
        }
    }

    const buttonDisabled = () => {
        return (icon !== 'valid')
    }

    const goToProject = (project) => {
        if (project.id != null) {
            navigate(`/${project.id}`)
        }
    }

    const checkNameLength = (name) => {
        if (name !== null && name.length > 25) {
            return name.substring(0, 22) + "..."
        } else {
            return name
        }
    }

    const closeRenameDialog = () => {
        setRenameOpen(false)
    }

    const openRenameDialog = (event) => {
        setNewName(name)
        event.stopPropagation()
        setRenameOpen(true)
    }

    const handleRenameProject = async (event) => {
        event.stopPropagation()
        await dispatch(renameProject({projectId: project.id, name: newName})).then(() => {
            setName(newName)
            closeRenameDialog()
        })

    }
    /*
    const getColor = () => {
        return project.projectType === "STOPANEL" ? 'primary' : 'secondary'
    }
    */


    const handleFocus = (event) => event.target.select();

    const tableCells = [project.companyName, project.creationDate, lastChanged]

    return (
        !isDeleted ?
        <TableRow className={classes.tableRow} key={project.id} onMouseEnter={() => setShowIcons(true)}
                  onMouseLeave={() => setShowIcons(false)} onBlur={() => setShowIcons(false)}
                  onClick={() => handleClick(project)} >
            <TableCell>
                <Tooltip title={<h1>{name}</h1>} disableHoverListener={name.length === checkNameLength(name).length}
                         placement={"bottom-start"} hidden={true}>
                    <Typography variant={"body1"}
                                className={classes.namecell}>{checkNameLength(name)}
                    </Typography>
                </Tooltip>
            </TableCell>
            {
                tableCells.map((cell, i) => {
                    return <TableCell className={classes.cell} key={i}>
                        <Typography variant={"subtitle1"}
                                    className={classes.cell}>{cell ? cell : "unnamed"}</Typography>
                    </TableCell>
                })
            }
            <TableCell align="right">
                {/*
                <div style={{
                    display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignItems: 'center',
                }}>
                    <StoTag text={project.projectType} color={getColor()}
                            style={{alignItems: 'center', align: 'left'}}></StoTag>
                */}
                {showIcons ?
                    <div className={classes.icons}>
                        <StoIconButton tooltip={"Rename"} icon={RenameIcon} className={classes.renameIcon}
                                       onClick={(event) => openRenameDialog(event)}/>

                        <StoIconButton tooltip={"Duplicate"} icon={DuplicateIcon}
                                       onClick={(event) => handleDuplicateProject(event, project)}/>


                        <StoIconButton tooltip={"Share"} icon={ShareIcon}
                                       onClick={(event) => openShareDialog(event)}/>


                        <StoIconButton tooltip={"Move to bin"} icon={TrashIcon}
                                       onClick={(event) => handleMoveToBin(event, project)}/>

                    </div>
                    :
                    <div style={{width: "13.6rem"}}/>
                }
                {/*  </div>*/}


            </TableCell>

            <StoDialog onClose={(event) => closeRenameDialog(event)} title={""}
                       open={renameOpen}
                       content={
                           <div>

                               <Typography variant={"h1"}
                                           className={classes.deleteProjectTitle}>{"Enter a new Name for the Project"}</Typography>

                               <StoTextInput title={"Name"} value={newName || ''} tabIndex={2}
                                             placeholder={project.name} onDoubleClick={(event) => handleFocus(event)}
                                             onChange={(event) => setNewName(event.target.value)}
                               />

                           </div>
                       }
                       actions={<div className={classes.dialogActions}>
                           <StoPrimaryButton onClick={(event) => handleRenameProject(event)} title={"Rename Project"}/>
                       </div>}
            />

            <StoDialog open={shareOpen} onClose={(event) => closeShareDialog(event)}
                       title={""}
                       maxWidth={"36rem"}
                       closeHidden={shareOpenDone}
                       content=
                           {!shareOpenDone ?
                               <div>

                                   <Typography variant={"h1"}
                                               className={classes.deleteProjectTitle}>{"Enter the email of the user you want to share a copy of your project with"}</Typography>

                                   <StoTextInput title={"Email"} value={userEmail || ''} tabIndex={2}
                                                 placeholder={"Enter the Email here"}
                                                 icon={icon}
                                                 onChange={(event) => handleSetUserEmail(event.target.value)}/>
                                   <Typography variant={"subtitle1"} style={{paddingTop: "1rem"}} align={'center'}>
                                       {"The project will appear as shared project in the overview of the user"}</Typography>
                               </div>
                               :
                               <div>
                                   <Typography variant={"h1"} textAlign={"center"}>
                                       {"Project shared successfully!"}
                                   </Typography>
                               </div>
                           }
                       actions={!shareOpenDone &&

                           <div className={classes.dialogActions}>
                               <StoPrimaryButton onClick={(event) => handleShareProject(event, project)}
                                                 disabled={buttonDisabled()}
                                                 title={"Share Project"}
                               />
                               <StoPrimaryButton title={"Cancel"} onClick={closeShareDialog} secondary/>
                           </div>}/>
        </TableRow> : null
    );
}

ProjectTableRow.propTypes = {
    project: PropTypes.object.isRequired
};
ProjectTableRow.defaultPropTypes = {};
export default ProjectTableRow;