import axios from "axios";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setProjectUnity} from '../unity/react-unity-adapter'

export const fetchAllProjects = createAsyncThunk('projects/fetchAll', async () => {
    try {
        console.log('async load all projects')
        return await axios.get('/api/projects/overview')
    } catch (err) {
        return err.message
    }
})

export const fetchAllProjectsInBin = createAsyncThunk('projects/fetchAll', async () => {
    try {
        console.log('async load all projects')
        return await axios.get('/api/projects/binFolder')
    } catch (err) {
        return err.message
    }
})

export const createProject = createAsyncThunk('projects/create', async ({project}) => {
    try {
        console.log('Create project: ', project)
        return await axios.post(`/api/projects/`, project)
    } catch (err) {
        return err.message
    }
})

export const getProject = createAsyncThunk('projects/get', async ({projectId}) => {
    try {
        console.log('Get project: ', projectId)
        return await axios.get(`/api/projects/${projectId}`)
    } catch (err) {
        return err.message
    }
})

export const deleteProject = createAsyncThunk('projects/delete', async ({projectId}) => {
    try {
        console.log('Delete project with Id : ', projectId)
        return await axios.delete(`/api/projects/${projectId}`)
    } catch (err) {
        return err.message
    }
})

export const duplicateProject = createAsyncThunk('project/duplicate', async ({projectId}) => {
    try {
        console.log('Duplicate project with id: ', projectId)
        return await axios.get(`/api/projects/${projectId}/copy`)
    } catch (err) {
        return err.message
    }
})


export const validateEmail = createAsyncThunk('auth0/validate', async ({email, isSupport}) => {
    try {
        console.log('Validating Email: ', email)
        return await axios.get(`/api/auth0/validate`, {params: {email: email, isSupport: isSupport}})
    } catch (err) {
        return err.message
    }
})

export const getUser = createAsyncThunk('auth0/user', async () => {
    console.log('getting the current user')
    try {
        return await axios.get('api/auth0/')
    } catch (err) {
        return err.message
    }
})

export const acceptLicense = createAsyncThunk('auth0/license', async () => {
    console.log('accepting the license')
    try {
        return await axios.put('api/auth0/license')
    } catch (err) {
        return err.message
    }
})

export const getCompany = createAsyncThunk('company/get', async () => {
    console.log('getting the company of the currently logged in user')
    try {
        return await axios.get('api/company/user')
    } catch (err) {
        return err.message
    }
})


export const shareProject = createAsyncThunk('project/share', async ({projectId, userId, sharedFromSupport}) => {
    try {
        console.log('Sharing project: ', projectId, 'with user: ', userId)
        return await axios.post(`/api/projects/${projectId}/share`, '', {params: {userId: userId, sharedFromSupport: sharedFromSupport}})
    } catch (err) {
        return err.message
    }
})

export const changePassword = createAsyncThunk('user/changePassword', async ({newPassword}) => {
    try {
        console.log('Change password for user to: ' + newPassword)
        return await axios.put(`/api/auth0/password`, newPassword, {headers: {'Content-Type': 'text/plain'}})
    } catch (err) {
        return err.message
    }
})

export const renameProject = createAsyncThunk('project/rename', async ({projectId, name}) => {
    try {
        console.log('Rename project: ', projectId, 'to: ', name)
        return await axios.put(`api/projects/${projectId}/rename`, '', {params: {newName: name}})
    } catch (err) {
        return err.message
    }
})

export const moveProjectToBin = createAsyncThunk('project/moveToBin', async ({projectId}) =>{
    try {
        console.log("Move project to bin with Id", projectId)
        return await axios.put(`/api/projects/${projectId}/moveToBin`)

    }catch (err){
        return err.message
    }
})

export const restoreProjectFromBin = createAsyncThunk('project/restoreProject', async ({projectId}) =>{
    try {
        console.log("Restore project from bin with Id", projectId)
        return await axios.put(`/api/projects/${projectId}/restoreProject`)

    }catch (err){
        return err.message
    }
})



const projectsSlice = createSlice({
    name: 'projects',
    initialState: {
        projectList: [],
        loading: false,
        listLoading: false,
        currentProjectId: null,
        project: {
            blobStorageUrl: {}
        },
        userId: '',
        userEmail: '',
        userRoles: [],
        companyId: '',
    },
    reducers: {
        //add frontend only actions
        setCurrentProjectId(state, action) {
            state.currentProjectId = action.payload
        },
        setUserRoles(state, action){
            state.userRoles = action.payload
        }

    },
    extraReducers(builder) {
        builder
            .addCase(fetchAllProjects.pending, (state) => {
                state.listLoading = true
            })
            .addCase(fetchAllProjects.fulfilled, (state, action) => {
                let data = action.payload.data
                data.sort(function (a, b) {
                    return new Date(b.lastChangedWithTimestamp) - new Date(a.lastChangedWithTimestamp)
                })
                state.projectList = data
                state.listLoading = false
            })
            .addCase(fetchAllProjects.rejected, (state) => {
                state.listLoading = false
                //todo: add error message
            })

            .addCase(deleteProject.pending, (state) => {
                state.listLoading = true
            })
            .addCase(deleteProject.fulfilled, (state, {payload}) => {
                const project = payload.data
                state.projectList = state?.projectList.filter(o => o !== project)
                state.listLoading = false
            })
            .addCase(deleteProject.rejected, (state) => {
                state.listLoading = false
            })
            .addCase(getProject.pending, (state) => {
                state.loading = true
            })
            .addCase(getProject.fulfilled, (state, {payload}) => {
                const project = payload.data
                state.project = project
                setProjectUnity(project)
                state.loading = false
            })
            .addCase(getProject.rejected, (state) => {
                state.loading = false
            })
            .addCase(duplicateProject.pending, (state) => {
                state.listLoading = true
                state.loading = true
            })
            .addCase(duplicateProject.fulfilled, (state, {payload}) => {
                console.log(payload)
                const project = payload.data
                state.loading = false
                state.listLoading = false
                if(!project){
                    return
                }
                state.projectList.unshift(project)
/*

                if (!project) {
                    return
                }
                state.projectList.unshift(project)
*/
                //setProjectUnity(project)


            })
            .addCase(duplicateProject.rejected, (state) => {
                state.listLoading = false
                state.loading = false
            })

            .addCase(validateEmail.pending, (state) => {
                state.loading = true
            })
            .addCase(validateEmail.fulfilled, (state, {payload}) => {
                const userId = payload.data
                if (userId.size === 0) {
                    return
                }
                state.userId = userId
                state.loading = false

            })
            .addCase(validateEmail.rejected, (state) => {
                state.loading = false
            })

            .addCase(shareProject.pending, (state) => {
                state.loading = true
            })

            .addCase(shareProject.fulfilled, (state) => {
                state.loading = false
            })
            .addCase(shareProject.rejected, (state) => {
                state.loading = false
            })
            .addCase(renameProject.pending, (state) => {
                state.loading = true
            })
            .addCase(renameProject.fulfilled, (state) => {
                state.loading = false
            })
            .addCase(renameProject.rejected, (state) => {
                state.loading = false
            })
            .addCase(getUser.rejected, (state) => {
                state.loading = false
            })
            .addCase(getUser.pending, (state) => {
                state.loading = true
            })
            .addCase(getUser.fulfilled, (state, {payload}) => {
                if (payload.data.email === undefined) {
                    state.userEmail = "dev_user"
                } else {
                    state.userEmail = payload.data.email
                    state.userId = payload.data.user_id
                }
                state.loading = false
            })

            .addCase(getCompany.rejected, (state) => {
                state.loading = false
            })
            .addCase(getCompany.fulfilled, (state, {payload}) => {
                state.loading = false
                if (payload.data === ""){
                    state.companyId = "dev_company"
                } else {
                    state.companyId = payload.data.id
                }
            })
            .addCase(getCompany.pending, (state) => {
                state.loading = true
            })


    }
})

export const {setCurrentProjectId, setUserRoles} = projectsSlice.actions
export default projectsSlice.reducer
