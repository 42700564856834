import React, {useEffect, useState} from "react";

import HandleSortIcon from "../utils/icons/handleSort.svg";
import ResetIcon from '../utils/icons/reset.svg';
import DuplicateIcon from '../utils/icons/duplicate.svg';
import DeleteIcon from '../utils/icons/trash.svg';

import {SortableHandle} from "react-sortable-hoc";
import {makeStyles} from "@mui/styles";
import {FormControl, MenuItem, Select, Tooltip, Typography} from "@mui/material";
import StoLengthInput from "../utils/components/StoLengthInput";
import {getToolTipTitle} from "../utils/toolTipUtil";
import StoStepperInput from "../utils/components/StoStepperInput";

const useStyle = makeStyles(() => ({
    stack: {
        width: '100%',
        height: '4.8rem',

        display: 'flex',
        alignItems: 'center',
        gap: '.8rem',
        paddingTop: '.4rem',
        paddingBottom: '.4rem',
        paddingLeft: '.8rem',
        paddingRight: '.8rem',
        boxSizing: 'border-box',
    },
    floorSelect: {
        width: '20rem',
    },
    inputContainer: {
        width: '8rem',
        height: '3.6rem'
    },

    input: {
        height: '100%'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '2.8rem',
        height: '2.8rem',
        cursor: 'pointer',
        '&:hover': {
            background: '#f2f2f2'
        }
    },
    icon: {
        width: '2rem',
        height: '2rem',
    }

}))

const RowHandle = SortableHandle(() =>
    <img alt={'icon'} src={HandleSortIcon}/>);

const Stack = ({
                   stack,
                   orderIndex,
                   floors,
                   onChangeValues,
                   onChangeFloorsCount,
                   onResetStack,
                   onDuplicateStack,
                   onDeleteStack
               }) => {
    const classes = useStyle();

    const [floorCount, setFloorCount] = useState(stack?.floorsCount)
    const [fromLevel, setFromLevel] = useState(stack?.fromLevel)
    const [toLevel, setToLevel] = useState(stack?.toLevel)

    useEffect(() => {
        setFromLevel(stack?.fromLevel)
        setToLevel(stack?.toLevel)
        setFloorCount(stack?.floorsCount)
    }, [stack, stack?.floorsCount, stack?.fromLevel, stack?.toLevel])

    function changeFloorsCount(value) {
        console.log(value)
        onBlurFloorsCount(value)
    }

    function onBlurFloorsCount(value) {
        console.log(isNaN(value), !Number.isInteger(value), value < 1, value)

        if (isNaN(value)
            || !Number.isInteger(value)
            || value < 1) {
            setFloorCount(stack?.floorsCount)
            return
        }

        setFloorCount(value)
        onChangeFloorsCount(stack, Number(value))
    }


    function onChangeFloor(floorId) {
        if (stack.floorId === floorId) {
            return
        }

        stack.floorId = floorId

        onChangeValues(stack)
    }

    function onChangeFloorHeight(value) {
        if (isNaN(value) || stack.floorHeight === value) {
            return
        }

        stack.floorHeight = Number(value)

        onChangeValues(stack)
    }

    function onChangeStructuralSlabThickness(value) {
        if (isNaN(value) || stack.structuralSlabThickness === value) {
            return
        }

        stack.structuralSlabThickness = Number(value)

        onChangeValues(stack)
    }

    function onChangeFinishedFloorThickness(value) {
        if (isNaN(value) || stack.structuralSlabThickness === value) {
            return
        }

        stack.finishedFloorThickness = Number(value)

        onChangeValues(stack)
    }

    function onChangeParapetHeight(value) {
        if (isNaN(value) || stack.parapetHeight === value) {
            return
        }

        stack.parapetHeight = Number(value)

        onChangeValues(stack)
    }

    return (
        <div className={classes.stack} style={{background: orderIndex % 2 === 0 ? '#ffffff' : '#fafafa'}}>
            <RowHandle/>
            <FormControl className={classes.floorSelect}>
                <Select
                    labelId={'demo-label'}
                    id={'demo'}
                    value={stack?.floorId ? stack.floorId : ''}
                    displayEmpty
                    onChange={event => onChangeFloor(event.target.value)}
                    style={{height: '3.6rem'}}
                >
                    {
                        floors.map((floor, index) => {
                            return (
                                <MenuItem key={index} value={floor.id}>{floor.name}</MenuItem>
                            )
                        })
                    }

                </Select>
            </FormControl>
            <div style={{width: '.8rem'}}/>
            <StoStepperInput defaultValue={floorCount} minValue={1} onChange={changeFloorsCount}
                             onBlur={onBlurFloorsCount} step={1}/>
            <Typography variant={'bodyText'} style={{width: '3rem'}} align={'center'}>{fromLevel}</Typography>
            <Typography variant={'bodyText'} style={{width: '3rem'}} align={'center'}>{toLevel}</Typography>
            <div style={{width: '.8rem'}}/>
            <StoLengthInput value={stack?.floorHeight}
                            className={classes.inputContainer}
                            textClassName={classes.input}
                            style={{width: '10rem'}}
                            onBlur={onChangeFloorHeight}/>
            <StoLengthInput value={stack?.structuralSlabThickness}
                            className={classes.inputContainer}
                            textClassName={classes.input}
                            style={{width: '10rem'}}
                            onBlur={onChangeStructuralSlabThickness}/>
            < StoLengthInput value={stack?.finishedFloorThickness}
                             className={classes.inputContainer}
                             textClassName={classes.input}
                             style={{width: '10rem'}}
                             onBlur={onChangeFinishedFloorThickness}/>
            <StoLengthInput value={stack?.parapetHeight}
                            className={classes.inputContainer}
                            textClassName={classes.input}
                            style={{width: '10rem'}}
                            onBlur={onChangeParapetHeight}
                            useEffect/>
            <div style={{width: '.8rem'}}/>
            <Tooltip
                enterDelay={500}
                arrow={true}
                title={getToolTipTitle('Reset', 'Reset floor height, structural slab thickness, finished floor thickness and parapet height to default values')}>
                <div className={classes.iconContainer}>
                    <img className={classes.icon} alt={'icon'} src={ResetIcon} onClick={() => onResetStack(stack)}/>
                </div>
            </Tooltip>
            <Tooltip
                enterDelay={500}
                arrow={true}
                title={getToolTipTitle('Duplicate', 'Duplicate the stack with all values and while be insert as next stack')}>

                <div className={classes.iconContainer}>
                    <img className={classes.icon} alt={'icon'} src={DuplicateIcon}
                         onClick={() => onDuplicateStack(stack)}/>
                </div>
            </Tooltip>
            <Tooltip
                enterDelay={500}
                arrow={true}
                title={getToolTipTitle('Delete', 'Delete the stack')}>
                <div className={classes.iconContainer}>
                    <img className={classes.icon} alt={'icon'} src={DeleteIcon} onClick={() => onDeleteStack(stack)}/>
                </div>
            </Tooltip>

        </div>
    );
};

export default Stack