import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {useParams} from "react-router-dom";
import {setCurrentProjectId} from "../projectsSlice";
import UnityViewer from "../../unity/UnityViewer";
import CreateDialog from "./CreateDialog";

import {createFloor} from "../../floor/floorSlice";
import {createElevation} from "../../elevation/elevationSlice";
import {getElevationPlans, getFloorPlans} from "../../plan/plansSlice";
import {getElevations} from '../../elevations/elevationsSlice'
import {getStacking} from '../../stacking/stackingSlice'

import {NotificationProvider, useNotification} from "../../notificationProvider/notificationProvider";
import {setupResponseInterceptor} from "../../utils/axiosUtils";
import EditMouldingTypeDialog from "./EditMouldingTypeDialog";
import UnityOverlay from "../../overlay/UnityOverlay";
import ExportDialog from "./ExportDialog";
import ExportInfoDialog from "./ExportInfoDialog";
import DeleteOutlineDialog from "../../floor/DeleteOutlineDialog";
import UnitySidebarOverlay from "../../overlay/UnitySidebarOverlay";

const useStyle = makeStyles(() => ({
    root: {
        width: '100vw',
        height: 'calc(100vh - 5.1rem)',
        backgroundColor: '#ffd700',
        overflow: 'hidden',
    },
}));

function ProjectPage(props) {
    const classes = useStyle(props.theme);
    const {projectId} = useParams();
    const dispatch = useDispatch()

    const currentProjectId = useSelector(state => state.projects.currentProjectId)
    const createPlan = useSelector(state => state.unity.createPlan)
    const editMouldingTypeId = useSelector(state => state.unity.editMouldingTypeId)
    const floorplans = useSelector(state => state.plans.floorPlans)
    const elevationplans = useSelector(state => state.plans.elevationPlans)
    const mouldingTypes = useSelector(state => state.mouldingTypeReducer.mouldingTypes)
    const mouldingTypeProfiles = useSelector(state => state.mouldingTypeReducer.mouldingTypeProfiles)
    const unitSystem = useSelector(state => state.unitSystem.unitSystem)
    const pricingLevel = useSelector(state => state.settings.settings.pricingLevel)

    const [floorOpen, setFloorOpen] = React.useState(false);
    const [elevationOpen, setElevationOpen] = React.useState(false);
    const [name, setName] = React.useState("")
    const [plan, setPlan] = React.useState(null)
    const [mouldingType, setMouldingType] = React.useState(null)

    useEffect(() => {
        dispatch(setCurrentProjectId(projectId))
        //eslint-disable-next-line
    }, [projectId])

    useEffect(() => {
        if (createPlan) {
            if (createPlan.isFloor) {
                dispatch(getFloorPlans({projectId: projectId}))
                setPlan(floorplans[0])
                setFloorOpen(true)
            } else {
                dispatch(getElevationPlans({projectId: projectId}))
                setPlan(elevationplans[0])
                setElevationOpen(true)
            }
            setName(createPlan.planName)
        }
        //eslint-disable-next-line
    }, [createPlan, projectId])

    useEffect(() => {
        if (editMouldingTypeId) {
            setMouldingType(mouldingTypes.find(m => m.id === editMouldingTypeId))
        }
        //eslint-disable-next-line
    }, [editMouldingTypeId])

    const createNewFloor = () => {
        if (typeof plan === "object" && plan.type === "application/pdf") {
            const newObject = {
                name: name,
                plan: {
                    name: plan.name
                }
            }
            dispatch(createFloor({
                projectId: projectId,
                floor: newObject,
                plan: plan
            })).then(() => dispatch(getStacking({projectId: projectId})))
        } else {
            const newObject = {
                name: name,
                planId: plan.id
            }
            dispatch(createFloor({
                projectId: projectId,
                floor: newObject
            })).then(() => dispatch(getStacking({projectId: projectId})))
        }
    }

    const createNewElevation = () => {
        if (typeof plan === "object" && plan.type === "application/pdf") {
            const newObject = {
                name: name,
                plan: {
                    name: plan.name
                }
            }
            dispatch(getElevations({projectId: projectId}))
                .then(() => dispatch(createElevation({projectId: projectId, elevation: newObject, plan: plan})))
        } else {
            const newObject = {
                name: name,
                planId: plan.id
            }
            dispatch(getElevations({projectId: projectId}))
                .then(() => dispatch(createElevation({projectId: projectId, elevation: newObject})))
        }
    }

    function AxiosInterceptorNotification() {
        const {showNotification} = useNotification();
        setupResponseInterceptor(showNotification);
        return <></>
    }

    return (
        <div className={classes.root}>
            <NotificationProvider>
                <AxiosInterceptorNotification/>
                <CreateDialog open={floorOpen} setOpen={setFloorOpen} projectId={currentProjectId} type={"Floor"}
                              plans={floorplans} name={name} setName={setName} plan={plan} setPlan={setPlan}
                              onSubmit={createNewFloor} initialName={createPlan?.planName}/>
                <CreateDialog open={elevationOpen} setOpen={setElevationOpen} projectId={currentProjectId}
                              type={"Elevation"} plans={elevationplans} name={name} setName={setName}
                              plan={plan} setPlan={setPlan} onSubmit={createNewElevation}
                              initialName={createPlan?.planName}/>
                <DeleteOutlineDialog/>
                <UnityOverlay/>
                <UnitySidebarOverlay/>
                {mouldingType &&
                    <EditMouldingTypeDialog open={true}
                                            projectId={currentProjectId}
                                            unitSystem={unitSystem}
                                            setMouldingType={setMouldingType}
                                            selectedMoulding={mouldingType}
                                            mouldingTypeProfiles={mouldingTypeProfiles}
                                            pricingLevel={pricingLevel}/>
                }
                <ExportDialog/>
                <ExportInfoDialog/>
                <UnityViewer projectId={projectId}/>
            </NotificationProvider>
        </div>
    );
}

ProjectPage.propTypes =
    {
        projectId: PropTypes.number,
    }
;

ProjectPage.defaultProps =
    {}
;

export default ProjectPage;