import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {saveAs} from 'file-saver';

export const getSchedulingPlaning = createAsyncThunk('schedulingPlaning/getSchedulingPlaning', async ({projectId}) => {
    try {
        console.log('Get scheduling planing: ', projectId)
        return await axios.get(`/api/projects/${projectId}/schedulingPlaning`)
    } catch (err) {
        return err.message
    }
})

export const updateSchedulingPlaning = createAsyncThunk('schedulingPlaning/updateSchedulingPlaning', async ({projectId, schedulingPlaning}) => {
    try {
        console.log('Update scheduling planing: ', projectId)
        return await axios.put(`/api/projects/${projectId}/schedulingPlaning`, schedulingPlaning)
    } catch (err) {
        return err.message
    }
})

export const updateSchedulingPlaningSettings = createAsyncThunk('schedulingPlaning/updateSchedulingPlaningSettings', async ({projectId, currentSettings}) => {
    try {
        console.log('Update scheduling planing settings: ', projectId)
        return await axios.put(`/api/projects/${projectId}/schedulingPlaning/settings`, currentSettings)
    } catch (err) {
        return err.message
    }
})

export const resetSchedulingPlaningSettings = createAsyncThunk('schedulingPlaning/resetSchedulingPlaningSettings', async ({projectId}) => {
    try {
        console.log('Reset scheduling planing settings: ', projectId)
        return await axios.post(`/api/projects/${projectId}/schedulingPlaning/settings/reset`)
    } catch (err) {
        return err.message
    }
})

export const downloadSchedulingPlanningCSV = createAsyncThunk('projects/downloadSchedulingPlanningCSV', async ({ projectId }) => {
    try {
        const response = await axios.get(`/api/projects/${projectId}/schedulingPlaning/csv`, {
            responseType: 'blob' // Wichtig, um die Antwort als Blob zu erhalten
        });

        // Verwendung von file-saver, um die Datei zu speichern
        saveAs(response.data, 'schedulingPlanning.csv');
    } catch (err) {
        console.error(err);
        return err.message;
    }
});

const schedulingPlaningSlice = createSlice({
    name: 'schedulingPlaning',
    initialState: {
        schedulingPlaning: {},
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getSchedulingPlaning.pending, (state) => {
                state.loading = true
            })
            .addCase(getSchedulingPlaning.fulfilled, (state, action) => {
                const schedulingPlaning = action.payload.data

                if (!schedulingPlaning) {
                    return
                }

                state.schedulingPlaning = schedulingPlaning
                state.schedulingPlaning.reset = false
                state.loading = false
            })
            .addCase(getSchedulingPlaning.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateSchedulingPlaning.pending, (state) => {
                state.loading = true
            })
            .addCase(updateSchedulingPlaning.fulfilled, (state, action) => {
                const schedulingPlaning = action.payload.data

                if (!schedulingPlaning) {
                    return
                }

                state.schedulingPlaning = schedulingPlaning
                state.schedulingPlaning.reset = false
                state.loading = false
            })
            .addCase(updateSchedulingPlaning.rejected, (state) => {
                state.loading = false
            })
            .addCase(resetSchedulingPlaningSettings.pending, (state) => {
                state.loading = true
            })
            .addCase(resetSchedulingPlaningSettings.fulfilled, (state, action) => {
                const schedulingPlaning = action.payload.data

                if (!schedulingPlaning) {
                    return
                }

                state.schedulingPlaning = schedulingPlaning
                state.schedulingPlaning.reset = true
                state.loading = false
            })
            .addCase(resetSchedulingPlaningSettings.rejected, (state) => {
                state.loading = false
            })
    }
})

export default schedulingPlaningSlice.reducer