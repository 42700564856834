import React from 'react'
import {makeStyles} from "@mui/styles";
import {Tooltip, Typography} from "@mui/material";

const useStyle = makeStyles(() => ({
    button: {
        width: "14rem",
        height: "3.2rem",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: ".8rem",

        "&:hover": {
            backgroundColor: "#F4F6F7",
        }
    },
    icon: {
        width: "2.8rem",
        height: "2.8rem",
    },
    text: {
        paddingTop: ".2rem",
        fontSize: "1.4rem",
    }
}))

function StoButton({
                       name, icon, tooltip, style,
                       onClick = () => {
                       }
                   }) {
    const classes = useStyle();

    return (
        <Tooltip title={tooltip ? <h1>{tooltip}</h1> : ""} arrow={true} placement={"bottom-start"}
                 hidden={true}>
            <div className={classes.button} onClick={onClick} style={style}>
                {icon && <img className={classes.icon} alt={'icon'} src={icon}/>}
                <Typography className={classes.text} variant={"button"}>{name}</Typography>
            </div>
        </Tooltip>
    )
}

export default StoButton