import React, {useEffect, useState} from 'react';
import {TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";

import MinusIcon from "../icons/minus.svg";
import PlusIcon from "../icons/plus.svg";

const useStyle = makeStyles(_ => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: 'max-content',
        height: "inherit",
    },
    textField: {
        borderRadius: '2px',
        width: '5.4rem',
        height: '3.2rem',
        textAlign: 'center',
    },
    icon: {
        padding: '0.8rem',
        width: '1.6rem',
        cursor: 'pointer',
    }

}));


function StoStepperInput({
                             placeholder, defaultValue = 2, minValue = 0, step = .1, onClick = () => {
    }, onChange = () => {
    }, onBlur = () => {
    }, autoFocus = false, selectAll = true
                         }) {

    step = Number(step)

    const [value, setValue] = useState(defaultValue);
    const classes = useStyle();

    useEffect(() => {
        if (!defaultValue || typeof (defaultValue) === "string") {
            return
        }

        setValue(Number(defaultValue.toFixed(2)))
    }, [defaultValue])

    function onKeyDown(event) {
        if (event.key === "Enter" ||
            event.key === "Escape") {
            const value = Number(event.target.value)
            onBlur(value)
        }
    }

    const handleIncrement = () => {
        if (isNaN(Number(value) + step)) {
            return
        }

        const newValue = (Number(value) + step)
        setValue(Number(newValue.toFixed(2)));
        onBlur(newValue)
    };

    const handleDecrement = () => {
        if (isNaN(value - step)) {
            return
        }

        if (value - step >= minValue) {
            const newValue = Number((value - step))
            setValue(Number(minValue.toFixed(2)))
            onBlur(newValue)
        } else if (value > minValue) {
            setValue(Number(minValue.toFixed(2)))
            onBlur(minValue)
        }
    };

    const onValueChange = (event) => {
        let value = event.target.value
        if (isNaN(value)) {
            setValue(defaultValue)
            return
        }

        value = Number(value)

        setValue(value);
        onChange(value)
    }

    return (
        <div className={classes.root}>
            <img className={classes.icon} alt={'icon'} src={MinusIcon} onClick={handleDecrement}/>
            <TextField className={classes.textField}
                       value={value}
                       inputProps={{style: {textAlign: 'center', padding: '0rem', height: '3.2rem'}}}
                       onClick={onClick}
                       placeholder={placeholder}
                       onChange={onValueChange}
                       onBlur={event => onBlur(event.target.value)}
                       onFocus={event => selectAll ? event.target.select() : null}
                       autoFocus={autoFocus}
                       onKeyDown={onKeyDown}/>
            <img className={classes.icon} alt={'icon'} src={PlusIcon} onClick={handleIncrement}/>
        </div>
    );
}

export default StoStepperInput;