import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';

import {Typography} from "@mui/material";
import StoTextInput from "../../utils/components/StoTextInput";
import StoDialog from "../../utils/components/StoDialog";
import StoPrimaryButton from "../../utils/components/StoPrimaryButton";

import {setEditMouldingType} from "../../unity/unitySlice";
import {useDispatch, useSelector} from "react-redux";
import {
    addMouldingTypeToCompany,
    deleteMouldingType,
    getCompanyMouldings,
    updateMouldingType,
    updateMouldingTypeInCompany
} from "../../mouldingType/mouldingTypeSlice";
import {PRICING_LEVEL} from "../../pricingLevel/pricingSlice";
import MouldingPreview from "./MouldingPreview";
import StoLengthInput from "../../utils/components/StoLengthInput";
import StoPricePerUnitInput from "../../utils/components/StoPricePerUnitInput";
import StoSnackbar from "../../utils/components/StoSnackbar";
import MouldingPreset from "./MouldingPreset";
import ColorPaletteItem from "../../colorPalette/components/ColorPaletteItem";


const useStyle = makeStyles(theme => ({
    root: {},
    toggleButtons: {
        marginTop: "0.6rem",
        backgroundColor: theme.palette.neutral.primary20,
        borderRadius: "2px!important",
        marginBottom: "0.6rem",
        height: "3.2rem",
        '& .MuiToggleButton-root': {
            borderRadius: "2px",
            fontSize: '13px',
            lineHeight: 9,
            backgroundColor: '#e4e4e4'
        },
    },
    planList: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        height: '12.8rem',
        overflow: "hidden",
        backgroundColor: theme.palette.neutral.white,
        border: `1px solid ${theme.palette.neutral.primary40}`,
        borderRadius: "2px!important",
        boxShadow: 'none!important',
        '& .MuiList-root': {
            padding: "0!important"
        }
    },
    listItem: {
        height: "3.5rem",
        padding: '0 2rem',
        ' &:not(:first-child)': {
            borderTop: `1px solid ${theme.palette.neutral.primary40}`
        },
    },

    actions: {
        height: "8.0rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

    },
    presetActions: {
        justifyContent: "center",
        display: "flex",

    },
    colorPalette: {
        marginTop: '10px',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none'
        },

    },
    actionsSelector: {
        height: "3.0rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    profilePreview: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '15px',
    },
    presetPreview: {
        display: 'flex',
        flexWrap: 'wrap',
        maxHeight: '330px',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '15px',
        border: '1px solid #CCCCCC',
    },
    squareItem: {
        width: '96px', // Um Platz für Ränder zu lassen
        height: '96px',  // oder eine gewünschte Höhe
        padding: '6px',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.neutral.white,
        display: 'inline-block',
        transition: 'border-color 0.3s', // Für einen sanften Übergang
        border: '1px solid', // Fügt einen transparenten Rahmen hinzu
        borderColor: theme.palette.neutral.primary40,
        borderRadius: '2px',
        marginBottom: '12px',
    },
    previewSquareItem: {
        width: '120px', // Um Platz für Ränder zu lassen
        height: '96px',  // oder eine gewünschte Höhe
        padding: '6px 19px',
        marginTop: '0.7rem',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.neutral.white,
        display: 'inline-block',
        transition: 'border-color 0.3s', // Für einen sanften Übergang
        border: '1px solid',
        borderColor: theme.palette.neutral.primary40,
        borderBottom: 'none',
        cursor: 'pointer',
    },
    previewColorItem: {
        width: '120px', // Um Platz für Ränder zu lassen
        height: '96px',  // oder eine gewünschte Höhe
        padding: '6px 19px',
        marginTop: '0.7rem',
        boxSizing: 'border-box',
        display: 'inline-block',
        transition: 'border-color 0.3s', // Für einen sanften Übergang
        border: '1px solid',
        borderColor: theme.palette.neutral.primary40,
        borderBottom: 'none',
        cursor: 'pointer',
    },
    presetSquareItem: {
        width: '308px', // Um Platz für Ränder zu lassen
        height: '94px',  // oder eine gewünschte Höhe
        padding: '10px 10px',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.neutral.grey,
        display: 'inline-block',
        transition: 'border-color 0.3s', // Für einen sanften Übergang
        borderBottom: '1px #CCCCCC solid',
        borderColor: theme.palette.neutral.primary40,
        cursor: 'pointer',
    },
    previewItemButton: {
        width: '120px', // Um Platz für Ränder zu lassen
        height: '32px',  // oder eine gewünschte Höhe
        padding: '6px 19px',
        boxSizing: 'border-box',
        backgroundColor: '#ffd700',
        display: 'inline-block',
        transition: 'border-color 0.3s', // Für einen sanften Übergang
        border: '1px solid',
        borderColor: '#ffd700',
        borderBottom: 'none',
        cursor: 'pointer',
        textAlign: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: '700',
    },
    profileCanvas: {
        margin: 0,
    },
    activeSquare: {
        borderColor: '#FFD700',
        border: '2px solid',
    },
    stoRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '14px',
    },
    stoRowShape: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '14px',
    },
    stoRowRightElement: {
        width: '120px',
    },
    stoRowLeftElement: {
        marginTop: '0.7rem',
    },
    selectorTitle: {
        fontSize: '14px',
        fontWeight: '400',
        color: theme.palette.neutral.primary80,
        margin: '3rem 0 0.5rem'
    }
}));


function EditMouldingTypeDialog({
                                    open,
                                    projectId,
                                    unitSystem,
                                    setMouldingType,
                                    selectedMoulding,
                                    mouldingTypeProfiles,
                                    pricingLevel,
                                    ...props
                                }) {
    const classes = useStyle(props.theme);
    const roles = useSelector(state => state.projects.userRoles)
    const isAdmin = roles.includes('admin:all') || process.env.REACT_APP_PROFILE === 'dev'
    const companyId = useSelector(state => state.projects.companyId)
    const dispatch = useDispatch()
    const [deleteOpen, setDeleteOpen] = React.useState(false)
    const [contentType, setContentType] = React.useState('edit')
    const [selectedMouldingType, setSelectedMouldingType] = React.useState(selectedMoulding)
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const [applyDisabled, setApplyDisabled] = React.useState(false)
    const colorPalette = useSelector(state => state.colors.colors)
    const [activeColor, setActiveColor] = React.useState(0)
    const mouldingPresets = useSelector(state => state.mouldingTypeReducer.mouldingPresets)

    useEffect(() => {
        console.log(colorPalette[0])
        dispatch(getCompanyMouldings({companyId: companyId}))
        //eslint-disable-next-line
    }, []);
    const updateSelectedMouldingType = () => {
        dispatch(updateMouldingType({projectId: projectId, mouldingType: selectedMouldingType}))
    }

    const deleteSelectedMouldingType = () => {
        dispatch(deleteMouldingType({projectId: projectId, mouldingTypeId: selectedMouldingType.id}))
    }
    const handleClose = () => {
        setContentType('edit')
        dispatch(setEditMouldingType(null))
        setMouldingType(null)
        setApplyDisabled(false)
    }

    const handleDelete = () => {
        deleteSelectedMouldingType()
        setDeleteOpen(false)
        handleClose()
    }

    const handleSubmit = async () => {
        updateSelectedMouldingType()
        handleClose()
    }
    const selectItemForEdit = (item) => {
        setSelectedMouldingType(item)
        setContentType('edit')
        setApplyDisabled(true)
    }

    const renderMouldingPreview = () => {
        return mouldingTypeProfiles.map((item) => {

            const isActive = item.id === selectedMouldingType.profileId;
            return (
                <div
                    key={item.id}
                    className={`${classes.squareItem} ${isActive ? classes.activeSquare : ''}`}
                    onClick={() => {
                        const updatedMouldingType = {
                            ...selectedMouldingType,
                            profileId: item.id,
                            topPoints: item.topPoints,
                            bottomPoints: item.bottomPoints,
                            applyProfileMode: item.applyProfileMode
                        };
                        setSelectedMouldingType(updatedMouldingType);

                    }}
                >
                    <MouldingPreview previewItem={item} width={'80px'} height={'80px'}/>

                </div>
            );
        });
    };

    const renderMouldingPresets = () => {
        if (mouldingPresets.length > 0) {
            return mouldingPresets.map((item) => {
                return (
                    <div
                        key={item.id}
                        className={classes.presetSquareItem}
                        onClick={() => {
                            const updatedMouldingType = {
                                ...item,
                                //important to keep the id of the selected moulding, because we want to edit the selected moulding
                                id: selectedMoulding.id,
                                previewColor: selectedMoulding.previewColor,
                                profileId: item.id,
                                topPoints: item.topPoints,
                                bottomPoints: item.bottomPoints,
                                applyProfileMode: item.applyProfileMode
                            };
                            setSelectedMouldingType(updatedMouldingType);
                            setContentType('edit')
                        }}

                    >
                        {contentType === 'load' &&
                            <MouldingPreset previewItem={item} width={'36px'} height={'36px'} isAdmin={isAdmin}
                                            itemForEdit={selectItemForEdit}
                                            companyId={companyId}
                                            unitSystem={unitSystem}/>
                        }
                    </div>
                );
            });
        }
    }

    const getDeleteContent = () => {
        return <div>
            <Typography variant={"h1"} className={classes.deleteProjectTitle}>
                {"Are you sure you want to delete this Moulding?"}</Typography>
            <Typography variant={"subtitle1"} style={{paddingTop: "2rem"}}>
                {""}</Typography>
        </div>

    }

    function updateMoultingTypeDepth(depth) {
        const mouldingType = {...selectedMouldingType, depth: depth};
        setSelectedMouldingType(mouldingType);
    }

    function updateLevel1Cost(cost) {
        const mouldingType = {...selectedMouldingType, level1Cost: cost};
        setSelectedMouldingType(mouldingType);
    }

    function updateLevel2Cost(cost) {
        const mouldingType = {...selectedMouldingType, level2Cost: cost};
        setSelectedMouldingType(mouldingType);
    }

    function updateLevel3Cost(cost) {
        const mouldingType = {...selectedMouldingType, level3Cost: cost};
        setSelectedMouldingType(mouldingType);
    }

    function updateLevel3Labor(labor) {
        const mouldingType = {...selectedMouldingType, level3Labor: labor};
        setSelectedMouldingType(mouldingType);
    }

    const hideProfileSelector = () => {
        setContentType('edit')
    }

    const showProfileSelector = () => {
        setContentType('select')
    }


    const handleCreatePreset = () => {
        dispatch(addMouldingTypeToCompany({
            companyId: companyId,
            mouldingType: selectedMouldingType
        })).then((response) => {
            if (response.payload.status === 200) {
                setSnackbarOpen(true)
            }
        })
    }

    const handleUpdatePreset = () => {
        dispatch(updateMouldingTypeInCompany({
            companyId: companyId,
            mouldingType: selectedMouldingType
        })).then((response) => {
            if (response.payload.status === 200) {
                setSnackbarOpen(true)
            }
        })
    }

    const handleSelectColor = () => {
        setActiveColor(colorPalette.findIndex(color => color.Farbtonnummer === selectedMouldingType.displayColor.farbtonnummer))
        setContentType('color')
    }

    const handleOpen = () => {
        setSnackbarOpen(false)
        setContentType('load')
    }

    const transformColor = (color) => {
        if (color !== undefined) {
            return {
                farbtonkarte: color.Farbtonkarte,
                farbtonnummer: color.Farbtonnummer,
                r: color.R,
                g: color.G,
                b: color.B
            }
        } else {
            return {
                farbtonkarte: "StoColor System 2022",
                farbtonnummer: "Y07 94 05",
                r: 245,
                g: 240,
                b: 229
            }
        }

    }
    const handleColorClose = () => {
        setContentType('edit')
        setSelectedMouldingType({...selectedMouldingType, displayColor: transformColor(colorPalette[activeColor])})
    }
    const handleClick = (color, index) => {
        setActiveColor(index)
    }

    const getColorContent = () => {
        return <div className={classes.colorPalette}>
            {colorPalette.map((color, index) => {
                return (
                    <div key={index}>
                        <ColorPaletteItem isActive={index === activeColor} name={color.Farbtonnummer}
                                          r={color.R} g={color.G} b={color.B}
                                          onClick={() => handleClick(color, index)}/>
                    </div>
                )

            })}
        </div>
    }

    const rgbToHex = (color) => {
        //eslint-disable-next-line
        return "#" + (1 << 24 | color.R << 16 | color.G << 8 | color.B).toString(16).slice(1);
    }

    const checkSaveFunctionality = () => {
        if (!isAdmin) {
            console.log('no admin')
            return false
        }
        if (applyDisabled) {
            console.log('update presets')
            return handleUpdatePreset()
        }
        if (!applyDisabled) {
            console.log('create preset')
            return handleCreatePreset()
        }
    }

    const getTextColor = (color) => {
        const Y = 0.2126 * color.R + 0.7152 * color.G + 0.0722 * color.B
        return  Y > 128 ? "black" : "white"
    }

    const getContent = () => {
        if (contentType === 'select') {
            return <>
                <StoDialog maxWidth={"36rem"} open={open} closeHidden={true} minHeight={"50rem"}
                           onBack={hideProfileSelector} height={'rem'}
                           onClose={hideProfileSelector} title={"Moulding Shapes"}

                           content={
                               <div style={{maxWidth: '36rem'}}>


                                   <div className={classes.selectorTitle}>
                                       Click on the shape you want to use
                                   </div>
                                   <div className={classes.profilePreview}>


                                       {renderMouldingPreview()}


                                   </div>
                               </div>
                           }
                           actions={
                               <div className={classes.actionsSelector}>
                                   {/*<StoPrimaryButton title={"Update"} onClick={handleSubmit}/>*/}
                                   <StoPrimaryButton title={"Cancel"} onClick={hideProfileSelector} secondary/>
                               </div>
                           }
                />
            </>
        } else if (contentType === 'edit') {
            return <>
                <StoDialog maxWidth={"36rem"} open={open} onClose={handleClose}
                           minHeight={"50rem"}
                           onOpen={mouldingPresets.length > 0 && handleOpen}
                           onSave={checkSaveFunctionality && isAdmin}
                           title={!applyDisabled ? "Edit Moulding" : "Edit Preset"}

                           content={
                               <div style={{maxWidth: '36rem'}}>

                                   <StoTextInput title={"Name"} value={selectedMouldingType.name}
                                                 onChange={e => setSelectedMouldingType({
                                                     ...selectedMouldingType,
                                                     name: e.target.value
                                                 })}
                                                 tabIndex={2}/>

                                   <div className={classes.stoRow}>
                                   <span
                                       className={classes.stoRowLeftElement}><b>Depth</b></span>
                                       <div className={classes.stoRowRightElement}>
                                           <StoLengthInput title={""}
                                                           onBlur={(value) => updateMoultingTypeDepth(value)}
                                                           value={selectedMouldingType.depth}
                                                           theme={'padding'}
                                                           padding={"0 0"}/>
                                       </div>
                                   </div>

                                   {pricingLevel === PRICING_LEVEL.LEVEL_3 &&
                                       <>
                                           <div className={classes.stoRow}>
                                   <span
                                       className={classes.stoRowLeftElement}><b>{(unitSystem === "IMPERIAL") ? "Material / Foot" : "Material / Meter"}</b></span>
                                               <div className={classes.stoRowRightElement}>
                                                   <StoPricePerUnitInput title={""}
                                                                         onBlur={(value) => updateLevel3Cost(value)}
                                                                         value={selectedMouldingType.level3Cost}
                                                                         theme={'padding'}
                                                                         padding={"0 0"}/>
                                               </div>
                                           </div>
                                           <div className={classes.stoRow}>
                                   <span
                                       className={classes.stoRowLeftElement}><b>{(unitSystem === "IMPERIAL") ? "Labor / Foot" : "Labor / Meter"}</b></span>
                                               <div className={classes.stoRowRightElement}>
                                                   <StoPricePerUnitInput title={""}
                                                                         onBlur={(value) => updateLevel3Labor(value)}
                                                                         value={selectedMouldingType.level3Labor}
                                                                         theme={'padding'}
                                                                         padding={"0 0"}/>
                                               </div>
                                           </div>
                                       </>
                                   }

                                   {pricingLevel === PRICING_LEVEL.LEVEL_2 &&
                                       <>
                                           <div className={classes.stoRow}>
                                   <span
                                       className={classes.stoRowLeftElement}><b>{(unitSystem === "IMPERIAL") ? "Cost / Foot" : "Cost / Meter"}</b></span>
                                               <div className={classes.stoRowRightElement}>
                                                   <StoPricePerUnitInput title={""}
                                                                         onBlur={(value) => updateLevel2Cost(value)}
                                                                         value={selectedMouldingType.level2Cost}
                                                                         theme={'padding'}
                                                                         padding={"0 0"}/>
                                               </div>
                                           </div>

                                       </>
                                   }

                                   {pricingLevel === PRICING_LEVEL.LEVEL_1 &&
                                       <>
                                           <div className={classes.stoRow}>
                                   <span
                                       className={classes.stoRowLeftElement}><b>{(unitSystem === "IMPERIAL") ? "Cost / Foot" : "Cost / Meter"}</b></span>
                                               <div className={classes.stoRowRightElement}>
                                                   <StoPricePerUnitInput title={""}
                                                                         onBlur={(value) => updateLevel1Cost(value)}
                                                                         value={selectedMouldingType.level1Cost}
                                                                         theme={'padding'}
                                                                         padding={"0 0"}/>
                                               </div>
                                           </div>

                                       </>
                                   }

                                   <>
                                       <div className={classes.stoRowShape}>
                                   <span className={classes.stoRowLeftElement}><b>Shape</b>

                                       </span>
                                           <div className={classes.stoRowRightElement}
                                                onClick={showProfileSelector}>
                                               <div className={classes.previewSquareItem}>
                                                   <MouldingPreview previewItem={selectedMouldingType} width={'80px'}
                                                                    height={'80px'}/>

                                               </div>
                                               <div className={classes.previewItemButton}>
                                                   Select...
                                               </div>
                                           </div>
                                       </div>
                                       <div className={classes.stoRowShape}>
                                           <span className={classes.stoRowLeftElement}><b>Color</b></span>


                                           <div className={classes.stoRowRightElement}
                                                onClick={handleSelectColor}>
                                               <div className={classes.previewColorItem}
                                                    style={{backgroundColor: rgbToHex(colorPalette[activeColor])}}>
                                                   <div style={{marginTop: '70px', display: 'flex', justifyContent: 'space-around', color: getTextColor(colorPalette[activeColor])}}>
                                                       {colorPalette[activeColor].Farbtonnummer}
                                                   </div>

                                               </div>




                                               <div className={classes.previewItemButton}>
                                                   Select...
                                               </div>
                                           </div>
                                       </div>


                                   </>

                               </div>
                           }
                           actions={
                               <div className={classes.actions}>
                                   {!applyDisabled && <StoPrimaryButton title={"Update"} onClick={handleSubmit}/>}
                                   <StoPrimaryButton title={"Cancel"} onClick={handleClose} secondary/>
                               </div>
                           }
                />
                <StoSnackbar open={snackbarOpen}
                             vertical={'top'} horizontal={'center'}
                             duration={3000}
                             onClose={() => setSnackbarOpen(false)}
                             message={"Moulding settings successfully saved as preset"}
                             showIcon={true}
                />

                <StoDialog open={deleteOpen}
                           onClose={() => setDeleteOpen(false)}
                           title={""}
                           maxWidth={"36rem"}
                           content={getDeleteContent()}
                           actions={
                               <div className={classes.dialogActions}>
                                   <StoPrimaryButton onClick={() => handleDelete()} title={"Delete Moulding"}/>
                                   <StoPrimaryButton onClick={() => setDeleteOpen(false)} title={"Cancel"}
                                                     secondary/>
                               </div>}/>
            </>
        } else if (contentType === 'load') {
            return <>
                <StoDialog maxWidth={"36rem"} open={open} closeHidden={true}
                           minHeight={"50rem"}
                           onBack={hideProfileSelector}
                           onClose={hideProfileSelector} title={"Moulding presets"}
                           content={
                               <div style={{maxWidth: '36rem'}}>


                                   <div className={classes.selectorTitle}>
                                       Click on the preset you want to load
                                   </div>
                                   <div className={classes.presetPreview}>


                                       {renderMouldingPresets()}


                                   </div>
                               </div>
                           }

                           actions={
                               <div className={classes.presetActions}>
                                   <StoPrimaryButton title={"Cancel"} onClick={handleClose} secondary={true}/>
                               </div>
                           }
                />
            </>
        } else if (contentType === 'color') {
            return <>
                <StoDialog maxWidth={"36rem"} open={open} closeHidden={true}
                           minHeight={"50rem"} maxHeight={'50rem'}
                           onBack={handleColorClose}
                           onClose={handleColorClose} title={"Color"}
                           content={getColorContent()}

                >

                </StoDialog>
            </>
        }
    }

    return (
        getContent()
    );
}

EditMouldingTypeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    projectId: PropTypes.string,
    name: PropTypes.string,
    pricingLevel: PropTypes.string.isRequired,
};
EditMouldingTypeDialog.defaultPropTypes = {};
export default EditMouldingTypeDialog;