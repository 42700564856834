import {Provider} from "react-redux";

import './App.css';
import theme from "./theme";
import {store} from "./store";
import {Outlet} from "react-router-dom";
import {ThemeProvider} from "@emotion/react";
import Header from "./projects/components/Header";
import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";


function App() {

    // eslint-disable-next-line no-unused-vars
    const [, setAccessToken] = useState(null);

    const {
        isLoading,
        error,
        isAuthenticated,
        getAccessTokenSilently,
        user,
        loginWithRedirect,
    } = useAuth0();

    useEffect(() => {
        if (process.env.REACT_APP_PROFILE === 'prod') {
            const getAccessToken = async () => {
                try {
                    const accessToken = await getAccessTokenSilently({
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE_PROD,
                        scope: process.env.REACT_APP_SCOPE,
                    });
                    setAccessToken(accessToken);

                } catch (e) {
                    console.log(e.message);
                }
            };
            getAccessToken();
        } else if (process.env.REACT_APP_PROFILE === 'testing') {
            const getAccessToken = async () => {
                try {
                    const accessToken = await getAccessTokenSilently({
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                        scope: process.env.REACT_APP_SCOPE,
                    });
                    setAccessToken(accessToken);

                } catch (e) {
                    console.log(e.message);
                }
            };
            getAccessToken();
        }
    }, [getAccessTokenSilently, user]);

    if (process.env.REACT_APP_PROFILE === 'prod' || process.env.REACT_APP_PROFILE === 'testing') {
        if (error) {
            return <div>Oops... {error.message}</div>;
        }

        if (isLoading) {
            return <div>Loading...</div>;
        }

        if (!isAuthenticated) {
            return loginWithRedirect();
        }
    }

    console.log("App Control Version: 0.3")
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <Header/>
                    <Outlet/>
                </Provider>
            </ThemeProvider>
        </div>
    );
}

export default App;
