import axios from "axios";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {setBigBlocksInUnity} from '../unity/react-unity-adapter'


export const updateBigBlocks = createAsyncThunk('bigBlocks/update', async ({projectId, bigBlocks}) => {
    try {
        console.log('Update BigBlocks: ', projectId, bigBlocks)
        let result = await axios.post(`/api/projects/${projectId}/bigblock`, bigBlocks)
        //setBigBlocksInUnity(result)
        return result
    } catch (err) {
        return err.message
    }
})

export const getBigBlocks = createAsyncThunk('bigBlocks/get', async ({projectId}) => {
    try {
        console.log('Get BigBlocks: ', projectId)
        let result = await axios.get(`/api/projects/${projectId}/bigblock`)
        setBigBlocksInUnity(result.data)
        return result
    } catch (err) {
        return err.message
    }
})

const bigBlockSlice = createSlice({
    name: 'bigBlock',
    initialState: {
        customBigBlocks: [],
        loading: false,
    },
    reducers: {},

})

export default bigBlockSlice.reducer