import PropTypes from "prop-types";
import {Chip} from "@mui/material";


function StoTag({text, color}) {
    return (
        <Chip label={text} color={color} style={{
            borderRadius: '11px',
            border: '1px solid #CDCDCD',
            fontSize: '14px',
            fontWeight: '700',
            height: '23px',
            textColor: 'black',
            alignItems: 'center',
        }}/>
    )
}

StoTag.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.string
}

export default StoTag