import React from 'react'
import './export.css'
import {ExportPricingCategory} from './ExportPricingCategory'
import {selectText} from './Helpers'


export const ExportLevel3CostTable = (props) => {

    const exportData = props.data
    const unitSystem = props.unitSystem
    const pricingLevel = props.pricingLevel

    console.log(exportData)

    return (
        <div>
            <table id="costTable" className={"export-table"}>
                <tbody>
                <tr>
                    <td colSpan="5" className="infoblockTitle copytable"  onMouseDown={() => selectText("costTable")}>
                        Project price
                    </td>
                </tr>


                {
                    exportData.pricingSummaries.map(
                        function (category, i) {
                            return (<ExportPricingCategory category={category} unitSystem={unitSystem} key={i} pricingLevel={pricingLevel}/>)
                        },
                    )
                }
                {
                    exportData.customValues.map(
                        function (category, i) {
                            return (<ExportPricingCategory category={category} unitSystem={unitSystem} key={i} pricingLevel={pricingLevel}/>)
                        },
                    )
                }
                {
                    exportData.result.map(
                        function (category, i) {
                            return (<ExportPricingCategory category={category} unitSystem={unitSystem} key={i} pricingLevel={pricingLevel}/>)
                        },
                    )
                }



                </tbody>
            </table>
        </div>
    )
}