import {createContext, useContext, useState} from 'react';
import {makeStyles} from "@mui/styles";

export const NotificationContext = createContext({
    message: null,
    messageDetails: null,
    messageType: null,
    showNotification: () => {
    }
});

export function useNotification() {
    return useContext(NotificationContext);
}

const useStyle = makeStyles(() => ({
    root: {
        width: '100vw',
        height: 'calc(100vh - 5.1rem)',
        backgroundColor: '#ffd700',
        overflow: 'hidden',
    },
    notification: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        minHeight: '4rem',
        backgroundColor: '#ffd700',
        paddingTop: '1rem',
        paddingLeft: '1rem',
        fontSize: '1.5rem',
        boxSizing: 'border-box',
        textAlign: 'left'
    },
    closeIcon: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        width: '2rem',
        height: '2rem',
        backgroundColor: '#ffd700',
        borderRadius: '50%',
        textAlign: 'center',
        fontFamily: 'sans-serif',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        cursor: 'pointer',

    },
    seeMore: {
        marginLeft: '1rem',
        fontSize: '1.2rem',
        display: 'inline-block',
        cursor: 'pointer',
    },
    seeMoreContent: {
        fontSize: '1.2rem',
        marginTop: '1rem',
        marginBottom: '1rem',
    }
}));

export function NotificationProvider({children, ...props}) {
    const classes = useStyle(props.theme);
    const [message, setMessage] = useState(null);
    const [messageDetails, setMessageDetails] = useState(null);
    const [messageType, setMessageType] = useState(null);
    const [seeMore, setSeeMore] = useState(false)


    const showNotification = (msg, msgType, msgDetails) => {
        setMessage(msg);
        setMessageType(msgType)
        setMessageDetails(msgDetails)
        //setTimeout(() => setMessage(null), 5000); // Nachricht nach 5 Sekunden ausblenden
    };

    function showMore() {
        setSeeMore(true);
    }

    function showLess() {
        setSeeMore(false);
    }

    function closeMessage() {
        setMessage(null);
        setMessageType(null);
        setMessageDetails(null);
    }


    return (
        <NotificationContext.Provider value={{message, showNotification}}>
            {children}

            {message && <>
                <div className={classes.notification}>
                    {messageType}{message}
                    {messageDetails &&
                    <>
                        {!seeMore && <div className={classes.seeMore} onClick={showMore}>[see more]</div>}

                        {seeMore && <>
                            <div className={classes.seeMore} onClick={showLess}>[see less]</div>
                            <div className={classes.seeMoreContent}>
                                {messageDetails}
                            </div>
                        </>}
                    </>}
                    <div className={classes.closeIcon} onClick={closeMessage}>X</div>
                </div>
            </>}

        </NotificationContext.Provider>
    );
}

