/* eslint-disable react-hooks/exhaustive-deps */
import {v4} from "uuid";
import {arrayMove} from "../../utils/arrayUtils";
import {makeStyles} from "@mui/styles";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import WorkPackageTable from "./WorkPackageTable";
import React, {Fragment, useEffect, useState} from "react";
import {Typography} from "@mui/material";
import StoButton from "../../utils/components/StoButton";

import PlusIcon from "../../utils/icons/plus.svg"
import {CalculationTypes} from "../CalculationType";
import {useDispatch, useSelector} from "react-redux";
import {getSchedulingSettings, resetSchedulingSettings, updateSchedulingSettings} from "./schedulingSettingsSlice";
import {useParams} from "react-router-dom";
import {getPanelTypes} from "../../panelType/panelTypeSlice";

const useStyle = makeStyles(() => ({
    tbody: {
        display: "block",
        width: "100%",
        overflow: "auto",
        background: "#cccccc"
    },
    title: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    header: {
        height: "2.4rem",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "2.4rem",
        paddingLeft: "6.2rem",
        paddingTop: "1.6rem",
    },
    headerEnd: {
        display: "flex",
        width: "47rem",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
    },
    addItem: {
        background: "white",
        textAlign: "left",
        height: "3.6rem",
    }
}));

const SortableCont = SortableContainer(({children}) => {
    const classes = useStyle();

    return <div className={classes.tbody}>{children}</div>;
});

const WorkPackageWrapper = SortableElement(props => <WorkPackageTable {...props} />);

let phases = []

function Phases() {
    const classes = useStyle();

    const dispatch = useDispatch()
    const {projectId} = useParams()

    const {schedulingSettings, ignoreUpdate } = useSelector(state => state.schedulingSettings)
    const [rerender, setRerender] = useState(false)

    useEffect(() => {
        if (ignoreUpdate) {
            return
        }

        if (!schedulingSettings || !schedulingSettings.phases) {
            dispatch(getSchedulingSettings({projectId: projectId}))
            dispatch(getPanelTypes({projectId: projectId}))
            return
        }

        const phasesSetUp = JSON.parse(JSON.stringify(schedulingSettings.phases))

        phasesSetUp
            .forEach(phase => {
                const oldPhase = phases.find(p => p.id === phase.id)

                phase.rename = false
                phase.collapse = oldPhase ? oldPhase.collapse : true

                phase.workPackages.forEach(wp => {
                    wp.rename = false
                })
            })

        phases = phasesSetUp
        setRerender(!rerender)
    }, [dispatch, projectId, schedulingSettings])

    function addPhase() {
        updatePhases([...phases, {
            name: "",
            id: v4(),
            calculationType: CalculationTypes.PANEL_PER_DAY,
            rename: true,
            newItem: true,
            workPackages: []
        }], false)
    }

    function editPhases(id) {
        const phase = phases.find(i => i.id === id)
        if (!phase) {
            return
        }

        phase.rename = true
        updatePhases(phases.map(p => id.id === id ? phase : p), false)
    }

    function renamePhase(id, name, oldName) {
        const phase = phases.find(p => p.id === id)
        if (!phase) {
            return
        }

        if (name === "") {
            if (phase.newItem) {
                removePhase(id, false)
                return
            }
            name = oldName
        }

        phase.newItem = false
        phase.rename = false
        phase.name = name
        updatePhases(phases.map(p => p.id === id ? phase : p))
    }

    function changeCalculationType(id, calculationType) {
        const phase = phases.find(p => p.id === id)
        if (!phase) {
            return
        }

        phase.calculationType = calculationType
        updatePhases(phases.map(p => p.id === id ? phase : p))
    }

    function sort({oldIndex, newIndex}) {
        updatePhases(arrayMove(phases, oldIndex, newIndex))
    }

    function removePhase(id, updateBackend = true) {
        updatePhases(phases.filter(p => p.id !== id), updateBackend)
    }

    function toggleCollapse(id) {
        const phase = phases.find(p => p.id === id)
        if (!phase) {
            return
        }

        phase.collapse = !phase.collapse
        updatePhases(phases.map(p => p.id === id ? phase : p), false)
    }

    function updateWorkPackages(phaseId, workPackages, updateBackend = true) {
        const newPhases = phases.map(p => p.id === phaseId ? {...p, workPackages} : p)
        updatePhases(newPhases, updateBackend)
    }

    function updatePhases(updatePhases, updateBackend = true) {
        phases = updatePhases
        const schedulingSettings = {
            phases: updatePhases
        }
        setRerender(!rerender)

        if (updateBackend) {
            dispatch(updateSchedulingSettings({projectId: projectId, schedulingSettings: schedulingSettings}))
        }
    }

    return (
        <Fragment>
            <Typography variant={"h6"}>General Settings</Typography>
            <div className={classes.title}>
                <Typography variant={"h1"} onClick={() => dispatch(resetSchedulingSettings({projectId:projectId}))}>Phases</Typography>
                <StoButton name={"Add Phase"} onClick={() => addPhase()} icon={PlusIcon}/>
            </div>
            <div className={classes.header}>
                <Typography variant={"labelRegular"}>Name</Typography>
                <div className={classes.headerEnd}>
                    <Typography variant={"labelRegular"} style={{width: "24.2rem"}}>Calculation Unit</Typography>
                    <Typography variant={"labelRegular"} style={{width: "11.2rem"}}>Team Size</Typography>
                    <Typography variant={"labelRegular"} style={{width: "5.6rem"}}>Actions</Typography>
                </div>
            </div>

            <div>
                <SortableCont
                    onSortEnd={sort}
                    axis="y"
                    lockAxis="y"
                    lockToContainerEdges={true}
                    lockOffset={["30%", "50%"]}
                    helperClass="draggingTable"
                    useDragHandle={true}
                >
                    {phases.map((phase, index) => {
                            return <WorkPackageWrapper key={index}
                                                       index={index}
                                                       phase={phase}
                                                       updateWorkPackages={(workPackages, updateBackend) => updateWorkPackages(phase.id, workPackages, updateBackend)}
                                                       toggleCollapse={toggleCollapse}
                                                       editPhase={editPhases}
                                                       renamePhase={renamePhase}
                                                       changeCalculationType={changeCalculationType}
                                                       removePhase={removePhase}/>
                        }
                    )}
                </SortableCont>
            </div>
        </Fragment>
    )
}

export default Phases