import axios from "axios";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {createFloor} from "../floor/floorSlice";
import {
    createElevation
} from "../elevation/elevationSlice";

export const getFloorPlans = createAsyncThunk('plan/floorPlans', async ({projectId}) => {
    try {
        console.log("Get all floor plans")
        return await axios.get(`/api/projects/${projectId}/plan/floorPlans`)
    } catch (err) {
        return err.message
    }
})

export const getElevationPlans = createAsyncThunk('plan/elevationPlans', async ({projectId}) => {
    try {
        console.log("Get all elevation plans")
        return await axios.get(`/api/projects/${projectId}/plan/elevationPlans`)
    } catch (err) {
        return err.message
    }
})

export const deletePlan = createAsyncThunk('plan/delete', async ({projectId, planId, planType}) => {
        return await axios.delete(`/api/projects/${projectId}/plan`, {params: {planId: planId, planType: planType}})
})

const plansSlice = createSlice({
    name: 'plans',
    initialState: {
        floorPlans: [],
        elevationPlans: [],
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getFloorPlans.pending, (state) => {
                state.loading = true
            })
            .addCase(getFloorPlans.fulfilled, (state, action) => {
                state.loading = false
                state.floorPlans = action.payload.data
            })
            .addCase(getFloorPlans.rejected, (state) => {
                state.loading = false
            })
            .addCase(createFloor.fulfilled, (state, action) => {
                const floorPlan = action.payload.data
                if (floorPlan && state.floorPlans) {
                    state.floorPlans.push(floorPlan)
                }
            })
            .addCase(getElevationPlans.pending, (state) => {
                state.loading = true
            })
            .addCase(getElevationPlans.fulfilled, (state, action) => {
                state.loading = false
                state.elevationPlans = action.payload.data
            })
            .addCase(getElevationPlans.rejected, (state) => {
                state.loading = false
            })
            .addCase(createElevation.fulfilled, (state, action) => {
                const {planDTO: elevationPlan} = action.payload.data
                if (elevationPlan && state.elevationPlans) {
                    state.elevationPlans.push(elevationPlan)
                }
            })
            .addCase(deletePlan.fulfilled, (state, action) => {
                state.loading = false
                if (action.meta.arg.planType === 'FLOOR') {
                    state.floorPlans = action.payload.data
                } else if (action.meta.arg.planType === 'ELEVATION') {
                    state.elevationPlans = action.payload.data
                }
            })
            .addCase(deletePlan.rejected, (state) => {
                state.loading = false
            })
            .addCase(deletePlan.pending, (state) => {
                state.loading = true
            })
    }
})

export default plansSlice.reducer
