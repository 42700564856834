import React, {useEffect, useState} from "react";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import WorkPackageRow from "./WorkPackageRow";
import {makeStyles} from "@mui/styles";
import {Collapse, Typography} from "@mui/material";
import PlusIcon from "../../utils/icons/plus.svg";
import PhaseRow from "./PhaseRow";
import {arrayMove} from "../../utils/arrayUtils";
import {v4} from "uuid";
import {CalculationUnitTime} from "../CalculationType";


const useStyle = makeStyles(() => ({
    header: {
        backgroundColor: "#F4F6F7",
        borderBottom: "solid 1px #CCCCCC",
    },
    tbody: {
        display: "block",
        width: "100%",
        overflow: "auto",
        background: "#cccccc"
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
    },
    addItem: {
        display: "flex",
        alignItems: "center",
        gap: ".8rem",

        height: "4.8rem",
        cursor: "pointer",

        paddingLeft: "6.6rem",
    },
    icon: {
        width: "1.6rem",
    }
}));

const SortableCont = SortableContainer(({children}) => {
    const classes = useStyle();

    return <div className={classes.tbody}>{children}</div>;
});

const WorkPackage = SortableElement(props => <WorkPackageRow {...props} />);

const WorkPackageTable = ({
                              phase,
                              updateWorkPackages,
                              editPhase,
                              renamePhase,
                              changeCalculationType,
                              removePhase,
                              toggleCollapse
                          }) => {
    const classes = useStyle();

    const [workPackages, setWorkPackages] = useState(phase.workPackages);
    const [collapse, setCollapse] = useState(phase.collapse);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        setWorkPackages(phase.workPackages)
    }, [phase.workPackages])

    useEffect(() => {
        setCollapse(phase.collapse)
    }, [phase.collapse])

    function addWorkPackage() {
        updateWorkPackages([...workPackages, {
            name: "",
            id: v4(),
            rename: true,
            newItem: true,
            calculationUnit: CalculationUnitTime.PER_DAY,
            teamSize: 2
        }], false)
    }

    function editWorkPackage(id) {
        const item = workPackages.find(i => i.id === id)
        if (!item) {
            return
        }

        item.rename = true
        updateWorkPackages(workPackages.map(i => i.id === id ? item : i), false)
    }

    function renamePackage(id, name, oldName) {
        const item = workPackages.find(i => i.id === id)
        if (!item) {
            return
        }

        if (name === "") {
            if (item.newItem) {
                removePackage(id, false)
                return
            }
            name = oldName
        }

        item.newItem = false
        item.rename = false
        item.name = name
        updateWorkPackages(workPackages.map(i => i.id === id ? item : i), true)
    }

    function removePackage(id, updateBackend = true) {
        updateWorkPackages(workPackages.filter(i => i.id !== id), updateBackend)
    }

    function updateTeamSize(id, teamSize) {
        const item = workPackages.find(i => i.id === id)
        if (!item) {
            return
        }

        item.teamSize = teamSize
        updateWorkPackages(workPackages.map(i => i.id === id ? item : i), true)
    }

    function updateCalculationUnit(id, calculationUnit) {
        const item = workPackages.find(i => i.id === id)
        if (!item) {
            return
        }

        item.calculationUnit = calculationUnit
        updateWorkPackages(workPackages.map(i => i.id === id ? item : i), true)
    }

    function sort({oldIndex, newIndex}) {
        updateWorkPackages(arrayMove(workPackages, oldIndex, newIndex), true)
    }

    return (
        <div>
            <PhaseRow phase={phase}
                      editPhase={editPhase}
                      renamePhase={renamePhase}
                      changeCalculationType={changeCalculationType}
                      removePhase={removePhase}
                      toggleCollapse={toggleCollapse}/>
            <div className={classes.table}>
                <SortableCont
                    onSortEnd={sort}
                    axis="y"
                    lockAxis="y"
                    lockToContainerEdges={true}
                    lockOffset={["30%", "50%"]}
                    helperClass="draggingRow"
                    useDragHandle={true}>
                    <Collapse in={collapse}>
                        {workPackages.map((item, index) => (
                            <WorkPackage
                                key={index}
                                index={index}
                                item={item}
                                editItem={editWorkPackage}
                                renameItem={renamePackage}
                                removeItem={removePackage}
                                updateTeamSize={updateTeamSize}
                                updateCalculationUnit={updateCalculationUnit}
                            />
                        ))}
                        <div className={classes.addItem} onClick={addWorkPackage}
                             onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
                             style={{background: hover ? "#F4F6F7" : "white"}}>
                            <img className={classes.icon} alt={'icon'} src={PlusIcon}/>
                            <Typography variant={"subtitle1"}>Add position</Typography>
                        </div>
                    </Collapse>
                </SortableCont>
            </div>
        </div>
    );
};

export default WorkPackageTable