import axios from "axios";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setFinishTypes} from "../unity/react-unity-adapter";

export const getFinishTypes = createAsyncThunk('finishTypes/get', async () => {
    try {
        console.log("Get all finish types")
        return await axios.get(`/api/finishTypes`)
    } catch (err) {
        return err.message
    }
})

const finishTypeSlice = createSlice({
    name: 'finishTypes',
    initialState: {
        finishTypes: [],
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getFinishTypes.pending, (state) => {
                state.loading = true
            })
            .addCase(getFinishTypes.fulfilled, (state, action) => {
                state.loading = false
                state.finishTypes = action.payload.data
                setFinishTypes(state.finishTypes)
            })
            .addCase(getFinishTypes.rejected, (state) => {
                state.loading = false
            })

    }
})

export default finishTypeSlice.reducer