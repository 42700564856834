import * as React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import theme from "../../theme";

const RadioIcon = styled('span')(() => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: "transparent",
    '.Mui-focusVisible &': {
       outline: '2px auto rgba(19,124,189,.6)',
       outlineOffset: 2,
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
    },
}));

const RadioCheckedIcon = styled(RadioIcon)({
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
});

function StoRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<RadioCheckedIcon />}
            icon={<RadioIcon />}
            {...props}
        />
    );
}

export default StoRadio