export function getFeetInchFromMeters(meters) {
    if (meters === null || meters === undefined)
        return {feet: 0, inches: 0}

    const feet = (meters / 0.3048) + 0.000001
    const feetInt = Math.floor(feet)
    const inches = (feet - feetInt) * 12

    return {
        feet: feetInt,
        inches: inches
    }
}

export function getMeterFromFeetInch(feetInches) {
    if (feetInches === null || feetInches === undefined)
        return 0

    const splitValue = feetInches.split(" ").filter(item => item.length > 0)

    if (splitValue.length === 0) {
        return 0
    }

    let {feet, inches} = getFeetsAndInches(splitValue[0])

    if (splitValue.length === 2) {
        const {feet:feet2, inches:inches2} = getInches(splitValue[1])
        feet += feet2
        inches += inches2

    }
    return feet * 0.3048 + inches * 0.0254
}

export function getPricePerMeterFromPricePerFeet(pricePerFeet) {

    return pricePerFeet / 0.3048
}

export function getPricePerSquareMeterFromPricePerSquareFeet(pricePerFeet) {

    return pricePerFeet / 0.3048 / 0.3048
}

function getFeetsAndInches(value) {
    const doubleParagraphCount = (value.match('"')||[]).length
    const paragraphCount = (value.match('\'')||[]).length

    value = value.replace("\"", '')
    value = value.replace(',', '.')
    value = value.replace('\'', '')
    value = value.replace(' ', '')

    if (value.length === 0 || isNaN(value)) {
        return {feet: 0, inches: 0}
    }

    if (doubleParagraphCount > 0 && paragraphCount === 0) {
        return {
            feet: 0,
            inches: value
        }
    }

    const feet = Math.floor(value)
    const inches = (value - feet) * 12

    return {feet: feet, inches: inches}
}

function getInches(value) {
    value = value.replace(',', '.');
    value = value.replace('\'\'', '.');
    value = value.replace('"', '');
    value = value.replace(' ', '');

    if (value.length === 0 || isNaN(value)) {
        return {
            feet: 0,
            inches: 0
        }
    }

    return {
        feet: Math.floor(value / 12),
        inches: value % 12
    }
}
