import React from 'react'
import './export.css'


export const ExportElevationPreview = (props) => {

    const exportData = props.data
    const project = props.project
    const elevationResult = props.elevationResult
    let image = (elevationResult.location === "??") ?
        project.blobStorageUrl + "projects/" + project.id + "/screenshots/elevations/visual_hidden.png"
        :
        project.blobStorageUrl + "projects/" + project.id + "/screenshots/elevations/visual_" + elevationResult.location +".png"



    const link = (elevationResult.location === "??") ? "hiddenFaces" : elevationResult.location

    const name = exportData.elevations.find(result => result.id === elevationResult.location) ? exportData.elevations.find(result => result.id === elevationResult.location).name : "No Assigned Materials"

    return (

        <a className="elevationElement" href={"export/" + link}>
            <img src={image} alt="Elevation Preview"/>
                {name}
                <div className="spacer"></div>
        </a>
)

};

