import {makeStyles} from "@mui/styles";
import StoSidebarItem from "./StoSidebarItem";
import React, {useState} from "react";
import {Collapse, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import StoIconButton from "../../utils/components/StoIconButton";
import ResetIcon from "../../utils/icons/reset.svg";
import SaveIcon from "../../utils/icons/save.svg";
import SaveDoneIcon from "../../utils/icons/save-done.svg";
import ResetDoneIcon from "../../utils/icons/reset-done.svg";

import {
    resetSchedulingSettingsToCompanyDefault,
    saveSchedulingSettingsAsCompanyDefault
} from "./schedulingSettingsSlice";
import {useParams} from "react-router-dom";
import StoSnackbar from "../../utils/components/StoSnackbar";
import StoPrimaryButton from "../../utils/components/StoPrimaryButton";
import StoDialog from "../../utils/components/StoDialog";


const useStyle = makeStyles(_ => ({
    sidebar: {
        height: "100%",
        width: "30rem",
        borderRight: "solid .1rem #CCCCCC",
    }

}));


function SchedulingSettingSidebar({sidebarItem, setSidebarItem}) {
    const classes = useStyle();

    const [collapseGeneral, setCollapseGeneral] = useState(true);
    const {projectId} = useParams();
    const [collapsePanelTypes, setCollapsePanelTypes] = useState(true);
    const isAdmin = useSelector(state => state.projects.userRoles).some(role => role === 'admin:all')
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.projects.companyId)
    const panelTypes = useSelector(state => state.panelTypes.panelTypes).filter(panelType => panelType.active)
    const panelTypesItems = getPanelTypesItems()
    const schedulingSettings = useSelector(state => state.schedulingSettings.schedulingSettings)
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarIcon, setSnackbarIcon] = useState(null)
    const [saveOpen, setSaveOpen] = useState(false)

    function toggleCollapseGeneral() {
        setCollapseGeneral(!collapseGeneral);
    }

    function toggleCollapsePanelTypes() {
        setCollapsePanelTypes(!collapsePanelTypes);
    }

    function getPanelTypesItems() {
        return panelTypes
            .map((panelType, index) => {
                return <StoSidebarItem name={panelType.name} key={index} activeItem={sidebarItem}
                                       setSidebarItem={setSidebarItem}/>
            })
    }

    function handleResetClick() {
        dispatch(resetSchedulingSettingsToCompanyDefault({projectId})).then(() => {
            setSnackbarMessage("Scheduling settings successfully reset to company settings")
            setSnackbarIcon(ResetDoneIcon)
            setShowSnackbar(true)
        })
    }
    function openSaveDialog(event) {
        event.stopPropagation()
        setSaveOpen(true)
    }

    const closeSaveDialog = (event) => {
        event.stopPropagation()
        setSaveOpen(false)
    }
    function handleSaveClick() {
        setSaveOpen(false)
        dispatch(saveSchedulingSettingsAsCompanyDefault({companyId, schedulingSettings})).then(() => {
            setSnackbarMessage("Scheduling settings successfully saved as company default settings")
            setSnackbarIcon(SaveDoneIcon)
            setShowSnackbar(true)

        })
    }


    return (
        <div className={classes.sidebar}>
            <StoSnackbar open={showSnackbar}
                         vertical={'top'} horizontal={'center'}
                         duration={3000}
                         onClose={() => setShowSnackbar(false)}
                         message={snackbarMessage}
                         icon={snackbarIcon}
            />
            <div style={{height: '4.8rem', borderBottom: "solid .1rem #EDEDED"}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%', paddingLeft: '1.2rem', paddingRight: '.8rem'}}>
                    <Typography style={{paddingRight: '1rem'}}>Scheduling Settings</Typography>
                    <div style={{display: 'flex'}}>
                        <StoIconButton
                            icon={ResetIcon}
                            tooltip={"Reset to company standard scheduling settings"}
                            onClick={handleResetClick}
                        />
                        {isAdmin && <StoIconButton
                            icon={SaveIcon}
                            tooltip={"Define current scheduling settings as default company settings"}
                            onClick={openSaveDialog}
                        />}
                    </div>
                </div>
            </div>
            <StoSidebarItem name={"General"} toggleCollapse={toggleCollapseGeneral} collapse={collapseGeneral}
                            setSidebarItem={toggleCollapseGeneral}/>
            <Collapse in={collapseGeneral}>
                <StoSidebarItem name={"Working Capacity"} activeItem={sidebarItem} setSidebarItem={setSidebarItem}/>
                <StoSidebarItem name={"Phases"} activeItem={sidebarItem} setSidebarItem={setSidebarItem}/>
            </Collapse>
            <StoSidebarItem name={"Time Settings"} toggleCollapse={toggleCollapsePanelTypes}
                            collapse={collapsePanelTypes} setSidebarItem={toggleCollapsePanelTypes}/>
            <Collapse in={collapsePanelTypes}>
                {panelTypesItems}
            </Collapse>

            <StoDialog open={saveOpen} onClose={(event) => closeSaveDialog(event)}
                       title={""}
                       maxWidth={"36rem"}
                       content={
                           <div>
                               <Typography variant={"h1"} className={classes.deleteProjectTitle}>
                                   {"Are you sure you want to override your company standard settings?"}</Typography>
                               <Typography variant={"subtitle1"} style={{paddingTop: "2rem"}}>
                                   {"This will override the company standard settings for all projects in your company."}</Typography>
                           </div>
                       }
                       actions={<div className={classes.dialogActions}>

                           <StoPrimaryButton onClick={(event) => handleSaveClick(event)} title={"Save"}/>
                           <StoPrimaryButton onClick={(event) => closeSaveDialog(event)} title={"Cancel"} secondary/>

            </div>}/>

        </div>
    )
}

export default SchedulingSettingSidebar