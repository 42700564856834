import {useSelector} from "react-redux";
import {unitSystems} from "../../unitSystem/unitSystemSlice";
import {getFeetInchFromMeters, getMeterFromFeetInch} from "../UnitConverter";
import {TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";

const FractionDigits = 2

function StoLengthInput({
                            title,
                            value,
                            placeholder,
                            className,
                            textClassName = null,
                            onClick = () => {
                            },
                            onBlur = () => {
                            },
                            style = {},
                            padding,
                        }) {

    const unitSystem = useSelector(state => state.unitSystem.unitSystem)

    const [internalValue, setInternalValue] = useState(getValue(value))

    useEffect(() => {
        setInternalValue(getValue(value))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    function getValue(v) {
        if (unitSystems.METRICAL === unitSystem) {
            return parseFloat(v).toFixed(FractionDigits) + " m"
        } else {
            const {inches, feet} = getFeetInchFromMeters(v)

            v = `${feet}' ${inches.toFixed(FractionDigits)}"`
        }

        return v
    }


    function onChangeValue(changedValue) {
        setInternalValue(changedValue)
    }

    function onBlurValue(blurredValue) {
        if (blurredValue.length === 0) {
            setInternalValue(getValue(value))
            return
        }

        if (unitSystems.METRICAL !== unitSystem) {
            blurredValue = getMeterFromFeetInch(blurredValue)
        } else {
            blurredValue = blurredValue.replace("m", "")
            blurredValue = blurredValue.replace(",", ".")
            blurredValue = blurredValue.replace(" ", "")
        }

        if (isNaN(blurredValue)
            || blurredValue === parseFloat(value).toFixed(FractionDigits)) {
            setInternalValue(getValue(value))
            return
        }

        onBlur(blurredValue)

        setInternalValue(getValue(blurredValue))
    }

    const customStyle = {...style, padding: padding || "2.4rem 0"}

    return (
        <div style={customStyle}>
            {title ? <Typography variant={"subtitle1"}>{title}</Typography> : null}
            <TextField fullWidth size={"small"}
                       variant="outlined"
                       value={internalValue}
                       onClick={onClick}
                       className={className}
                       onChange={event => onChangeValue(event.target.value)}
                       onBlur={event => onBlurValue(event.target.value)}
                       placeholder={placeholder}
                       InputProps={textClassName ? {
                           className: textClassName,
                       } : null}
            />
        </div>
    );
}

export default StoLengthInput

