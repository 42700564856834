import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@mui/styles";
import {FormControlLabel, Typography} from "@mui/material";
import theme from "../../theme";
import StoRadio from "./StoRadio";

const useStyle = makeStyles(theme => ({
    unit: {
        width: "15rem",
        height: "10.8rem",
        backgroundColor: 'theme.palette.neutral.primary10',
        border: 'solid 1px rgba(127, 127, 127, 0.15)',
        borderRadius: "3px",
        display: "flex",
        justifyContent: "center",
        margin: "0!important",
        '&:hover': {
            //backgroundColor: theme.palette.neutral.primary0,
            border: `solid 1px ${theme.palette.primary.main}`
        }
    },

    project: {
        width: "flex",
        height: "4rem",
        backgroundColor: 'theme.palette.neutral.primary10',
        border: 'solid 1px rgba(127, 127, 127, 0.15)',
        borderRadius: "3px",
        display: "flex",
        paddingRight: "1rem",
        justifyContent: "center",
        margin: "0!important",
        '&:hover': {
            //backgroundColor: theme.palette.neutral.primary0,
            border: `solid 1px ${theme.palette.primary.main}`
        }
    },
    titleunit: {
        textAlign: "center",
    },
    titleproject: {
        textAlign: "right"
    }
}));

function StoRadioCard({title, subtitle, selected, value, type, ...props}) {
    const classes = useStyle(props.theme);
    return ((type === 'unit') ?
            <FormControlLabel className={classes.unit} value={value} labelPlacement={"top"}
                              style={{backgroundColor: selected === value ? theme.palette.neutral.primary0 : null}}
                              label={
                                  <div className={classes.titleunit}>
                                      <Typography style={{fontWeight: selected === value ? 600 : null}}
                                                  variant={'body1'}>{title}</Typography>
                                      <Typography variant={'body2'}
                                                  style={{paddingTop: "0.6rem"}}>{subtitle}</Typography>
                                  </div>
                              }
                              control={
                                  <StoRadio checked={selected === value} value={value}/>
                              }>
            </FormControlLabel>
            :

            <FormControlLabel className={classes.project} value={value} labelPlacement={"right"}
                              style={{backgroundColor: selected === value ? theme.palette.neutral.primary0 : null}}
                              label={
                                  <div className={classes.titleproject}>
                                      <Typography style={{fontWeight: selected === value ? 600 : null}}
                                                  variant={'body1'}>{title}</Typography>
                                  </div>
                              }
                              control={
                                  <StoRadio checked={selected === value} value={value}/>
                              }>
            </FormControlLabel>

    );
}

StoRadioCard.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    selected: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
StoRadioCard.defaultPropTypes = {};
export default StoRadioCard;