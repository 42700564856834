import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@mui/styles";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import Paper from '@mui/material/Paper';
import ProjectTableRow from "./ProjectTableRow";
import {ReactComponent as SortIcon} from "../../utils/icons/sort.svg"
import {ReactComponent as SortIconUp} from "../../utils/icons/sortup.svg"
import {ReactComponent as SortIconDown} from "../../utils/icons/sortdown.svg"
import ProjectTableRowInBin from "./ProjectTableRowInBin";


const useStyle = makeStyles(theme => ({
    root: {
        boxShadow: "none!important",
    },
    headerCell: {
        paddingBottom: "5px!important",
        borderBottom: `1px solid ${theme.palette.neutral.primary40}!important`,
        backgroundColor: theme.palette.neutral.primary10,
        '&:hover': {
            cursor: "default"
        }

    },

    cell: {
        maxHeight: "5rem"
    },
    test: {
        display: "flex",
        verticalAlign: "center!important"
    }
}));

function useForceUpdate() {
    //eslint-disable-next-line
    const [value, setValue] = React.useState(0);
    return () => setValue(value => value + 1);
}

function ProjectList({projects, ...props}) {
    const classes = useStyle(props.theme);

    const cellNames = ["Name", "Client", "Date of creation", "Latest Change", ""]    //["Name", "Client", "Creator", "Created", "Last used", ""]
    const [listData, setListData] = React.useState(Object.values(projects))
    const [lastClicked, setLastClicked] = React.useState(["Latest Change", false])
    const forceUpdate = useForceUpdate();

/*
    // hide the zendesk web widget
    useEffect(() => {
        setTimeout(() => {
            const iframeElement = document.getElementById('launcher');
            if (iframeElement) {
                iframeElement.style.display = 'none';
            }
        }, 1000); // wait for 1 second
    }, []);

 */

    const sortTable = (name) => {
        let tmpData = listData
        let reverse = false
        if (name === 'Name') {
            if (lastClicked[0] === 'Name' && lastClicked[1] === false) {
                setLastClicked(['Name', true])
                reverse = true
            } else if (lastClicked[0] === 'Name' && lastClicked[1] === true) {
                setLastClicked(['Name', false])
                reverse = false
            } else {
                setLastClicked(['Name', false])
            }

            tmpData.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1
                }
                return 0;
            })
        }
        if (name === 'Date of creation') {
            if (lastClicked[0] === 'Date of creation' && lastClicked[1] === false) {
                setLastClicked(['Date of creation', true])
                reverse = true
            } else if (lastClicked[0] === 'Date of creation' && lastClicked[1] === true) {
                setLastClicked(['Date of creation', false])
                reverse = false
            } else {
                setLastClicked(['Date of creation', false])
            }
            tmpData.sort(function (a, b) {
                return new Date(b.creationDate) - new Date(a.creationDate)
            })

        }

        if (name === 'Latest Change') {
            if (lastClicked[0] === 'Latest Change' && lastClicked[1] === false) {
                setLastClicked(['Latest Change', true])
                reverse = true
            } else if (lastClicked[0] === 'Latest Change' && lastClicked[1] === true) {
                setLastClicked(['Latest Change', false])
                reverse = false
            } else {
                setLastClicked(['Latest Change', false])
            }
            tmpData.sort(function (a, b) {
                return new Date(b.lastChangedWithTimestamp) - new Date(a.lastChangedWithTimestamp)
            })
        }

        if (reverse) {
            tmpData.reverse()
        }
        setListData(tmpData)
        forceUpdate()
    }

    const getSortIcon = (name) => {
        const activeColumn = lastClicked[0]
        const direction = lastClicked[1]
        if (name === "" || name === "Client") {
            return
        }
        if (name === activeColumn) {
            if (!direction) {
                return <SortIconDown/>
            } else {
                return <SortIconUp/>
            }
        } else {
            return <SortIcon/>
        }
    }


    return (
        <TableContainer component={Paper} className={classes.root}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {cellNames.map((name, i) => {
                            return <TableCell className={classes.headerCell} key={i}>
                                <div style={{display: "flex", alignItems: "center"}} onClick={() => sortTable(name)}>
                                    <Typography variant={"subtitle1"}>{name}
                                    </Typography>
                                    {getSortIcon(name)}
                                </div>
                            </TableCell>
                        })}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {listData.map((project) => (
                        !project.deleted ? (
                            <ProjectTableRow key={project.id} project={project}/>
                        ) : (
                            <ProjectTableRowInBin key={project.id} project={project}/>
                        )
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

ProjectList.propTypes = {
    projects: PropTypes.array.isRequired,
};
ProjectList.defaultPropTypes = {};
export default ProjectList;