import {useDispatch, useSelector} from "react-redux";
import {Typography} from "@mui/material";
import StoCheckbox, {CHECKBOX_STATES} from "../utils/components/StoCheckbox";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {updatePanelType, updatePanelTypes} from "./panelTypeSlice";

function PanelTypes() {
    const dispatch = useDispatch();
    const {projectId} = useParams();

    const [allPanelTypesCheckbox, setAllPanelTypesCheckboxCheckbox] = useState(CHECKBOX_STATES.Empty)

    const panelTypes = useSelector(state => state.panelTypes.panelTypes)

    useEffect(() => {
        const actives = panelTypes.filter(panelType => panelType.active).length
        const inactives = panelTypes.filter(panelType => !panelType.active).length

        const checkboxState = actives === panelTypes.length ?
            CHECKBOX_STATES.Checked :
            inactives === panelTypes.length ?
                CHECKBOX_STATES.Empty :
                CHECKBOX_STATES.Indeterminate
        setAllPanelTypesCheckboxCheckbox(checkboxState)
    }, [panelTypes])

    function getPanelTypeCheckboxes() {
        return panelTypes.map(panelType =>
            <StoCheckbox key={panelType.id} title={panelType.name} value={panelType.active}
                         onChange={value => changePanelType(panelType.id, value)}/>)
    }

    function changePanelType(id, value) {
        const allPanelTypes = JSON.parse(JSON.stringify(panelTypes))
        const panelType = allPanelTypes.find(panelType => panelType.id === id)
        panelType.active = value

        dispatch(updatePanelType({projectId: projectId, panelType: panelType}))
    }

    function changeAll(value) {
        const allPanelTypes = JSON.parse(JSON.stringify(panelTypes))
        allPanelTypes.forEach(panelType => panelType.active = value)

        dispatch(updatePanelTypes({projectId: projectId, panelTypes: allPanelTypes}))
    }

    return <div>
        <Typography variant={'h1'}>Panel Types</Typography>
        <StoCheckbox title={"All"} value={allPanelTypesCheckbox}
                     onChange={changeAll}/>
        {getPanelTypeCheckboxes()}
    </div>
}

export default PanelTypes;