import axios from "axios";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {setUnitSystemUnity} from "../unity/react-unity-adapter";

export const unitSystems = {
    IMPERIAL: 'IMPERIAL',
    METRICAL: 'METRICAL',
}

export const getUnitSystem = createAsyncThunk('unitSystem/get', async ({projectId}) => {
    try {
        console.log('Get unit system: ', projectId)
        let response;
        response = await axios.get(`/api/projects/${projectId}/unitSystem`);
        setUnitSystemUnity(response.data)

        return response
    } catch (err) {
        return err.message
    }
})

const unitSystemSlice = createSlice({
    name: 'unitSystem',
    initialState: {
        unitSystem: null,
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getUnitSystem.pending, (state) => {
                state.loading = true
            })
            .addCase(getUnitSystem.fulfilled, (state, {payload}) => {
                state.unitSystem = payload.data
                state.loading = false
            })
            .addCase(getUnitSystem.rejected, (state) => {
                state.loading = false
            })
    }
})

export default unitSystemSlice.reducer