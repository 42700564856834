import {DateTime, Interval} from "luxon";

export function getNextDay(date, days = 1) {
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + days);
    return nextDay;
}

// getPreviousDay
export function getPreviousDay(date, days = 1) {
    const previousDay = new Date(date);
    previousDay.setDate(date.getDate() - days);
    return previousDay;
}

export function getDayName(date) {
    const daysInWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return daysInWeek[date.getDay()]
}

export function getAmountOfWorkingDay(start, end, workingDays) {
    let currentDate = new Date(start);
    let workingDaysCount = 0

    // Iterate through each day in the time span
    while (end - currentDate > 1000) {
        // Check if the current day is a non-working day (holiday)
        if (workingDays.includes(getDayName(currentDate))) {
            workingDaysCount += 1
        }
        currentDate = getNextDay(currentDate)
    }

    return workingDaysCount
}

export function getExactAmountOfWorkingDay(start, end, workingDays) {
    let luxonStart = DateTime.fromJSDate(start)
    let luxonEnd = DateTime.fromJSDate(end)

    let allDays = luxonEnd.diff(luxonStart, 'days').toObject().days

    const notWorkingDays = Interval.fromDateTimes(luxonStart, luxonEnd).splitBy({days: 1})
        .reduce((notWorkingDays, interval) =>
            !workingDays.includes(getDayName(interval.start.toJSDate())) ? notWorkingDays + 1 : notWorkingDays, 0)

    return allDays - notWorkingDays
}

export function getAmountOfWorkingDay2(start, end, workingDays) {
    let currentDate = new Date(start);
    let workingDaysCount = 0

    // Iterate through each day in the time span
    while (end - currentDate > 1000) {
        // Check if the current day is a non-working day (holiday)
        if (workingDays.includes(getDayName(currentDate))) {
            workingDaysCount += 1
        }
        currentDate = getNextDay(currentDate)
    }

    return workingDaysCount
}

export function getEndDateByDurationAndWorkingDays(start, duration, workingDays) {
    let currentDate = new Date(start);
    let coveredDays = 0

    // Iterate through each day in the time span
    while (coveredDays < duration) {
        // Check if the current day is a non-working day (holiday)
        const currentDay = getDayName(currentDate);
        if (workingDays.includes(currentDay)) {
            coveredDays += 1
        }
        currentDate = getNextDay(currentDate)
    }

    return currentDate
}

export function getEndDateByDurationAndWorkingDaysHalfDaySteps(start, duration, workingDays) {
    let currentDate = new Date(start);
    let coveredDays = 0

    // Iterate through each day in the time span
    while (coveredDays < duration) {
        // Check if the current day is a non-working day (holiday)
        const currentDay = getDayName(currentDate);
        if (workingDays.includes(currentDay)) {
            coveredDays += .5
        }
        currentDate = DateTime.fromJSDate(currentDate).plus({hours: 12}).toJSDate()
    }

    return currentDate
}

export function getEndDateByDurationAndWorkingDays3(start, duration, workingDays) {
    let luxonEnd = DateTime.fromJSDate(start).plus({days: duration})
    let diff = duration - getExactAmountOfWorkingDay(start, luxonEnd.toJSDate(), workingDays)

    let counter = 0

    while (diff > 0 && counter < 100) {
        luxonEnd = luxonEnd.plus({days: diff})
        diff = duration - getExactAmountOfWorkingDay(start, luxonEnd.toJSDate(), workingDays)
        counter++
    }

    return luxonEnd.toJSDate()
}

export function getEndDateByDurationAndWorkingDays2(start, duration, workingDays) {
    let currentDate = new Date(start);
    let coveredDays = 0

    const days = Math.floor(duration)
    const hours = (duration - days) * 24
    const minutes = (hours - Math.floor(hours)) * 60

    // Iterate through each day in the time span
    while (coveredDays < days) {
        // Check if the current day is a non-working day (holiday)
        const currentDay = getDayName(currentDate);
        if (workingDays.includes(currentDay)) {
            coveredDays += 1
        }
        currentDate = getNextDay(currentDate)
    }

    let coveredHours = 0

    while (coveredHours < hours) {
        // Check if the current day is a non-working day (holiday)
        const currentDay = getDayName(currentDate);
        if (workingDays.includes(currentDay)) {
            coveredHours += 1
        }
        currentDate = DateTime.fromJSDate(currentDate).plus({hours: 1}).toJSDate()
    }

    let coveredMinutes = 0

    while (coveredMinutes < minutes) {
        // Check if the current day is a non-working day (holiday)
        const currentDay = getDayName(currentDate);
        if (workingDays.includes(currentDay)) {
            coveredMinutes += 1
        }
        currentDate = DateTime.fromJSDate(currentDate).plus({minutes: 1}).toJSDate()
    }

    return currentDate
}

export function getStartDateByDurationAndWorkingDays3(end, duration, workingDays) {
    let luxonStart = DateTime.fromJSDate(end).minus({days: duration})
    let diff = duration - getExactAmountOfWorkingDay(luxonStart.toJSDate(), end, workingDays)

    let counter = 0

    while (diff > 0 && counter < 100) {
        luxonStart = luxonStart.minus({days: diff})
        diff = duration - getExactAmountOfWorkingDay(luxonStart.toJSDate(), end, workingDays)
        counter++
    }

    return luxonStart.toJSDate()
}

export function getStartDateByDurationAndWorkingDays2(end, duration, workingDays) {
    let currentDate = new Date(end);
    let coveredDays = 0

    const days = Math.floor(duration)
    const hours = (duration - days) * 24
    const minutes = (hours - Math.floor(hours)) * 60

    // Iterate through each day in the time span
    while (coveredDays < days) {
        // Check if the current day is a non-working day (holiday)
        const currentDay = getDayName(currentDate);
        if (workingDays.includes(currentDay)) {
            coveredDays += 1
        }
        currentDate = getPreviousDay(currentDate)
    }

    let coveredHours = 0

    while (coveredHours < hours) {
        // Check if the current day is a non-working day (holiday)
        const currentDay = getDayName(currentDate);
        if (workingDays.includes(currentDay)) {
            coveredHours += 1
        }
        currentDate = DateTime.fromJSDate(currentDate).minus({hours: 1}).toJSDate()
    }

    let coveredMinutes = 0

    while (coveredMinutes < minutes) {
        // Check if the current day is a non-working day (holiday)
        const currentDay = getDayName(currentDate);
        if (workingDays.includes(currentDay)) {
            coveredMinutes += 1
        }
        currentDate = DateTime.fromJSDate(currentDate).minus({minutes: 1}).toJSDate()
    }

    return currentDate
}

export function adjustEndDate(start, duration, workingDays, workingHoursPerDay) {
    let currentDate = new Date(start);
    let coveredHours = 0
    let endDate = DateTime.fromJSDate(currentDate).plus({day: 1}).toJSDate()

    // Iterate through each day in the time span
    while (coveredHours < duration) {
        // Check if the current day is a non-working day (holiday)
        const currentDay = getDayName(currentDate);
        if (workingDays.includes(currentDay)) {
            coveredHours += workingHoursPerDay
        }
        currentDate = getNextDay(currentDate)
        endDate = currentDate
    }

    return endDate
}

export function calculateTaskLength(start, end) {
    let diff = end - start;
    let years = Math.floor(diff / (365 * 24 * 60 * 60 * 1000));
    diff -= years * 365 * 24 * 60 * 60 * 1000;
    let months = Math.floor(diff / (30 * 24 * 60 * 60 * 1000));
    diff -= months * 30 * 24 * 60 * 60 * 1000;
    let weeks = Math.floor(diff / (7 * 24 * 60 * 60 * 1000));
    diff -= weeks * 7 * 24 * 60 * 60 * 1000;
    let days = Math.floor(diff / (24 * 60 * 60 * 1000));
    diff -= days * 24 * 60 * 60 * 1000;
    let hours = Math.floor(diff / (60 * 60 * 1000));
    diff -= hours * 60 * 60 * 1000;
    let minutes = Math.floor(diff / (60 * 1000));

    let result = "";

    if (years > 0) {
        result += `${years}y, `;
    }
    if (months > 0) {
        result += `${months}m, `;
    }
    if (weeks > 0) {
        result += `${weeks}w, `;
    }
    if (days > 0) {
        result += `${days}d, `;
    }
    if (hours > 0) {
        result += `${hours}h, `;
    }
    if (minutes > 0) {
        result += `${minutes}m`;
    }

    return result.trim();
}

export function getPreviousWorkingDay(date, workingDays) {
    let currentDay = date
    while (!workingDays.includes(getDayName(currentDay))) {
        currentDay = getNextDay(currentDay, -1)
    }
    return currentDay
}

export function getPreviousWorkingDayByDuration(date, duration, workingDays) {
    let currentDay = date
    let coveredDays = 0
    while (coveredDays < duration) {
        currentDay = getNextDay(currentDay, -1)
        if (workingDays.includes(getDayName(currentDay))) {
            coveredDays += 1
        }
    }
    return currentDay
}

export function getPreviousWorkingDayByDuration2(date, duration, workingDays) {
    let currentDay = date
    let coveredDays = 0
    while (coveredDays < duration) {
        if (workingDays.includes(getDayName(currentDay))) {
            coveredDays += 1
        }
    }
    return currentDay
}

export function getPreviousWorkingDayByDurationHalfDay(date, duration, workingDays) {
    let currentDay = date
    let coveredDays = 0
    while (coveredDays < duration) {
        currentDay = DateTime.fromJSDate(currentDay).minus({hours: 12}).toJSDate()
        if (workingDays.includes(getDayName(currentDay))) {
            coveredDays += .5
        }
    }
    return currentDay
}

export function getNextWorkingDay(date, workingDays) {
    let currentDay = date
    while (!workingDays.includes(getDayName(currentDay))) {
        currentDay = getNextDay(currentDay)
    }
    return currentDay
}

export function getNextWorkingDayByDuration(date, duration, workingDays) {
    let currentDay = date
    let coveredDays = 0
    while (coveredDays < duration) {
        currentDay = getNextDay(currentDay)
        if (workingDays.includes(getDayName(currentDay))) {
            coveredDays += 1
        }
    }
    return currentDay
}

export function getNextWorkingDayByDurationHalfDay(date, duration, workingDays) {
    let currentDay = date
    let coveredDays = 0
    while (coveredDays < duration) {
        currentDay = DateTime.fromJSDate(currentDay).plus({hours: 12}).toJSDate()
        if (workingDays.includes(getDayName(currentDay))) {
            coveredDays += .5
        }
    }
    return currentDay
}

export function adjustMidnight(date) {
    // Clone the input date to avoid modifying the original object
    const adjustedDate = new Date(date);

    // Set the hours, minutes, and seconds to 0
    adjustedDate.setHours(0, 0, 0);

    return adjustedDate;
}

export function adjustLastMinuteOfDay(date) {
    // Clone the input date to avoid modifying the original object
    const adjustedDate = new Date(date);

    // Set the hours, minutes, and seconds to 0
    adjustedDate.setHours(23, 59, 59);

    return adjustedDate;
}

export function adjustToHalfDay(date) {
    const adjustedDate = new Date(date);

    if (adjustedDate.getHours() < 12) {
        adjustedDate.setHours(12, 0, 0)
    } else {
        adjustedDate.setHours(23, 59, 59)
    }
    return adjustedDate
}

export function adjustToHalfDay2(date) {
    const adjustedDate = new Date(date);

    if (adjustedDate.getHours() < 12) {
        adjustedDate.setHours(0, 0, 0)
    } else {
        adjustedDate.setHours(12, 0, 0)
    }
    return adjustedDate
}

export function getDiffDays(startDate, endDate) {
    return DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), 'days').days
}

export function getDiffDaysInWorkingDays(startDate, endDate, workingDays) {
    const flip = startDate < endDate
    if (startDate > endDate) {
        const tmp = startDate
        startDate = endDate
        endDate = tmp
    }

    let diffDays = 0
    let currentDate = new Date(startDate)
    while (endDate - currentDate > 1000) {
        if (workingDays.includes(getDayName(currentDate))) {
            diffDays += 1
        }

        currentDate = getNextDay(currentDate)
    }

    if (flip) {
        diffDays *= -1
    }

    return diffDays
}

export function getDiffDaysInWorkingDays2(startDate, endDate, workingDays) {
    const halfDays = startDate.getHours() !== endDate.getHours() ? .5 : 0

    const flip = startDate < endDate
    if (startDate > endDate) {
        const tmp = startDate
        startDate = endDate
        endDate = tmp
    }

    let diffDays = 0
    let currentDate = new Date(startDate)
    while (endDate - currentDate > 1000) {
        if (workingDays.includes(getDayName(currentDate))) {
            diffDays += 1
        }

        currentDate = getNextDay(currentDate)
    }

    diffDays -= halfDays

    if (flip) {
        diffDays *= -1
    }

    return diffDays
}

export function getJSDate(backendDate) {
    const {year, month, day, hour, minute} = backendDate
    const result = new Date(year + '-' + month + '-' + day)
    result.setHours(hour, minute, 0)

    return result
}

export function getBackendDate(jsDate) {
    return {
        year: jsDate.getFullYear(),
        month: jsDate.getMonth() + 1,
        day: jsDate.getDate(),
        hour: jsDate.getHours(),
        minute: jsDate.getMinutes(),
    }
}

export function getDurationInDays(task, workingDays) {
    const amountOfDays = getAmountOfWorkingDay(task.start, task.end, workingDays)
    const halfDays = (task.start.getHours() === 12 && task.end.getHours() !== 12)
    || (task.start.getHours() !== 12 && task.end.getHours() === 12)
        ? 0.5
        : 0

    return amountOfDays - halfDays
}