import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {CircularProgress, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {Visibility, VisibilityOff} from "@mui/icons-material";


const useStyle = makeStyles(theme => ({
    root: {
        padding: "2.4rem 0",
    },
    textField: {
        borderRadius: '2px',
        '& .MuiOutlinedInput-root': {
            borderRadius: "2px",
            backgroundColor: theme.palette.neutral.white,
            fontSize: '13px',
            marginTop: '0.6rem',
        },
    },
}));

function StoTextInput({
                          title, value, placeholder, icon, showPassword, isPassword, onDoubleClick = () => {
    }, alignment, width, endText, onClick, maxLength = () => {
    }, onChange = () => {
    }, onBlur = () => {
    }, padding, multiline, ...props
                      }) {
    const classes = useStyle({...props.theme, padding});
    const [showPw, setShowPw] = React.useState(showPassword);

    const selectIcon = () => {
        if (icon === 'valid') {
            return <DoneIcon size={"2rem"} color={'success'} fontSize={'large'}/>
        } else if (icon === 'invalid') {
            return <CloseIcon size={"2rem"} color={'error'} fontSize={'large'}/>
        } else if (icon === 'loading') {
            return <CircularProgress size={"2rem"}/>
        } else if (icon === 'password' && !showPw) {
            return <IconButton onClick={() => setShowPw(!showPw)}>
                <Visibility size={"2rem"} fontSize={'large'}/>
            </IconButton>
        } else if (icon === 'password' && showPw) {
            return <IconButton onClick={() => setShowPw(!showPw)}>
                <VisibilityOff size={"2rem"} fontSize={'large'}/>
            </IconButton>
        } else {
            return null
        }
    }

    const inputStyle = {
        min: 0,
        width: width || '100%',
        textAlign: alignment || "left",
    }

    if (multiline) {
        inputStyle.padding = '0'
        inputStyle.paddingTop = '0.35rem'
        inputStyle.marginLeft = '-.2rem'
    }

    return (
        <div className={classes.root} style={{padding: padding || "2.4rem 0"}}>
            <Typography variant={"subtitle1"}>{title}</Typography>
            <TextField fullWidth
                       size={"small"}
                       className={classes.textField}
                       variant="outlined"
                       value={value}
                       onClick={onClick}
                       placeholder={placeholder}
                       onChange={onChange}
                       type={!showPw && isPassword ? 'password' : 'text'}
                       onBlur={onBlur}
                       multiline={multiline}
                       onDoubleClick={onDoubleClick}
                       rows={multiline ? 6 : 1}
                       InputProps={{
                           inputProps: {
                               maxLength: maxLength || 1000,
                               style: inputStyle
                           },
                           endAdornment: (
                               <div>
                                   <Typography variant={'subtitle1'} paddingLeft={'1rem'}>{endText}</Typography>
                                   <InputAdornment position="end">
                                       {selectIcon()}
                                   </InputAdornment>
                               </div>
                           ),
                       }}
                       tabIndex={2}
            />
        </div>
    );
}

StoTextInput.propTypes = {
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.string,
    padding: PropTypes.string,

};
StoTextInput.defaultProps = {
    padding: "2.4rem 0",
};
export default StoTextInput;
