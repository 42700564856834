import {makeStyles} from "@mui/styles";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import React from "react";
import {ArrowDropDown} from "@mui/icons-material";
import PricingSummaryItem from "./PricingSummaryItem";


const useStyle = makeStyles(() => ({
    root: {
        display: 'block',
        width: '100%',
        //paddingBottom: '2rem',
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        },
        //width: '80rem',
    },

    box: {
        display: 'block',

        gridTemplateColumns: '1fr 1fr 1fr',
        justifyContent: 'center',
        "&.MuiButtonBase-root-MuiAccordionSummary-root": {
            alignItems: 'flex'
        }

    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        paddingLeft: '1.5em',
    },
    focusVisible: {
        backgroundColor: 'white!important',
    },
    backup: {
        paddingTop: '0.5rem',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: '50px',
        paddingBottom: '1rem'
        // paddingLeft: '7px'
    },
    level3: {},
    pricingCategoryFont: {
        fontFamily: 'FrutigerNext-Bd',
        fontSize: '16px !important',
        display: 'flex',
        alignItems: 'center',
    },
    tooltipIcon: {
        marginLeft: '0.5rem',
    },
    pricingItemFont: {

        fontSize: '16px !important',
        fontFamily: 'FrutigerNext !important',
        fontWeight: '400 !important'

    },
    placeHolder: {
        width: '16rem',
        height: '1rem',
    }

}));

function PricingSummaryCategory({category, key, unitSystem, isResult, ...props}) {
    const classes = useStyle({...props.theme});


    return (

        <Accordion className={classes.root} elevation={0} disableGutters={true} defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ArrowDropDown/>}
                aria-controls={'panel-content' + key}
                id={'panel-header' + key}
                expander={true}
                focusVisibleClassName={classes.focusVisible}
                style={{height: '6rem', width: '85rem'}}
            >
                <div className={classes.box}>

                    <Typography
                        className={classes.pricingCategoryFont}>{category.pricingCategory}

                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {category.items && category.items.length > 0 && category.items.map((item, i) => {
                    return (
                        <div key={i}>
                            <PricingSummaryItem item={item} unitSystem={unitSystem}
                                                backgroundColor={i % 2 === 0 ? '#ffffff' : 'rgba(244, 246, 247, 0.50)'}
                                                isResult={isResult}/>
                        </div>
                    )
                })}
                {category.summaries && category.summaries.length > 0 && category.summaries.map((item, i) => {
                    return (
                        <div key={i}>
                            <PricingSummaryItem item={item} unitSystem={unitSystem}
                                                backgroundColor={i % 2 === 0 ? '#ffffff' : 'rgba(244, 246, 247, 0.50)'}
                                                lastElement={i + 1 === category.summaries.length} isResult={isResult}/>
                        </div>
                    )
                })}
            </AccordionDetails>
        </Accordion>


    );
}

export default PricingSummaryCategory;