import React from "react";
import {SortableHandle} from "react-sortable-hoc";
import {makeStyles} from "@mui/styles";
import {Typography} from "@mui/material";

import HandleSortIcon from "../../utils/icons/handleSort.svg";
import RenameIcon from "../../utils/icons/rename.svg";
import DeleteIcon from "../../utils/icons/trash.svg";
import CollapseIcon from "../../utils/icons/collapse.svg"

import StoInput from "../../utils/components/StoInput";
import StoSwitchButton from "../../utils/components/StoSwitchButton";
import {
    getCalculationType,
    getCalculationTypes,
    translateCalculationType,
    translateCalculationTypes
} from "../CalculationType";

const useStyle = makeStyles(() => ({
    item: {
        height: "4.8rem",

        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",

        paddingLeft: ".8rem",
        paddingRight: ".8rem",

        background: "#F4F6F7",

        borderBottom: "solid .1rem #cccccc",
    },
    name: {
        display: "flex",
        alignItems: "center",
    },
    content: {
        display: "flex",
        alignItems: "center",
        gap: ".8rem",
    },
    buttons: {
        display: "flex",
        alignItems: "center",
        gap: "2.4rem",
    },
    iconGroup: {
        display: "flex",
        alignItems: "center",
        gap: ".8rem",
    },
    icon: {
        width: "2.4rem",
    },
    smallIcon: {
        width: "1.6rem",
    }
}))

const RowHandler = SortableHandle((props) =>
    <img alt={'icon'} src={HandleSortIcon} className={props.className}/>);

const PhaseRow = ({phase, changeCalculationType, renamePhase, editPhase, removePhase, toggleCollapse}) => {
    const classes = useStyle()

    const [showActions, setShowActions] = React.useState(false)

    const name = phase.name.toUpperCase()

    const showGanttChartViewType = false

    return (
        <div className={classes.item}
             onMouseEnter={_ => setShowActions(true)}
             onMouseLeave={_ => setShowActions(false)}>
            <div className={classes.content}>
                <RowHandler className={classes.icon}/>
                <img className={classes.smallIcon} alt={'icon'} src={CollapseIcon}
                     onClick={() => toggleCollapse(phase.id)}
                     style={{transform: phase.collapse ? "rotate(0deg" : "rotate(270deg)"}}/>
                {!phase.rename ?
                    <Typography className={classes.name} variant={"button"}
                                onDoubleClick={() => editPhase(phase.id)}>{name}</Typography> :
                    <StoInput title={phase.name} value={phase.name} id={phase.id} autoFocus={true}
                              onBlur={(event) => renamePhase(phase.id, event.target.value, phase.name)}/>}
            </div>
            <div className={classes.buttons}>
                {showGanttChartViewType && <StoSwitchButton activeOption={name === "MANUFACTURING" ? 'Floor/Elevation' : 'Individual'}
                                 options={['Individual', 'Floor/Elevation']}
                                 onChange={(option) => console.log(option)}/>}
                <StoSwitchButton onChange={option => changeCalculationType(phase.id, getCalculationType(option))}
                                 activeOption={translateCalculationType(phase.calculationType)}
                                 options={translateCalculationTypes(getCalculationTypes())}/>
                <div style={{width: "11.8rem"}}/>
                {showActions ?
                    <div className={classes.iconGroup}>
                        <img className={classes.icon} alt={'icon'} src={RenameIcon}
                             onClick={() => editPhase(phase.id)}/>
                        <img className={classes.icon} alt={'icon'} src={DeleteIcon}
                             onClick={() => removePhase(phase.id)}/>
                    </div>
                    : <div style={{width: "5.6rem"}}/>
                }
            </div>
        </div>
    )
};

export default PhaseRow
