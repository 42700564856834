/*
import {makeStyles} from "@mui/styles";

const useStyle = makeStyles(() => ({

}))
*/
import StoPricingMenu from "../../utils/components/StoPricingMenu";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getPricing} from "../pricingSlice";
import {useParams} from "react-router-dom";

function PricingLevel1() {
    const unit = useSelector(state => state.unitSystem.unitSystem)
    const panelTypes = useSelector(state => state.panelTypes.panelTypes).filter((type => type.active === true))
    const dispatch = useDispatch()
    const {projectId} = useParams();

    useEffect(() => {
        dispatch(getPricing({projectId: projectId}))
        //eslint-disable-next-line
    }, []);

    return (
        <div>
                {
                    panelTypes.map((type, i) => {

                        return <StoPricingMenu name={type.name} id={type.id} key={i} projectId={projectId}
                                               unit={unit} isAccordion={true}></StoPricingMenu>
                    })}

        </div>
    )
}

export default PricingLevel1