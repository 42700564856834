import PropTypes from "prop-types";

function TrashIcon({color='#7F7F7F', width="28px", height="28px"}) {
    return (
        <svg version="1.1" width={width} height={height} fill={color} stroke={color} viewBox="0 0 28.0 28.0"
             xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id="i0">
                    <path
    d="M13.5,0 C13.7761424,-5.07265313e-17 14,0.223857625 14,0.5 L14,11.5 C14,11.7761424 13.7761424,12 13.5,12 L0.5,12 C0.223857625,12 -2.43738069e-16,11.7761424 0,11.5 L0,0.5 C-3.38176876e-17,0.223857625 0.223857625,2.72771136e-16 0.5,0 L13.5,0 Z"/>
                </clipPath>
                <clipPath id="i1">
                    <path
    d="M8.5,0 C8.77614237,-5.07265313e-17 9,0.223857625 9,0.5 C9,0.776142375 8.77614237,1 8.5,1 L0.5,1 C0.223857625,1 2.55862292e-16,0.776142375 0,0.5 C-3.38176876e-17,0.223857625 0.223857625,5.07265313e-17 0.5,0 L8.5,0 Z"/>
                </clipPath>
                <clipPath id="i2">
                    <path
    d="M14,0 C14.5522847,-1.01453063e-16 15,0.44771525 15,1 L15,2 C15,2.55228475 14.5522847,3 14,3 L1,3 C0.44771525,3 -2.65431532e-16,2.55228475 0,2 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L14,0 Z"/>
                </clipPath>
                <clipPath id="i3">
                    <path
    d="M7,0 C7.55228475,-1.01453063e-16 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 L1,2 C0.44771525,2 6.76353751e-17,1.55228475 0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L7,0 Z"/>
                </clipPath>
            </defs>
            <g transform="translate(6.5 5.0)">
                <g transform="translate(13.5 17.0) rotate(90.0) scale(-1.0 1.0)">
                    <g clipPath="url(#i0)">
                        <path
                            d="M0.5,0 L13.5,0 C13.7761424,-5.07265313e-17 14,0.223857625 14,0.5 L14,11.5 C14,11.7761424 13.7761424,12 13.5,12 L0.5,12 C0.223857625,12 -2.43738069e-16,11.7761424 0,11.5 L0,0.5 C-3.38176876e-17,0.223857625 0.223857625,2.72771136e-16 0.5,0 Z"
                            stroke={color} strokeWidth="2" fill="none" strokeMiterlimit="5"/>
                    </g>
                </g>
                <g transform="translate(5.499999999999999 14.5) rotate(90.0) scale(-1.0 1.0)">
                    <g clipPath="url(#i1)">
                        <polygon points="0,0 9,0 9,1 0,1 0,0" stroke="none" fill={color}/>
                    </g>
                </g>
                <g transform="translate(7.999999999999999 14.5) rotate(90.0) scale(-1.0 1.0)">
                    <g clipPath="url(#i1)">
                        <polygon points="0,0 9,0 9,1 0,1 0,0" stroke="none" fill={color}/>
                    </g>
                </g>
                <g transform="translate(10.5 14.5) rotate(90.0) scale(-1.0 1.0)">
                    <g clipPath="url(#i1)">
                        <polygon points="0,0 9,0 9,1 0,1 0,0" stroke="none" fill={color}/>
                    </g>
                </g>
                <g transform="translate(0.0 1.0)">
                    <g clipPath="url(#i2)">
                        <path
                            d="M1,0 L14,0 C14.5522847,-1.01453063e-16 15,0.44771525 15,1 L15,2 C15,2.55228475 14.5522847,3 14,3 L1,3 C0.44771525,3 -2.65431532e-16,2.55228475 0,2 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"
                            stroke={color} strokeWidth="2" fill="none" strokeMiterlimit="5"/>
                    </g>
                </g>
                <g transform="translate(3.5 0.0)">
                    <g clipPath="url(#i3)">
                        <path
                            d="M1,0 L7,0 C7.55228475,-1.01453063e-16 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 L1,2 C0.44771525,2 6.76353751e-17,1.55228475 0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"
                            stroke={color} strokeWidth="2" fill="none" strokeMiterlimit="5"/>
                    </g>
                </g>
            </g>
        </svg>

    )
}

TrashIcon.propTypes = {
    // width: PropTypes.string,
    // height: PropTypes.string,
    color: PropTypes.string
};


export default TrashIcon