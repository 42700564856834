import axios from "axios";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {setPricingSummary} from "../unity/react-unity-adapter";

export const getPricingSummary = createAsyncThunk('pricingSummary/get', async ({projectId}) => {
    try {
        console.log('Get pricing summary: ', projectId)
        return await axios.get(`/api/projects/${projectId}/pricingSummary`)
    } catch (err) {
        return err.message
    }
})


export const updatePricingSummary = createAsyncThunk('pricingSummary/update', async ({projectId, pricingSummary}) => {
    try {
        console.log('Update pricing summary: ', projectId, pricingSummary)
        return await axios.put(`/api/projects/${projectId}/pricingSummary`, pricingSummary)
    } catch (err) {
        return err.message
    }
})

export const updatePricingSummaryItem = createAsyncThunk('pricingSummary/updateItem', async ({projectId, pricingSummaryItem}) => {
    try {
        console.log('Update pricing summary: ', projectId, pricingSummaryItem)
        return await axios.put(`/api/projects/${projectId}/pricingSummary/item`, pricingSummaryItem)
    } catch (err) {
        return err.message
    }
})

export const addPricingSummaryItem = createAsyncThunk('pricingSummary/addItem', async ({projectId}) => {
    try {
        console.log('Add pricing summary: ', projectId)
        return await axios.post(`/api/projects/${projectId}/pricingSummary`)
    } catch (err) {
        return err.message
    }
})

export const deletePricingSummaryItem = createAsyncThunk('pricingSummary/deleteItem', async ({projectId, itemId}) => {
    try {
        console.log('Delete pricing summary: ', projectId, itemId)
        return await axios.delete(`/api/projects/${projectId}/pricingSummary/${itemId}`)
    } catch (err) {
        return err.message
    }
})
const pricingSummarySlice = createSlice({
    name: 'pricingSummary',
    initialState: {
        pricingSummary: {},
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getPricingSummary.pending, (state) => {
                state.loading = true
            })
            .addCase(getPricingSummary.fulfilled, (state, {payload}) => {
                const pricingSummary = payload.data
                if (!pricingSummary) {
                    return
                }
                state.pricingSummary = pricingSummary
                state.loading = false
                setPricingSummary(pricingSummary)
            })
            .addCase(getPricingSummary.rejected, (state) => {
                state.loading = false
            })

            .addCase(updatePricingSummary.pending, (state) => {
                state.loading = true
            })
            .addCase(updatePricingSummary.fulfilled, (state, {payload}) => {
                const pricingSummary = payload.data
                if (!pricingSummary) {
                    return
                }
                state.pricingSummary = pricingSummary
                state.loading = false
                setPricingSummary(pricingSummary)
            })
            .addCase(updatePricingSummary.rejected, (state) => {
                state.loading = false
            })


            .addCase(updatePricingSummaryItem.pending, (state) => {
                state.loading = true
            })
            .addCase(updatePricingSummaryItem.fulfilled, (state, {payload}) => {
                const pricingSummary = payload.data
                if (!pricingSummary) {
                    return
                }
                state.pricingSummary = pricingSummary
                state.loading = false
                setPricingSummary(pricingSummary)
            })
            .addCase(updatePricingSummaryItem.rejected, (state) => {
                state.loading = false
            })
            .addCase(addPricingSummaryItem.pending, (state) => {
                state.loading = true
            })
            .addCase(addPricingSummaryItem.fulfilled, (state, {payload}) => {
                const pricingSummary = payload.data
                if (!pricingSummary) {
                    return
                }
                state.pricingSummary = pricingSummary
                state.loading = false
                setPricingSummary(pricingSummary)
            })
            .addCase(addPricingSummaryItem.rejected, (state) => {
                state.loading = false
            })
            .addCase(deletePricingSummaryItem.pending, (state) => {
                state.loading = true
            })
            .addCase(deletePricingSummaryItem.fulfilled, (state, {payload}) => {
                const pricingSummary = payload.data
                if (!pricingSummary) {
                    return
                }
                state.pricingSummary = pricingSummary
                state.loading = false
                setPricingSummary(pricingSummary)
            })
            .addCase(deletePricingSummaryItem.rejected, (state) => {
                state.loading = false
            })
    }
})

export default pricingSummarySlice.reducer