import axios from "axios";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {addItemToLoadingQueue, setFloor} from "../unity/react-unity-adapter";
import {getStacking} from "../stacking/stackingSlice";

//region Floor

export const getFloor = createAsyncThunk('floor/get', async ({projectId, floorId}) => {
    try {
        console.log('Get floor: ', projectId, floorId)
        return await axios.get(`/api/projects/${projectId}/floorPlan/${floorId}`)
    } catch (err) {
        return err.message
    }
})
export const createFloor = createAsyncThunk('floor/create', async ({projectId, floor, plan}) => {
    try {
        console.log('Create floor_x: ', projectId, floor, plan)
        addItemToLoadingQueue("UploadPlan");
        if (plan) {
            floor.planName = plan.name;
            const data = new FormData()
            data.append('floorDTO', new Blob([JSON.stringify(floor)], {
                type: "application/json"
            }));
            data.append("file", new Blob([plan], {
                type: "application/pdf"
            }));

            return await axios.post(`/api/projects/${projectId}/floorPlan/newPlan`, data)
        } else {
            return await axios.post(`/api/projects/${projectId}/floorPlan/existingPlan`, floor)
        }
    } catch (err) {
        return err.message
    }
})

export const renameFloor = createAsyncThunk('floor/rename', async ({projectId, floorId, name}, thunkAPI) => {
    try {
        console.log('rename floor: ', projectId, floorId, name)
        let result = await axios.put(`/api/projects/${projectId}/floorPlan/${floorId}/rename`, name, {
            headers: {
                'Content-Type': 'text/plain'
            }
        })
        thunkAPI.dispatch(getStacking({projectId: projectId}))
        return result
    } catch (err) {
        return err.message
    }
})

export const deleteFloor = createAsyncThunk('floor/delete', async ({projectId, floorId}) => {
    try {
        console.log('Delete floor: ', projectId, floorId)
        return await axios.delete(`/api/projects/${projectId}/floorPlan/${floorId}`)
    } catch (err) {
        return err.message
    }
})

//endregion

//region Outline

export const createOutline = createAsyncThunk('outline/create', async ({projectId, floorId, outline}) => {
    try {
        console.log('Create outline: ', projectId, outline)
        return await axios.post(`/api/projects/${projectId}/floorPlan/${floorId}/outline`, outline)
    } catch (err) {
        return err.message
    }
})

export const updateOutline = createAsyncThunk('outline/update', async ({projectId, floorId, outline}) => {
    try {
        console.log('Update outline: ', outline)
        return await axios.put(`/api/projects/${projectId}/floorPlan/${floorId}/outline`, outline)
    } catch (err) {
        return err.message
    }
})

export const deleteOutline = createAsyncThunk('outline/delete', async ({projectId, floorId, outlineId}) => {
    try {
        console.log('Delete outline: ', outlineId)
        return await axios.delete(`/api/projects/${projectId}/floorPlan/${floorId}/outline/${outlineId}`)
    } catch (err) {
        return err.message
    }
})

export const updateFloorGeometries = createAsyncThunk('outline/updateFloorGeometries', async ({
                                                                                                  projectId,
                                                                                                  floorId,
                                                                                                  geometries
                                                                                              }) => {
    try {
        console.log('Update floor geometries: ', geometries)
        return await axios.put(`/api/projects/${projectId}/floorPlan/${floorId}/geometries`, geometries)
    } catch (err) {
        return err.message
    }
})

export const copyOutline = createAsyncThunk('outline/copy', async ({projectId, floorId, copyFloorId}) => {
    try {
        console.log('Copy outline from other floor: ', floorId, copyFloorId)
        return await axios.post(`/api/projects/${projectId}/floorPlan/${floorId}/outline/${copyFloorId}`)
    } catch (err) {
        return err.message
    }
})

//endregion

//region Origin

export const updateOrigin = createAsyncThunk('origin/update', async ({projectId, floorId, origin}) => {
    try {
        console.log('Update origin: ', origin)
        return await axios.put(`/api/projects/${projectId}/floorPlan/${floorId}/origin`, origin)
    } catch (err) {
        return err.message
    }
})

//endregion

//region Balcony

export const createBalcony = createAsyncThunk('balcony/create', async ({projectId, floorId, balcony}) => {
    try {
        return await axios.post(`/api/projects/${projectId}/floorPlan/${floorId}/balcony`, balcony)
    } catch (err) {
        return err.message
    }
})

export const updateBalcony = createAsyncThunk('balcony/update', async ({projectId, floorId, balcony}) => {
    try {
        console.log('Update balcony: ', balcony)
        return await axios.put(`/api/projects/${projectId}/floorPlan/${floorId}/balcony`, balcony)
    } catch (err) {
        return err.message
    }
})

export const deleteBalcony = createAsyncThunk('balcony/delete', async ({projectId, floorId, balconyId}) => {
    try {
        console.log('Delete balcony: ', balconyId)
        return await axios.delete(`/api/projects/${projectId}/floorPlan/${floorId}/balcony/${balconyId}`)
    } catch (err) {
        return err.message
    }
})

//endregion

//region Scale Ratio

export const setScale = createAsyncThunk('scaleRatio/set', async ({projectId, floorId, scale}) => {
    try {
        console.log('Set scale ratio: ', floorId, scale)
        return await axios.put(`/api/projects/${projectId}/floorPlan/${floorId}/scale`, scale, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    } catch (err) {
        return err.message
    }
})

export const setCustomScale = createAsyncThunk('scaleRatio/setCustom', async ({projectId, floorId, customScale}) => {
    try {
        console.log('Set custom scale ratio: ', floorId, customScale)
        return await axios.put(`/api/projects/${projectId}/floorPlan/${floorId}/customScale`, customScale)
    } catch (err) {
        return err.message
    }
})

//endregion

//region North Orientation

export const setNorthOrientation = createAsyncThunk('northOrientation/set', async ({
                                                                                       projectId,
                                                                                       floorId,
                                                                                       northOrientation
                                                                                   }) => {
    try {
        console.log('Set north orientation: ', northOrientation)
        return await axios.put(`/api/projects/${projectId}/floorPlan/${floorId}/northOrientation`, northOrientation)
    } catch (err) {
        return err.message
    }
})

const floorSlice = createSlice({
    name: 'floor',
    initialState: {
        floor: {
            id: '',
            name: '',
            plan: null,
            scaleRatio: -1,
            origin: null,
            outlines: [],
            balconies: []
        },
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder

            //region Floor

            .addCase(getFloor.pending, (state) => {
                state.loading = true
            })
            .addCase(getFloor.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)
            })
            .addCase(getFloor.rejected, (state) => {
                state.loading = false
            })
            .addCase(createFloor.pending, (state) => {
                state.loading = true
            })
            .addCase(createFloor.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false

                setTimeout(() => setFloor(payload.data), 1000)
            })
            .addCase(createFloor.rejected, (state) => {
                state.loading = false
            })
            .addCase(renameFloor.pending, (state) => {
                state.loading = true
            })
            .addCase(renameFloor.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)

            })
            .addCase(renameFloor.rejected, (state) => {
                state.loading = false
            })

            //endregion

            //region Outline

            .addCase(createOutline.pending, (state) => {
                state.loading = true
            })
            .addCase(createOutline.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)
            })
            .addCase(createOutline.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateOutline.pending, (state) => {
                state.loading = true
            })
            .addCase(updateOutline.fulfilled, (state, {payload}) => {
                console.log(payload)
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)
            })
            .addCase(updateOutline.rejected, (state) => {
                state.loading = false
            })
            .addCase(deleteOutline.pending, (state) => {
                state.loading = true
            })
            .addCase(deleteOutline.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)
            })
            .addCase(deleteOutline.rejected, (state) => {
                state.loading = false
            })
            .addCase(copyOutline.pending, (state) => {
                state.loading = true
            })
            .addCase(copyOutline.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)
            })
            .addCase(copyOutline.rejected, (state) => {
                state.loading = false
            })

            //endregion

            //region Origin

            .addCase(updateOrigin.pending, (state) => {
                state.loading = true
            })
            .addCase(updateOrigin.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)
            })
            .addCase(updateOrigin.rejected, (state) => {
                state.loading = false
            })

            //endregion

            //region Balcony

            .addCase(createBalcony.pending, (state) => {
                state.loading = true
            })
            .addCase(createBalcony.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)
            })
            .addCase(createBalcony.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateBalcony.pending, (state) => {
                state.loading = true
            })
            .addCase(updateBalcony.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)
            })
            .addCase(updateBalcony.rejected, (state) => {
                state.loading = false
            })
            .addCase(deleteBalcony.pending, (state) => {
                state.loading = true
            })
            .addCase(deleteBalcony.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)
            })
            .addCase(deleteBalcony.rejected, (state) => {
                state.loading = false
            })

            //endregion

            //region Scale Ratio

            .addCase(setScale.pending, (state) => {
                state.loading = true
            })
            .addCase(setScale.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)
            })
            .addCase(setScale.rejected, (state) => {
                state.loading = false
            })

            .addCase(setCustomScale.pending, (state) => {
                state.loading = true
            })
            .addCase(setCustomScale.fulfilled, (state, {payload}) => {
                state.floor = payload.data
                state.loading = false
                setFloor(state.floor)
            })
            .addCase(setCustomScale.rejected, (state) => {
                state.loading = false
            })

            //endregion

    }
})

export default floorSlice.reducer