import React, { useState } from 'react';

const tabStyles = {
    selected: {
        color: '#000',
        textAlign: 'center',
        textIndent: '0',
        textEdge: 'cap',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '17px',
        letterSpacing: '0.1px',
        borderBottom: '3px solid black',
        paddingBottom: '6px',
        margin: '0 10px',
        cursor: 'pointer',
    },
    unselected: {
        color: '#000',
        textAlign: 'center',
        textIndent: '0',
        textEdge: 'cap',
        paddingBottom: '6px',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 450,
        lineHeight: '17px', /* 106.25% */
        letterSpacing: '0.6px',
        margin: '0 10px',
        cursor: 'pointer',
    },
};

const ProjectOverviewTabview = ({ onTabChange }) => {
    const [selectedTab, setSelectedTab] = useState(0); // Initialize the selected tab index

    const handleTabClick = (index) => {
        setSelectedTab(index);
        if (onTabChange) {
            onTabChange(index);
        }
    };

    const tabs = ['My Projects', 'Trash Can'];

    return (
        <div>
            <div className="tab-container" style={{ display: 'flex' }}>
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        className="tab"
                        style={index === selectedTab ? tabStyles.selected : tabStyles.unselected}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProjectOverviewTabview;
