import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@mui/styles";
import {IconButton, Tooltip} from "@mui/material";

const useStyle = makeStyles(theme => ({
    root: {
        margin: "0 3px"
    },
    iconButton: {
        width: '2.8rem',
        height: '2.8rem',
        borderRadius: '3px!important',
        border: 'none',
        padding: "0!important",
        "&:hover": {
            backgroundColor: `${theme.palette.neutral.primary20}`,
        },
    },
}));

function StoIconButton({tooltip, icon, iconComponent, onClick, disabled, ...props}) {
    const classes = useStyle(props.theme);
    return (
        <div className={classes.root}>
            <Tooltip title={tooltip && !disabled ? <h1>{tooltip}</h1> : ""} arrow={true} placement={"bottom-start"} hidden={true}>
                <IconButton className={classes.iconButton}
                            disabled={disabled}
                            disableRipple
                            onClick={onClick}
                >
                    {icon ? <img alt={'icon'} src={icon}/> : iconComponent}
                </IconButton>
            </Tooltip>
        </div>
    );
}

StoIconButton.propTypes = {
    icon: PropTypes.string,
    iconComponent: PropTypes.element,
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};
StoIconButton.defaultPropTypes = {};
export default StoIconButton;