import axios from "axios";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {setClientUnity} from "../unity/react-unity-adapter";

export const getClientInfo = createAsyncThunk('clientInfo/get', async ({projectId}) => {
    try {
        return await axios.get(`/api/projects/${projectId}/clientInfo`)
    } catch (err) {
        return err.message
    }
})

export const updateClientInfo = createAsyncThunk('client/update', async ({projectId, clientInfo}) => {
    try {
        return await axios.put(`/api/projects/${projectId}/clientInfo`, clientInfo)
    } catch (err) {
        return err.message
    }
})

const clientInfoSlice = createSlice({
    name: 'clientInfo',
    initialState: {
        clientInfo: {
            companyName: '',
            location: {
                country: '',
                city: '',
                postCode: null,
                address: ''
            },
            notes: '',
        },
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getClientInfo.pending, (state) => {
                state.loading = true
            })
            .addCase(getClientInfo.fulfilled, (state, {payload}) => {
                state.clientInfo = payload.data
                state.loading = false
                setClientUnity(state.clientInfo)
            })
            .addCase(getClientInfo.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateClientInfo.pending, (state) => {
                state.loading = true
            })
            .addCase(updateClientInfo.fulfilled, (state, {payload}) => {
                state.clientInfo = payload.data
                state.loading = false
                //setClientUnity(state.client)
            })
            .addCase(updateClientInfo.rejected, (state) => {
                state.loading = false
            })
    }
})

export default clientInfoSlice.reducer