import React, {Fragment, useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import {makeStyles} from "@mui/styles";
import {DateTime, Interval} from "luxon";
import StoSidebarItem from "../StoSidebarItem";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getSchedulingPlaning, updateSchedulingPlaning,} from "../planing/schedulingPlaningSlice";
import {translateWeekDays} from "../../utils/weekDays";
import {
    adjustLastMinuteOfDay,
    adjustMidnight,
    adjustToHalfDay,
    adjustToHalfDay2,
    getBackendDate,
    getDayName,
    getDiffDays,
    getDiffDaysInWorkingDays2,
    getEndDateByDurationAndWorkingDays2,
    getEndDateByDurationAndWorkingDaysHalfDaySteps,
    getExactAmountOfWorkingDay,
    getJSDate,
    getNextDay,
    getNextWorkingDay,
    getNextWorkingDayByDuration,
    getNextWorkingDayByDurationHalfDay,
    getPreviousDay,
    getPreviousWorkingDay,
    getPreviousWorkingDayByDurationHalfDay,
    getStartDateByDurationAndWorkingDays2
} from "../../utils/timeUtils";
import SettingsIcon from "../../utils/icons/settings.svg";
import PlusIcon from "../../utils/icons/plus.svg";
import DeleteIcon from "../../utils/icons/trash.svg";
import EditSchedulingPlaningItemDialog from "./EditSchedulingPlaningItemDialog";
import {v4} from "uuid";
import StoSwitchButton from "../../utils/components/StoSwitchButton";
import {updateTasksExpandEnd, updateTasksExpandStart} from "./TaskUtils";

const useStyle = makeStyles(() => ({
    container: {
        width: "100%",
        height: "100%",
        overflowX: "scroll",
        overflowY: "scroll",
        '&::-webkit-overflow-scrolling': 'touch',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    scrollbarTop: {
        overflowX: 'scroll',
        width: 'calc(100% - 30.1rem)',
        position: 'absolute',
        height: '1.61rem',
        top: '5.4rem',
        '&::-webkit-scrollbar': {
            height: '1.6rem',
        },
        '&::-webkit-scrollbar-track': {
            borderTop: '.1rem solid #cccccc',
            borderBottom: '.1rem solid #cccccc',
            borderRadius: '0rem',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '0rem',
        }
    },
    scrollbarTopContent: {
        height: '100%',
    },
    sidebar: {
        position: 'absolute',
        height: 'calc(100% - 14.4rem)',
        width: '30rem',
        overflowY: 'scroll',
        top: '14.4rem',
        left: 0,
        borderTop: '.1rem solid #cccccc',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    switchButton: {
        position: 'absolute',
        display: "block",

        top: "1.2rem",
        right: "1.2rem",

        height: "4.8rem",
        width: "20rem",

        fontSize: "1.2rem",
    },
}));

const dragHandle = 'drag-handle-'
const expandHandle = 'expa' +
    'nd-handle-'

const offsetDaysStart = 92
const offsetDaysEnd = 92
const showCurrentDay = "Before" // "Before" | "After" | "Center" | "None"
const taskBoxMargin = 16
const taskBoxHeight = 32.1
const workingHoursPerDay = 8
// here we define different chart views. in each view we have different widthPerDay. widthPerDay is the width of each day in the chart.
// later we can add more views here. quarterly, etc.
const widthPerDayOptions = {"weekly": 64, "monthly": 64 / 6, "overview": 0}

let allowedInteractions = true

function GanttChart() {
    const classes = useStyle();

    const schedulingPlaning = useSelector(state => state.schedulingPlaning.schedulingPlaning);

    const {currentSettings} = schedulingPlaning ? schedulingPlaning : {}

    const dispatch = useDispatch()
    const {projectId} = useParams()

    const [date, setDate] = useState(null)
    const [planingType, setPlaningType] = useState(null)
    const [phases, setPhases] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogItem, setDialogItem] = useState(null);
    const [initial, setInitial] = useState(true);
    const [chartView, setChartView] = useState("weekly")
    const workingDays = currentSettings ? translateWeekDays(currentSettings.selectedDays) : []
    const [widthPerDay, setWidthPerDay] = useState(widthPerDayOptions[chartView])

    useEffect(() => {
        if ((!schedulingPlaning || !schedulingPlaning.currentSettings)) {
            dispatch(getSchedulingPlaning({projectId: projectId}))
            return
        }

        if (phases.length !== 0) {
            return
        }

        setUpPhases()
        //eslint-disable-next-line
    }, [dispatch, projectId, schedulingPlaning])

    function setUpPhases() {
        const schedulingSettings = schedulingPlaning.currentSettings

        if (!schedulingSettings) {
            return
        }

        const phasesSetUp = JSON.parse(JSON.stringify(schedulingSettings.phases))

        addIsCollapsedAttribute(phasesSetUp)

        setPhases(phasesSetUp)
        setDate(schedulingPlaning.date)
        setPlaningType(schedulingPlaning.type)
    }

    function addIsCollapsedAttribute(elements) {
        elements.forEach(element => {
            element.isCollapsed = element.showSubTasks ? true : undefined

            setStartEndDate(element)

            if (element.subTasks) {
                addIsCollapsedAttribute(element.subTasks)
            }
        })
    }

    useEffect(() => {
        if (schedulingPlaning.type === planingType &&
            JSON.stringify(schedulingPlaning.date) === JSON.stringify(date) &&
            !schedulingPlaning.reset) {
            return
        }

        setUpPhases()
        //eslint-disable-next-line
    }, [schedulingPlaning])

    useEffect(() => {
        makeChart(phases, workingDays)
        //eslint-disable-next-line
    }, [phases, workingDays])

    function setStartEndDate(element) {
        element.start = getJSDate(element.startDate)
        element.end = getJSDate(element.endDate)
    }

    let projectStartDate, chartStart, chartEnd, duration;
    if (schedulingPlaning && schedulingPlaning.currentSettings) {
        const phases = schedulingPlaning.currentSettings.phases
        const startDates = phases.map(d => new Date(d.startDate.year + "-" + d.startDate.month + "-" + d.startDate.day))
        const endDates = phases.map(d => new Date(d.endDate.year + "-" + d.endDate.month + "-" + d.endDate.day))
        const projectStartDate = d3.min(startDates)
        const projectEndDate = d3.max(endDates)

        duration = Math.ceil(DateTime.fromJSDate(projectEndDate).diff(DateTime.fromJSDate(projectStartDate)).as('days'))

        chartStart = adjustMidnight(DateTime.fromJSDate(projectStartDate).minus({days: offsetDaysStart}).toJSDate())
        chartEnd = adjustMidnight(DateTime.fromJSDate(projectStartDate).plus({days: duration + offsetDaysEnd}).toJSDate())
    } else {
        projectStartDate = new Date("2023-11-02");
        duration = 10
        chartStart = DateTime.fromJSDate(projectStartDate).minus({days: offsetDaysStart}).toJSDate()
        chartEnd = DateTime.fromJSDate(projectStartDate).plus({days: duration + offsetDaysEnd}).toJSDate()
    }

    const chartContainerRef = useRef(null);
    const timelineRef = useRef(null);
    const sidebarRef = useRef(null);
    const scrollbarTopRef = useRef(null);
    const scrollbarTopContentRef = useRef(null);

    function toggleCollapse(item, altKey) {
        if (!item || !item.showSubTasks) {
            return
        }

        if (altKey) {
            toggleCollapseAll(item)
        } else {
            item.isCollapsed = !item.isCollapsed
        }

        updatePhases(phases, false)
    }

    function toggleCollapseAll(item) {
        const isCollapsed = !item.isCollapsed

        const type = item.type

        setCollapseByType(phases, type, isCollapsed)

        function setCollapseByType(elements, type, isCollapsed) {
            elements.forEach(element => {
                if (element.type === type && element.showSubTasks) {
                    element.isCollapsed = isCollapsed
                }

                setCollapseByType(element.subTasks, type, isCollapsed)
            })

        }
    }

    function updatePhases(phases, backendUpdate = true) {
        setPhases(phases)
        setRerender(!rerender)

        if (!backendUpdate) {
            return
        }

        setBackendDateByElements(phases)

        function setBackendDateByElements(elements) {
            elements.forEach(element => {
                getBackendDateByElement(element)

                if (element.subTasks) {
                    setBackendDateByElements(element.subTasks)
                }
            })
        }


        const schedulingPlaning = {
            schedulingSettings: {
                phases: phases
            }
        }

        dispatch(updateSchedulingPlaning({projectId: projectId, schedulingPlaning: schedulingPlaning}))
    }

    function getBackendDateByElement(element) {
        element.startDate = getBackendDate(element.start)
        element.endDate = getBackendDate(element.end)
    }

    function makeChart(data, workingDays) {
        chartContainerRef.current?.addEventListener("scroll", (event) => {
            timeLineElement.attr('transform', `translate(${-event.target.scrollLeft}, 0)`)

            sidebarRef.current?.scrollTo(0, event.target.scrollTop)
        })


        let items = getItems(data)

        function getItems(elements, items = []) {
            if (!elements || !elements.length) {
                return items
            }

            elements.forEach(element => {
                items.push(element)
                if (!element.isCollapsed && element.showSubTasks) {
                    return getItems(element?.subTasks, items)
                }
            })

            return items
        }

        const svgElement = d3.select("#scheduling-chart");
        const timeLineElement = d3.select("#time-line");

        if (initial) {
            chartContainerRef.current?.scrollTo(widthPerDay * (offsetDaysStart - 1), 0)
            setInitial(false)
        }

        // Define the margin and dimensions of the chart
        const margin = {top: 0, right: 0, bottom: 0, left: 0};

        const totalTasks = items.length
        const totalHeight = totalTasks * (taskBoxHeight + taskBoxMargin)

        const totalDays = duration + offsetDaysStart + offsetDaysEnd
        const totalWidth = totalDays * widthPerDay

        // Set the SVG element's width and height attributes
        svgElement
            .attr("width", totalWidth + margin.left + margin.right)
            .attr("height", totalHeight + margin.top + margin.bottom)
            .attr('display', 'block')

        timeLineElement
            .attr("width", totalWidth + margin.left + margin.right)
            .attr("height", 145)
            .attr('fill', 'white')

        if (scrollbarTopContentRef.current) {
            scrollbarTopContentRef.current.style.width = totalWidth + 'px'
        }

        // Create a chart within the SVG with the appropriate dimensions
        svgElement.selectAll("g").remove()
        timeLineElement.selectAll('g').remove()

        const svg = svgElement.append("g")

        const svgTimeline = timeLineElement.append('g')
        svgTimeline.insert("rect", ":first-child")
            .attr("class", "timeline-background")
            .attr('width', totalWidth)
            .attr('height', 145)
            .style('fill', 'white')
            .style('stroke', '#cccccc')

        // Let's draw
        // Define the scales for the time axis
        let getXScale = setupXScale()
        let getYScale = setupYScale();

        //define zoom behavior
        // const zoom = d3.zoom()
        //         .scaleExtent([0.1, 1.5])
        //         .on("zoom", zoomed);
        //
        // svgElement.call(zoom);
        //
        // function zoomed(event) {
        //
        //     let transform = event.transform
        //     // mapping the value of k in to range of 8 to 64
        //     // we change the width of the task box based on the zoom level
        //     const scale = d3.scaleLinear()
        //         .domain([0.1, 1.5])
        //         .range([8, 64])
        //
        //     widthPerDay = scale(transform.k)
        //     scrollToTask(phases[0])
        //     makeChart(phases, workingDays)
        //
        //     if (transform.k < 0.6) {
        //
        //         // remove the xAxisDay ticks
        //         svg.selectAll('.timeline-day').remove()
        //
        //         // remove disabled working days
        //         svg.selectAll('.column').remove()
        //
        //     }
        // }

        let showDayTimeline = false
        if (initial) {
            chartContainerRef.current?.scrollTo(widthPerDay * (offsetDaysStart - 1), 0)
            setInitial(false)
        }
        if (chartView === "weekly") {
            showDayTimeline = true
        } else if (chartView === "overview") {
            svg.selectAll('.timeline-day').remove()
            svg.selectAll('.column').remove()
        }
        if (showDayTimeline) {
            drawDayTimeline()
            drawDisabledWorkingDays();
        }


        drawMonthTimeLine()
        drawYearTimeLine()

        drawMonthLines()
        drawCurrentDate()
        drawRows(items);
        drawTasks(items);

        drawStartDate()
        drawEndDate()

        function setupYScale() {
            // Define the scales for the tasks
            return d3.scaleBand()
                .domain(items.map(task => getTaskId(task)))
                .range([0, items.length * (taskBoxHeight + taskBoxMargin)])
        }

        function setupXScale() {
            return d3.scaleTime()
                .domain([chartStart, chartEnd])
                .range([0, totalWidth])
        }

        function drawDayTimeline() {
            const xScaleDay = d3.scaleTime()
                .domain([chartStart, chartEnd])
                .range([0, totalWidth]);

            const xAxisDay = d3.axisTop(xScaleDay)
                .ticks(d3.timeDay.every(1))

            svgTimeline.selectAll('.timeline-day').remove()

            const timeline = svgTimeline.append("g")
                .attr("class", "timeline-day")
                .attr('transform', 'translate(0, 146)')
                .call(xAxisDay)

            timeline.selectAll('.domain')
                .attr('d', 'm0,-1.5 l' + totalWidth + ',0')
                .attr('stroke', '#dedede')
                .attr('stroke-width', '1.75px')

            timeline.selectAll('.tick line')
                .attr('display', 'none')

            timeline.selectAll('.tick text')
                .text(data => data.getDate())
                .attr("x", widthPerDay / 2)
                .style("user-select", "none")
                .style("font-size", "14px")
                .style("font-weight", day => workingDays.includes(getDayName(day)) ? '700' : '400')
                .style("fill", day => workingDays.includes(getDayName(day)) ? 'black' : '#cccccc')

            timeline.selectAll('.tick')
                .append('circle')
                .attr('cx', widthPerDay / 2 + 14)
                .attr('cy', -14)
                .attr('r', 3)
                .style("fill", day => workingDays.includes(getDayName(day)) ? '#FFD700' : '#cccccc')

            timeline.selectAll('.tick')
                .append('text')
                .attr('x', widthPerDay / 2)
                .attr('y', -26)
                .text(day => getDayName(day).slice(0, 2))
                .style("user-select", "none")
                .style("font-size", "10px")
                .style('fill', day => workingDays.includes(getDayName(day)) ? 'black' : '#cccccc')
        }

        function drawMonthTimeLine() {
            const xScaleMonth = d3.scaleTime()
                .domain([chartStart, chartEnd])
                .range([0, totalWidth]);

            const xAxisMonth = d3.axisTop(xScaleMonth)
                .ticks(d3.timeMonth.every(1))

            svgTimeline.selectAll('.timeline-month').remove()


            const timeline = svgTimeline.append("g")
                .attr("class", "timeline-month")
                .attr('transform', 'translate(0, 100)')
                .call(xAxisMonth);

            timeline.selectAll('.domain')
                .attr('display', 'none')

            timeline.selectAll('.tick line')
                .attr('stroke', '#cccccc')
                .attr('y1', -145)
                .attr('y2', 45)

            timeline.selectAll('.tick text')
                .text(date => {
                    const dateTime = DateTime.fromJSDate(date)
                    if (chartView === "overview" && duration > 365 && duration < 665) {
                        return dateTime.monthShort + ' ' + dateTime.year
                    } else if (chartView === "overview" && duration > 665) {
                        return dateTime.monthShort
                    } else {
                        return dateTime.monthLong + ' ' + dateTime.year
                    }
                })
                .style("user-select", "none")
                .style("font-size", "14px")
                .style('color', '#cccccc')
                .attr("x", day => DateTime.fromJSDate(day).daysInMonth * widthPerDay / 2)
                .attr("class", "timeline-month-label")
        }


        function drawYearTimeLine() {
            svgTimeline.selectAll('.timeline-year').remove()

            if (chartView !== "overview" || duration < 665) {
                return
            }

            const xScaleYear = d3.scaleTime()
                .domain([chartStart, chartEnd])
                .range([0, totalWidth]);

            const xAxisYear = d3.axisTop(xScaleYear)
                .ticks(d3.timeYear.every(1))

            const timeline = svgTimeline.append("g")
                .attr("class", "timeline-year")
                .attr('transform', 'translate(0, 120)')
                .call(xAxisYear);

            timeline.selectAll('.domain')
                .attr('display', 'none')

            timeline.selectAll('.tick line')
                .attr('stroke', '#ccc')
                .attr('stroke-width', '3px')
                .attr('y1', -145)
                .attr('y2', 45)

            timeline.selectAll('.tick text')
                .text(date => {
                    const dateTime = DateTime.fromJSDate(date)
                    return dateTime.year
                })
                .style("user-select", "none")
                .style("font-size", "14px")
                .style('color', '#cccccc')
                .attr("x", day => DateTime.fromJSDate(day).daysInYear * widthPerDay / 2)
                .attr("class", "timeline-month-label")
        }

        function drawStartDate() {
            const minTaskStart = d3.min(data, task => task.start)

            drawLineByDate(minTaskStart)
        }

        function drawEndDate() {
            // Creating Ticks for the start and end of the project
            const maxTaskStart = d3.max(data, task => task.end)

            // Draw line for maxTaskStart
            drawLineByDate(maxTaskStart)
        }

        function drawMonthLines() {
            const interval = Interval.fromDateTimes(
                DateTime.fromJSDate(chartStart),
                DateTime.fromJSDate(chartEnd)
            ).splitBy({day: 1}).map(d => d.start).filter(d => d.day === 1).map(d => d.toJSDate())

            interval.forEach(date => drawLineByDate(date))
        }

        function drawLineByDate(date) {
            svg.append("line")
                .attr("x1", getXScale(date))
                .attr("x2", getXScale(date))
                .attr("y1", -24)
                .attr("y2", totalHeight)
                .style("stroke", '#cccccc')
                .style("stroke-width", "2px")
                .style("opacity", 0.5);
        }

        function drawCurrentDate() {
            if (!showCurrentDay || showCurrentDay === "None")
                return

            let x = getXScale(Date.now())

            switch (showCurrentDay) {
                case "Before":
                    x += -widthPerDay / 2
                    break
                case "After":
                    x += widthPerDay / 2
                    break
                default:
                    break
            }

            const scrollTop = 0

            svg.selectAll('.current-date-line').remove()
            svg.selectAll('.current-date-triangle').remove()

            svg.append('line')
                .attr('class', 'current-date-line')
                .attr("x1", x)
                .attr("x2", x)
                .attr("y1", 0)
                .attr("y2", totalHeight)
                .style("stroke", "#ffc637")
                .style("stroke-width", "2px")

            svg.append('path')
                .attr('class', 'current-date-triangle')
                .attr('fill', '#ffc637')
                .attr('width', 16)
                .attr('height', 16)
                .attr('d', 'm' + (x - 6) + ',' + (scrollTop - 0.5) + ' l12,0 l-6,6 z')
                .attr('stroke', 'none')
        }

        function drawRows() {
            // remove if any
            svg.selectAll(".row").remove()
            // add row colors to horizontal grids
            svg.selectAll(".row")
                .data(items)
                .enter()
                .append("rect")
                .attr('id', task => 'row-' + getTaskId(task))
                .attr("x", 0)
                .attr("y", task => getYScale(getTaskId(task)))
                .attr("width", totalWidth)
                .attr("height", task => getYScale.bandwidth() + (task.workPackages ? .2 : 0))
                .on('mouseover', (event, task) => {
                    if (task.workPackages) {
                        return
                    }

                    const row = svg.selectAll('#row-' + getTaskId(task))
                    row.style('fill', '#F4F6F7')
                })
                .on('mouseout', (event, task) => {
                    if (task.workPackages) {
                        return
                    }

                    const row = svg.selectAll('#row-' + getTaskId(task))
                    row.style('fill', 'transparent')
                })
                .style('fill', task => task.workPackages ? "#F4F6F7" : "white")
                .style('stroke', task => task.workPackages ? "#ededed" : "transparent")
                .style('stroke-width', task => task.workPackages ? ".1rem" : "0")
                .attr("class", "row").lower()
        }

        function drawDisabledWorkingDays() {
            svg.selectAll('.column').remove()

            const dateArray = [];
            let currentDate = chartStart;
            while (currentDate <= chartEnd) {
                currentDate = getNextDay(currentDate)
                dateArray.push(currentDate);
            }

            svg
                .append('defs')
                .append('pattern')
                .attr('id', 'diagonalHatch')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 8)
                .attr('height', 8)
                .append('path')
                .attr('d', 'M-4,4 l4,-4 M0,8 l8,-8')
                .attr('stroke', '#EDEDED')
                .attr('stroke-width', 1)

            svg.selectAll(".column")
                .data(dateArray)
                .enter()
                .append("rect")
                .attr("x", (data, i) => ((i + 1) * widthPerDay))
                .attr("y", 0)
                .attr("width", widthPerDay)
                .attr("height", totalHeight)
                .attr("stroke", "#cccccc")
                .attr("stroke-width", 0.05)
                .attr('pointer-events', 'none')
                .style('fill', day => workingDays.includes(getDayName(day))
                    ? 'transparent'
                    : 'url(#diagonalHatch)')
        }

        function drawToolTip(task) {
            if (task.dragged || task.expand) {
                return
            }

            const x = getXScale(task.end) + 10
            const y = getYScale(getTaskId(task)) + getYScale.bandwidth() / 2

            let tooltip = svg.append("rect")
                .attr("class", "tooltip")
                .attr("id", "tooltip")
                .attr("x", x) // Adjust the position as needed
                .attr("y", y - 10) // Adjust the position as needed
                .attr("width", 0) // Adjust the width as needed
                .attr("height", 20) // Adjust the height as needed

                .style("fill", "#d9d9d9")
                .style("stroke-width", 1)
                .style("stroke", "#d9d9d9")
                .style('pointer-events', 'none')

            // Append text to the right side of the box
            let text = svg.append("text")
                .attr("class", "tooltip-label")
                .attr("id", "tooltip-text")
                .attr("dy", "0.35em")
                .style("user-select", "none")
                .style("pointer-events", "none")
                .attr("x", x) // Adjust the position as needed
                .attr("y", y)
                .style("font-size", "11px")
                .style("fill", "#000")

            text.append("tspan")
                .text(task.start.toLocaleDateString('en-GB'))
                .attr("dx", "3")

            const durationInDays = getExactAmountOfWorkingDay(task.start, task.end, workingDays)
                .toFixed(2)
                .replace(/[.,]00$/, "");

            text.append("tspan")
                .text(durationInDays + " days")
                .attr("dx", "10")

            text.append("tspan")
                .text(task.end.toLocaleDateString('en-GB'))
                .attr("dx", "17")

            tooltip.attr("width", text.node().getBBox().width + 10)

            const scrollRightSide = getXScale.invert(chartContainerRef.current.scrollLeft + chartContainerRef.current.offsetWidth)
            const endOfTheTask = getXScale.invert(getXScale(task.end) + 1)
            const rightSideSpace = getDiffDays(endOfTheTask, scrollRightSide)

            const scrollLeftSide = getXScale.invert(chartContainerRef.current.scrollLeft)
            const startOfTheTask = getXScale.invert(getXScale(task.start) + 1)
            const leftSideSpace = getDiffDays(scrollLeftSide, startOfTheTask)

            const tooltipLength = text.node().getBBox().width + 10
            const tooltipLengthInDays = (tooltipLength / widthPerDay) + 0.25

            if (tooltipLengthInDays < rightSideSpace) {
                tooltip.attr("x", getXScale(task.end) + 1)
                text.attr("x", getXScale(task.end) + 1)
            } else if (tooltipLengthInDays < leftSideSpace) {
                tooltip.attr("x", getXScale(task.start) - tooltipLength - 1)
                text.attr("x", getXScale(task.start) - tooltipLength - 1)
            } else {
                if (startOfTheTask > scrollLeftSide) {
                    tooltip.attr("x", getXScale(task.start) + widthPerDay + 1)
                    text.attr("x", getXScale(task.start) + widthPerDay + 1)
                } else {
                    tooltip.attr("x", chartContainerRef.current.scrollLeft + 1)
                    text.attr("x", chartContainerRef.current.scrollLeft + 1)
                }

            }

        }

        function removeToolTip() {
            svg.select(".tooltip").remove();
            svg.select(".tooltip-label").remove();
        }

        function drawTasks(tasks) {
            // Append rectangles and drag handles
            svg.selectAll(".task-box")
                .data(tasks)
                .enter()
                .each(drawTask)
        }

        function drawTask(task) {
            const taskId = getTaskId(task)

            const height = getHeightByType(task)
            const y = getYScale(taskId) + (getYScale.bandwidth() - height) / 2

            const fill = getColorByType(task)
            const stroke = getStrokeByType(task)
            const cursor = getCursorByType(task)

            const g = svg.append('g')
                .attr('class', 'task-group')
                .attr('id', 'task-group-' + taskId)

                .on('mouseover', () => onMouseOverTask(task))
                .on('mouseout', () => onMouseOutTask(task))

            g.append('rect')
                .attr('class', 'task')
                .attr('id', 'task-' + taskId)
                .attr('y', y)
                .attr('height', height)

                .style('fill', fill)
                .style('stroke', stroke)
                .style('cursor', cursor)

                .on('click', event => toggleCollapse(task, event.altKey || event.metaKey))

            if (task.type === 'Phase' || task.type === 'WorkPackage' || task.isCustom) {
                const drag = d3.drag()
                    .on("start", event => handleDragStart(event, task))
                    .on("drag", event => handleDrag(event, task))
                    .on("end", event => handleDragEnd(event, task));

                const expand = d3.drag()
                    .on("start", event => handleExpandStart(event, task))
                    .on("drag", event => handleExpand(event, task))
                    .on("end", event => handleExpandEnd(event, task))

                const handleFill = task.type === 'Phase' ? '#000' : '#fff'
                g.append('circle')
                    .attr('class', 'task-handle')
                    .attr('id', dragHandle + taskId)
                    .attr('cy', y + height / 2)
                    .attr('r', 5)

                    .call(drag)

                    .style('fill', handleFill)
                    .style('opacity', 0)
                    .style('cursor', 'pointer')

                if (task.type === 'WorkPackage' || task.isCustom) {
                    g.append('circle')
                        .attr('class', 'task-handle')
                        .attr('id', expandHandle + taskId)
                        .attr('cy', y + height / 2)
                        .attr('r', 5)

                        .call(expand)

                        .style('fill', handleFill)
                        .style('opacity', 0)
                        .style('cursor', 'pointer')
                }

                g.append('rect')
                    .attr('class', 'preview')
                    .attr('id', 'preview-' + taskId)
                    .attr('y', y)
                    .attr('height', height)
                    .attr('pointer-events', 'none')

                    .style('fill', 'transparent')
            }

            updateTaskPosition(task)
        }

        function updateTaskPosition(task) {
            const taskId = getTaskId(task)

            const startX = getXScale(task.start)
            const endX = getXScale(task.end)

            const width = endX - startX

            svg.select("#task-" + taskId)
                .attr("x", startX)
                .attr("width", width);

            const divider = task.duration > 12 ? 2 : 4

            svg.select("#" + dragHandle + taskId)
                .attr("cx", startX + widthPerDay / divider)

            svg.select("#" + expandHandle + taskId)
                .attr("cx", startX + width)
        }

        function updatePreviewDrag(task) {
            if (!task.previewStart) {
                return
            }


            const taskId = getTaskId(task)

            const preview = svg.selectAll("#preview-" + taskId)

            const previewStartX = getXScale(task.previewStart)
            const endX = getXScale(task.end)
            const previewWidth = endX - previewStartX

            preview
                .attr("x", previewStartX)
                .attr("width", previewWidth)
                .attr('stroke', task.dragged ? '#ccc' : 'transparent')
                .attr('stroke-dasharray', '5,5')
        }

        function onMouseOverTask(task) {
            if (task.dragged || task.expand || !allowedInteractions) {
                return
            }

            drawToolTip(task)
            setHandleOpacity(task, 1)
        }

        function onMouseOutTask(task) {
            if (task.dragged || task.expand) {
                return
            }

            removeToolTip()
            setHandleOpacity(task, 0)
        }

        function setHandleOpacity(task, opacity) {
            const taskId = getTaskId(task)

            svg.selectAll('#' + dragHandle + taskId).style('opacity', opacity)
            svg.selectAll('#' + expandHandle + taskId).style('opacity', opacity)
        }

        function handleDragStart(event, task) {
            if (!allowedInteractions) {
                return
            }

            onMouseOutTask(task)
            task.dragged = true
            task.dragDuration = getExactAmountOfWorkingDay(task.start, task.end, workingDays)

            task.dragStartDate = task.start
        }

        function handleDrag(event, task) {
            if (!allowedInteractions) {
                return
            }

            task.dragged = true

            const currentTime = DateTime.fromJSDate(getXScale.invert(event.x)).minus({hours: 12}).toJSDate()

            task.previewStart = getNextWorkingDay(adjustToHalfDay2(currentTime), workingDays)
            task.start = currentTime

            const end = getEndDateByDurationAndWorkingDaysHalfDaySteps(task.previewStart, task.dragDuration, workingDays)

            if (task.previewStart.getHours() === 12
                && task.dragDuration % 1 > 0) {
                task.end = adjustToHalfDay2(end)
            } else {
                task.end = end
            }

            updateTaskPosition(task)
            updatePreviewDrag(task)
        }

        function handleDragEnd(event, task) {
            if (!task.previewStart || !allowedInteractions) {
                return
            }

            allowedInteractions = false

            task.dragged = false

            task.start = task.previewStart

            updateTaskPosition(task)

            updateChildrenTasks(task)
            updateParentTask(task)

            updatePhases(phases)

            setTimeout(() => {
                allowedInteractions = true
            }, 2000)
        }

        function updateChildrenTasks(parentTask) {
            const diffInWorkingDays = getDiffDaysInWorkingDays2(parentTask.start, parentTask.dragStartDate, workingDays)

            if (Math.abs(diffInWorkingDays) < .1) {
                return
            }

            parentTask.subTasks.forEach(subTask => updateChildTask(subTask, diffInWorkingDays))
        }

        function updateChildTask(childTask, diffInDays) {
            if (diffInDays < 0) {
                childTask.start = getPreviousWorkingDayByDurationHalfDay(childTask.start, Math.abs(diffInDays), workingDays)
                childTask.end = getPreviousWorkingDayByDurationHalfDay(childTask.end, Math.abs(diffInDays), workingDays)
            } else {
                childTask.start = getNextWorkingDayByDurationHalfDay(childTask.start, diffInDays, workingDays)
                childTask.end = getNextWorkingDayByDurationHalfDay(childTask.end, diffInDays, workingDays)
            }

            childTask.subTasks.forEach(subTask => updateChildTask(subTask, diffInDays))

            updateTaskPosition(childTask)
        }

        function updateParentTask(task) {
            const parentTask = getParentTask(task, phases)

            if (!parentTask) {
                return
            }

            const children = parentTask.subTasks

            parentTask.start = new Date(d3.min(children, d => d.start))
            parentTask.end = new Date(d3.max(children, d => d.end));

            updateTaskPosition(parentTask)
        }

        function handleExpandStart(event, task) {
            if (!allowedInteractions) {
                return
            }

            task.duration = getExactAmountOfWorkingDay(task.start, task.end, workingDays)
            onMouseOutTask(task)
            task.expand = true
        }

        function handleExpand(event, task) {
            if (!allowedInteractions) {
                return
            }

            task.expand = true

            task.end = getXScale.invert(event.x)

            if (task.end - task.start < 0) {
                task.end = task.start
            }

            updateTaskPosition(task)
            updatePreviewExpand(task)
        }

        function handleExpandEnd(event, task) {
            if (!allowedInteractions) {
                return
            }

            allowedInteractions = false

            task.expand = false
            const end = getXScale.invert(event.x)
            task.end = getPreviousWorkingDay(adjustToHalfDay(end), workingDays)
            if (task.end - task.start < 0) {
                task.end = adjustLastMinuteOfDay(getNextWorkingDay(task.start, workingDays, 1))
            }

            const endDuration = getExactAmountOfWorkingDay(task.start, task.end, workingDays)

            console.log(endDuration, task.duration)

            const factor = endDuration / task.duration

            task.customTeamSize = task.customTeamSize / factor
            task.duration = endDuration

            if (schedulingPlaning.type === 'START') {
                updateTasksExpandStart(task.subTasks, factor, task.start, workingDays)
            } else {
                updateTasksExpandEnd(task.subTasks, factor, task.end, workingDays)
            }

            updateParentTask(task)
            updateTaskPosition(task)
            updatePreviewExpand(task)

            updatePhases(phases)

            setTimeout(() => {
                allowedInteractions = true
            }, 2000)
        }

        function updatePreviewExpand(task) {
            const taskId = getTaskId(task)

            const startX = getXScale(task.start)
            const end = getPreviousWorkingDay(adjustToHalfDay(task.end), workingDays)
            const endX = getXScale(end)
            const width = endX - startX

            const preview = svg.selectAll("#preview-" + taskId)

            preview
                .attr("x", startX)
                .attr("width", width)
                .attr('stroke', '#ccc')
                .attr('stroke-dasharray', '5,5')

            if (!task.expand) {
                preview.attr('stroke', 'transparent')
            }
        }

        function getHeightByType(task) {
            const height = getYScale.bandwidth() - taskBoxMargin

            switch (task.type) {
                case 'Phase':
                case 'WorkPackage':
                    return height
                case 'Floor':
                case 'Elevation':
                default:
                    return height * 0.8
            }
        }

        function getColorByType(task) {
            if (task.isCustom) {
                return '#ffd700'
            }

            switch (task.type) {
                case 'Phase':
                    return 'transparent'
                case 'WorkPackage':
                    return '#58585A'
                case 'Floor':
                    return "#767676"
                case 'Elevation':
                    return "#CCCCCC"
                default:
                    return "#CCCCCC"
            }
        }

        function getStrokeByType(task) {
            if (task.type === 'Phase') {
                return '#000'
            }

            return 'transparent'
        }

        function getCursorByType(task) {
            return task.showSubTasks ? 'pointer' : 'default'
        }
    }

    function getTaskId(task) {
        return 'id-' + (task.id ? task.id.toString() : task.toString())
    }

    function getParentTask(childTask, elements) {
        if (!elements.length) {
            return null
        }

        const parent = elements.find(element =>
            element.subTasks.includes(childTask)
        )

        if (parent) {
            return parent
        }

        return getParentTask(childTask, elements.flatMap(element => element.subTasks))
    }

///region Sidebar

    function onClickIcon(item) {
        if (item.type === 'Phase') {
            addCustomTask(item)

            updatePhases(phases)
        } else if (item.isCustom) {
            onDeleteItem(item)
        } else {
            setDialogItem(item)
            setOpenDialog(true)
        }
    }

    function addCustomTask(phase) {
        const startDate = adjustMidnight(getNextWorkingDayByDuration(d3.max(phase.subTasks, subTask => subTask.end), 1, workingDays))

        const customTask = {
            id: v4(),
            name: 'Custom Position',
            type: phase.productStrategy === 'Serial' ? 'WorkPackage' : 'Floor',
            subTasks: [],
            start: startDate,
            end: adjustLastMinuteOfDay(getNextWorkingDayByDuration(startDate, 4, workingDays)),
            isCustom: true
        }

        customTask.duration = getExactAmountOfWorkingDay(customTask.start, customTask.end, workingDays)

        phase.subTasks.push(customTask)
        phase.end = d3.max(phase.subTasks, subTask => subTask.end)
    }

    function onRenameItem(item, newName) {
        item.name = newName
        updatePhases(phases)
    }

    function onAddItem(item, after = 1) {
        const parentTask = getParentTask(item, phases)

        const index = parentTask.subTasks.findIndex(subTask => subTask === item)

        let startDate, endDate

        if (after === 1) {
            endDate = getEndDateByDurationAndWorkingDays2(item.end, 4, workingDays)
            startDate = getStartDateByDurationAndWorkingDays2(endDate, 4, workingDays)
        } else {
            startDate = getStartDateByDurationAndWorkingDays2(item.start, 4, workingDays)
            endDate = getEndDateByDurationAndWorkingDays2(startDate, 4, workingDays)
        }

        const customTask = {
            id: v4(),
            name: 'Custom Position',
            type: 'WorkPackage',
            subTasks: [],
            start: startDate,
            end: endDate,
            isCustom: true
        }

        customTask.duration = getExactAmountOfWorkingDay(customTask.start, customTask.end, workingDays)

        parentTask.subTasks.splice(index + after, 0, customTask)

        parentTask.start = d3.min(parentTask.subTasks, subTask => subTask.start)
        parentTask.end = d3.max(parentTask.subTasks, subTask => subTask.end)

        updatePhases(phases)
    }

    function onDeleteItem(item) {
        const parentTask = getParentTask(item, phases)

        if (!parentTask) {
            return
        }

        parentTask.subTasks = parentTask.subTasks.filter(subTask => subTask !== item)

        parentTask.start = d3.min(parentTask.subTasks, subTask => subTask.start)
        parentTask.end = d3.max(parentTask.subTasks, subTask => subTask.end)

        updatePhases(phases)
    }

    function getSidebarContent() {
        phases.forEach(phase => {
            phase.icon = !phase.isCollapsed ? PlusIcon : null
            phase.subTasks.forEach((subTask, index) => {
                const isCustom = subTask.isCustom
                const isWorkPackage = subTask.type === 'WorkPackage'

                if (isWorkPackage) {
                    subTask.secondName = !isCustom ? 'Team: ' + Number(subTask.customTeamSize)
                        .toFixed(2)
                        .replace(/[.,]00$/, "") : null
                }

                subTask.icon = isCustom ? DeleteIcon : isWorkPackage ? SettingsIcon : null
                subTask.rename = isCustom
                subTask.delete = isCustom
                subTask.addIconBefore = index === 0
                subTask.addIconAfter = true

                subTask.subTasks.forEach(subTask => {
                    subTask.secondName = subTask.amountOfPanels + ' Panels'
                    subTask.subTasks.forEach(subTask => {
                        subTask.secondName = subTask.amountOfPanels + ' Panels'
                    })
                })

                if (subTask.type === 'Floor') {
                    subTask.secondName = subTask.amountOfPanels + ' Panels'
                    subTask.subTasks.forEach(subTask => {
                        if (subTask.type === 'Elevation') {
                            subTask.secondName = subTask.amountOfPanels + ' Panels'
                        }
                    })
                }
            })
        })

        return phases.map((phase, index) => {
            const childAttributes = ['subTasks', 'subTasks', 'subTasks']

            return <Fragment key={index}>
                <StoSidebarItem key={phase.id}
                                item={phase}
                                childAttributes={childAttributes}
                                itemStyles={[{background: '#F4F6F7', borderBottom: '1px solid #ededed'}]}
                                textStyles={[{fontWeight: '600', textTransform: "uppercase"}]}
                                toggleCollapse={toggleCollapse}
                                onClick={(item, event) => event.shiftKey ? toggleShowSubTasks(item) : scrollToTask(item)}
                                onClickIcon={onClickIcon}
                                onRename={onRenameItem}
                                onDelete={onDeleteItem}
                                onAddItemBefore={(item) => onAddItem(item, 0)}
                                onAddItemAfter={onAddItem}/>
            </Fragment>
        })
    }

    function scrollToTask(item) {
        const y = chartContainerRef.current?.scrollTop

        const days = Math.ceil(DateTime.fromJSDate(item.start).diff(DateTime.fromJSDate(chartStart), 'days').days) - 1
        const x = widthPerDay * days

        chartContainerRef.current?.scrollTo(x, y)
    }

    function toggleShowSubTasks(item) {
        item.showSubTasks = !item.showSubTasks
        item.isCollapsed = item.showSubTasks ? false : undefined
        updatePhases(phases)
    }

///endregion

    function updateItem(item) {
        phases.forEach(phase => {
            phase.subTasks = phase.subTasks.map(workPackage => {
                if (workPackage.id === item.id) {
                    phase.start = new Date(d3.min(phase.subTasks, d => d.start))
                    phase.end = new Date(d3.max(phase.subTasks, d => d.end));
                    return item
                }

                return workPackage
            })
        })

        updatePhases(phases, true)
    }

    function onUpdateGranularity(option) {

        // chartContainerRef.current?.scrollTo(widthPerDay * (offsetDaysStart - 1), 0)
        setInitial(true)
        if (option === "overview") {
            const maxTaskEnd = getNextDay(d3.max(phases, task => task.end))
            const minTaskStart = getPreviousDay(d3.min(phases, task => task.start))
            const daysDiff = getDiffDays(minTaskStart, maxTaskEnd)
            setWidthPerDay(chartContainerRef.current.offsetWidth / (daysDiff + offsetDaysStart + offsetDaysEnd - 2))
        } else if (option === "monthly") {
            setWidthPerDay(widthPerDayOptions[option.toLowerCase()])
        } else if (option === "weekly") {
            setWidthPerDay(widthPerDayOptions[option.toLowerCase()])
        } else {
            return
        }

        setChartView(option);
        setRerender(!rerender)
    }

    return (
        <Fragment>
            <div className={classes.container} ref={chartContainerRef} onScroll={event =>
                scrollbarTopRef.current?.scrollTo(event.target.scrollLeft, 0)
            }>
                <div style={{width: 'calc(100% - 30.1rem)', clipPath: 'inset(0 0 0 0)', position: 'fixed'}}>
                    <svg id={'time-line'} style={{overflowX: 'scroll'}} ref={timelineRef}/>
                </div>
                <svg id={'scheduling-chart'} style={{marginTop: '14.5rem'}}/>
                <div className={classes.sidebar} ref={sidebarRef} onScroll={event => {
                    const x = chartContainerRef.current.scrollLeft
                    chartContainerRef.current?.scrollTo(x, event.target.scrollTop)
                }}>
                    {getSidebarContent()}
                </div>
                {chartView !== "overview" &&
                    <div className={classes.scrollbarTop}
                         ref={scrollbarTopRef}
                         onScroll={event => {
                             const y = chartContainerRef.current.scrollTop
                             chartContainerRef.current?.scrollTo(event.target.scrollLeft, y)
                         }}>
                        <div className={classes.scrollbarTopContent}
                             ref={scrollbarTopContentRef}/>
                    </div>
                }
                <EditSchedulingPlaningItemDialog setOpen={setOpenDialog}
                                                 open={openDialog}
                                                 workPackage={dialogItem}
                                                 updateItem={updateItem}
                                                 workingDays={workingDays}
                                                 workHoursPerDay={workingHoursPerDay}
                                                 schedulingPlaningType={schedulingPlaning.type}/>

            </div>
            <div className={classes.switchButton}>
                <StoSwitchButton options={Object.keys(widthPerDayOptions)}
                                 activeOption={chartView}
                                 style={{width: "-webkit-fill-available"}}
                                 textStyle={{textTransform: "capitalize"}}
                                 onChange={onUpdateGranularity}
                />

            </div>
        </Fragment>
    )
}

export default GanttChart;
