import axios from "axios";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {setFloorPlanSettings} from "../unity/react-unity-adapter";

export const getFloorPlanSettings = createAsyncThunk('floorPlanSettings/get', async ({projectId}) => {
    try {
        console.log('Get floor plan settings: ', projectId)
        return await axios.get(`/api/projects/${projectId}/floorPlanSettings`)
    } catch (err) {
        return err.message
    }
})

export const updateFloorPlanSettings = createAsyncThunk('floorPlanSettings/update', async ({projectId, floorPlanSettings}) => {
    try {
        console.log('Update floor plan settings: ', floorPlanSettings)
        return await axios.put(`/api/projects/${projectId}/floorPlanSettings`, floorPlanSettings)
    } catch (err) {
        return err.message
    }
})

const floorPlanSettingsSlice = createSlice({
    name: 'floorPlanSettings',
    initialState: {
        floorPlanSettings: {
            floorHeight: 0,
            structuralSlabLevelThickness: 0,
            finishedFloorLevelThickness: 0,
            groundStructuralSlabLevelThickness: 0,
            groundFinishedFloorLevelThickness: 0,
            terrainOffset: 0,
            parapetHeight: 0,
            floorMeasurementVariant: 0,
        },
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getFloorPlanSettings.pending, (state) => {
                state.loading = true
            })
            .addCase(getFloorPlanSettings.fulfilled, (state, {payload}) => {
                state.floorPlanSettings = payload.data
                state.loading = false
                setFloorPlanSettings(state.floorPlanSettings)
            })
            .addCase(getFloorPlanSettings.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateFloorPlanSettings.pending, (state) => {
                state.loading = true
            })
            .addCase(updateFloorPlanSettings.fulfilled, (state, {payload}) => {
                state.floorPlanSettings = payload.data
                state.loading = false
                //setClientUnity(state.client)
            })
            .addCase(updateFloorPlanSettings.rejected, (state) => {
                state.loading = false
            })
    }
})

export default floorPlanSettingsSlice.reducer