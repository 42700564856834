import {makeStyles} from "@mui/styles";
import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import StoSwitchButton from "../utils/components/StoSwitchButton";
import StoTimePicker from "../utils/components/StoTimePicker";
import {resetSchedulingPlaningSettings, updateSchedulingPlaning} from "./planing/schedulingPlaningSlice";
import ResetIcon from "../utils/icons/reset.svg";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const useStyle = makeStyles(theme => ({
    sidebar: {
        height: "100%",
        width: "30rem",
        borderRight: "solid .1rem #CCCCCC",
    },
    header: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignContent: "stretch",
        height: "14.4rem",
        paddingLeft: ".8rem",
        paddingRight: ".8rem",
        borderBottom: "solid .1rem #CCCCCC",
    },
    headerSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "4.8rem",
    },
    headerTitle: {
        display: "flex",
        alignItems: "center",
        height: "4.8rem"
    },
    resetButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: ".8rem",
        height: "3.2rem",
        width: "10rem",
        backgroundColor: theme.palette.primary.main,
        borderRadius: ".2rem",
        border: ".1rem solid #FFC637",
        cursor: "pointer",
    },
    switchButton: {
        display: "flex",
        alignItems: "center",
        height: "4.8rem",
    },
    date: {
        display: "flex",
        flexDirection: "row",
        height: "4.8rem",
        alignItems: "center",
        justifyContent: "space-between",
    },
    datePicker: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        gap: ".8rem",
        width: "16.9rem",

    },
    iconWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "3.6rem",
        height: "3.6rem",
    },
    addItem: {
        display: "flex",
        alignItems: "center",
        height: "4.8rem",
        paddingLeft: "1.6rem",
        gap: ".8rem",
    },
    icon: {
        width: "2rem",
        height: "2rem",
    },
    content: {
        overflowY: "scroll",
        height: 'calc(100% - 14.4rem)',

        '&::-webkit-scrollbar': {
            display: 'none',
        },

        '&::after': {
            content: '""',
            display: 'block',
            height: '1.1rem',
        }
    }

}));

const startOrEndDate = {
    start: "START",
    end: "END"
}

const startOrEndDateLabel = [startOrEndDate.start, startOrEndDate.end]


function SchedulingSettingSidebar() { // addWorkPackage
    const classes = useStyle();

    const schedulingPlaningSlice = useSelector(state => state.schedulingPlaning);
    const {schedulingPlaning} = schedulingPlaningSlice

    const dispatch = useDispatch()
    const {projectId} = useParams()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(schedulingPlaning.reset && schedulingPlaningSlice.loading)
    }, [schedulingPlaning, schedulingPlaningSlice])


    function updateStartOrEndDate(option) {
        const schedulingPlaning = {
            type: option
        }

        dispatch(updateSchedulingPlaning({projectId: projectId, schedulingPlaning: schedulingPlaning}))
    }

    function updateDate(date) {
        const schedulingPlaning = {
            date: date
        }

        dispatch(updateSchedulingPlaning({projectId: projectId, schedulingPlaning: schedulingPlaning}))
    }

    function resetSchedulePlaningSettings() {
        dispatch(resetSchedulingPlaningSettings({projectId: projectId}))
    }

    const iconStyle = loading ? {
        animation: 'rotation 5s linear infinite',
        animationDirection: 'reverse',
    } : {}

    return (
        <div className={classes.sidebar}>
            <div className={classes.header}>
                <div className={classes.headerSection}>
                    <Typography className={classes.headerTitle}>
                        Schedule Planning
                    </Typography>
                    <div className={classes.resetButton} onClick={resetSchedulePlaningSettings}>
                        <Typography>Calculate</Typography>
                        <img className={classes.icon} src={ResetIcon} alt={'reset icon'} style={iconStyle}/>
                    </div>
                </div>
                <div className={classes.switchButton}>
                    <StoSwitchButton options={startOrEndDateLabel.map(option => option.toLowerCase())}
                                     activeOption={schedulingPlaning?.type?.toLowerCase()}
                                     style={{width: "-webkit-fill-available"}}
                                     textStyle={{textTransform: "capitalize"}}
                                     onChange={(option) => updateStartOrEndDate(option.toUpperCase())}/>
                </div>
                <div className={classes.date}>
                    <Typography
                        className={classes.headerTitle}>{schedulingPlaning?.type === startOrEndDate.start ? "Startdate" : "Enddate"}</Typography>
                    <StoTimePicker defaultValue={schedulingPlaning?.date} onChange={updateDate}/>
                </div>
            </div>
        </div>
    )
}

export default SchedulingSettingSidebar