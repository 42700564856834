import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@mui/styles";
import {TableCell, TableRow, Tooltip, Typography} from "@mui/material";
import StoIconButton from "../../utils/components/StoIconButton";
import RestoreIcon from "../../utils/icons/restore_from_trash_icon.svg"
import TrashIcon from "../../utils/icons/trash.svg"
import {useDispatch} from "react-redux";
import {
    deleteProject,
    fetchAllProjectsInBin,
    restoreProjectFromBin,
} from "../projectsSlice";
import StoDialog from "../../utils/components/StoDialog";
import StoPrimaryButton from "../../utils/components/StoPrimaryButton";



const useStyle = makeStyles(theme => ({
        tableRow: {
            '& .MuiTableRow-root': {
                height: "5rem",
            },
            '& .MuiTableCell-root': {
                padding: "0 16px",
                height: "5rem",
                width: "14rem",
            },
            '&:hover': {
                backgroundColor: `${theme.palette.neutral.primary0}!important`
            },
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.neutral.white,
            },
        },
        icons: {
            display: "flex"
        },
        divider: {
            margin: "0 14px!important"
        },
        dialogActions: {
            height: "8.4rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        },
        deleteProjectTitle: {
            textAlign: "center",
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        cell: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        namecell: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "inline-flex",
            verticalAlign: "middle"
        },
        renameIcon: {
            verticalAlign: "baseline",
            display: "inline-block",
            fontSize: "16px"

        }
    }))
;

function ProjectTableRow({project, ...props}) {
    const classes = useStyle(props.theme);
    const dispatch = useDispatch();
    const [showIcons, setShowIcons] = React.useState(false)
    const [currentProjectId, setCurrentProjectId] = React.useState(project.id)
    const [deleteOpen, setDeleteOpen] = React.useState(false)
    const [lastChanged, setLastChanged] = React.useState("")
    const [name, setName] = React.useState("")


    useEffect(() => {
        setName(project.name)
        const date = new Date(project.lastChanged)
        const now = new Date()
        const diff = now.getTime() - date.getTime()
        const dayDiff = Math.floor(diff / (1000 * 3600 * 24))
        if (dayDiff === 1) {
            setLastChanged(dayDiff + " Day ago")
        } else if (dayDiff === 0) {
            setLastChanged("today")
        } else if (dayDiff < 5)
            setLastChanged(dayDiff + " Days ago")
        else {
            setLastChanged(project.lastChanged)
        }
        //eslint-disable-next-line
    }, [])

    const openDeleteDialog = (event, project) => {
        event.stopPropagation()
        setCurrentProjectId(project.id)
        setDeleteOpen(true)
    }

    const closeDeleteDialog = (event) => {
        event.stopPropagation()
        setDeleteOpen(false)
    }

    const handleDelete = async (event) => {
        event.stopPropagation()
        await dispatch(deleteProject({projectId: currentProjectId}))
        closeDeleteDialog(event)
        dispatch(fetchAllProjectsInBin())
    }

    const checkNameLength = (name) => {
        if (name.length > 25) {
            return name.substring(0, 22) + "..."
        } else {
            return name
        }
    }

    const handleRestoreProject = async (event, project)=>{

        await dispatch(restoreProjectFromBin({projectId: project.id}))
        dispatch(fetchAllProjectsInBin())
    }
    const tableCells = [project.companyName, project.creationDate, lastChanged]   //[client, creator, today, today]

    return (
        <TableRow className={classes.tableRow} key={project.id} onMouseEnter={() => setShowIcons(true)}
                  onMouseLeave={() => setShowIcons(false)} onBlur={() => setShowIcons(false)}
                  >
            <TableCell>
                <Tooltip title={<h1>{name}</h1> } disableHoverListener={name.length === checkNameLength(name).length} placement={"bottom-start"} hidden={true}>
                    <Typography variant={"body1"}
                                className={classes.namecell}>{checkNameLength(name)}
                    </Typography>
                </Tooltip>
            </TableCell>
            {
                tableCells.map((cell, i) => {
                    return <TableCell className={classes.cell} key={i}>
                        <Typography variant={"subtitle1"}
                                    className={classes.cell}>{cell ? cell : "unnamed"}</Typography>
                    </TableCell>
                })
            }
            <TableCell align="right">
                {showIcons ?
                    <div className={classes.icons}>

                        <StoIconButton tooltip={"Restore"} icon={RestoreIcon}
                                       onClick={(event) => handleRestoreProject(event, project)}
                        />
                        <StoIconButton tooltip={"Delete Permanently"} icon={TrashIcon}
                                       onClick={(event) => openDeleteDialog(event, project)}/>




                        {/*<StoIconButton icon={EditIcon} onClick={editProject}/>
                        <Divider orientation="vertical" flexItem className={classes.divider}/>
                        <StoIconButton iconComponent={<BackIcon flip={true}/>}
                                       onClick={() => goToProject(project)}/>*/}

                    </div>
                    :
                    <div style={{width: "7.1rem"}}/>
                }
            </TableCell>
            <StoDialog open={deleteOpen} onClose={(event) => closeDeleteDialog(event)}
                       title={""}
                       maxWidth={"36rem"}
                       content={
                           <div>
                               <Typography variant={"h1"} className={classes.deleteProjectTitle}>
                                   {"Are you sure you want to delete \"" + project.name + "\"?"}</Typography>
                               <Typography variant={"subtitle1"} style={{paddingTop: "2rem"}}>
                                   {"All your designs will be lost. This can not be reversed."}</Typography>
                           </div>
                       }
                       actions={<div className={classes.dialogActions}>

                           <StoPrimaryButton onClick={(event) => handleDelete(event)} title={"Delete Project"}/>
                           <StoPrimaryButton onClick={(event) => closeDeleteDialog(event)} title={"Cancel"} secondary/>

                       </div>}/>

        </TableRow>
    );
}

ProjectTableRow.propTypes = {
    project: PropTypes.object.isRequired
};
ProjectTableRow.defaultPropTypes = {};
export default ProjectTableRow;