import React, {Fragment, useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import StoDialog from "../utils/components/StoDialog";
import StoPrimaryButton from "../utils/components/StoPrimaryButton";
import {Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setDeleteOutline} from "../unity/unitySlice";
import {deleteOutline} from "./floorSlice";
import {useParams} from "react-router-dom";
import {setDeleteOutlineInUnity} from "../unity/react-unity-adapter";

const useStyle = makeStyles(_ => ({
    root: {},
    actions: {
        height: "8.4rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }
}));

function DeleteOutlineDialog({
                                 ...props
                             }) {
    const classes = useStyle(props.theme);

    const dispatch = useDispatch()
    const {projectId} = useParams()

    const deleteOutlineData = useSelector(state => state.unity.deleteOutlineData)

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setIsOpen(deleteOutlineData?.outlineId !== undefined)
    }, [deleteOutlineData?.outlineId])

    const handleClose = () => {
        setIsOpen(false)
        dispatch(setDeleteOutline({}))
    }

    const handleSubmit = async () => {
        dispatch(deleteOutline({
            projectId: projectId,
            floorId: deleteOutlineData.floorId,
            outlineId: deleteOutlineData.outlineId
        }))

        setDeleteOutlineInUnity(deleteOutlineData.floorId, deleteOutlineData.outlineId)
        handleClose()
    }

    const getContent = () => {
        return (<div style={{paddingTop: '2.4rem', marginBottom: '-.6rem'}}>
                <Typography variant={"h6"}>
                    Do you really want to delete this outline?
                </Typography>
                <Typography variant={"h6"}>
                    All connected balconies will be also deleted.
                </Typography>
            </div>
        )
    }

    const getActions = () => {
        return (
            <div className={classes.actions}>
                <StoPrimaryButton title={"Delete"} onClick={handleSubmit}/>
                <StoPrimaryButton title={"Cancel"} onClick={handleClose} secondary/>
            </div>
        )
    }

    return (
        <Fragment>
            <StoDialog maxWidth={"36rem"} open={isOpen} onClose={handleClose} title={"Delete Outline"}
                       content={getContent()}
                       actions={getActions()}
            />
        </Fragment>);
}

DeleteOutlineDialog.defaultPropTypes = {};
export default DeleteOutlineDialog;