import {store} from "../store";
import {unitSystems} from "../unitSystem/unitSystemSlice";

export const CalculationTypes = {
    PANEL_PER_DAY: "PANEL_PER_DAY",
    AREA_PER_DAY: "AREA_PER_DAY",
}

export function getCalculationTypes() {
    return [CalculationTypes.PANEL_PER_DAY, CalculationTypes.AREA_PER_DAY]
}

export function translateCalculationType(calculationType) {
    const {unitSystem} = store.getState().unitSystem

    switch (calculationType) {
        case CalculationTypes.PANEL_PER_DAY:
            return "Panel"
        case CalculationTypes.AREA_PER_DAY:
            if (unitSystems.METRICAL === unitSystem) {
                return "Square Meter"
            } else {
                return "Square Feet"
            }
        default:
            return ""
    }
}

export function translateCalculationTypes(calculationTypes) {
    return calculationTypes.map(translateCalculationType)
}

export function getCalculationType(calculationType) {
    switch (calculationType) {
        case "Panel":
            return CalculationTypes.PANEL_PER_DAY
        case "Square Feet":
        case "Square Meter":
            return CalculationTypes.AREA_PER_DAY
        default:
            return ""
    }
}

export const CalculationUnitTime = {
    PER_HOUR: "PER_HOUR",
    PER_DAY: "PER_DAY",
}

export function getCalculationUnitTimes() {
    return [CalculationUnitTime.PER_HOUR, CalculationUnitTime.PER_DAY]
}

export function translateCalculationUnitTimes(calculationUnitTimes) {
    return calculationUnitTimes.map(translateCalculationUnitTime)
}

export function translateCalculationUnitTime(calculationUnitTime) {
    switch (calculationUnitTime) {
        case CalculationUnitTime.PER_HOUR:
            return "per hour"
        case CalculationUnitTime.PER_DAY:
            return "per day"
        default:
            return ""
    }
}

export function getCalculationUnitTime(calculationType) {
    switch (calculationType) {
        case "per hour":
            return CalculationUnitTime.PER_HOUR
        case "per day":
            return CalculationUnitTime.PER_DAY
        default:
            return ""
    }
}
