import React from 'react'
import './export.css'
import {ExportPricingItem} from './ExportPricingItem'


export const ExportPricingCategory = (props) => {

    const category = props.category
    const unitSystem = props.unitSystem
    const pricingLevel = props.pricingLevel

    return (
       <>
            {
                category.items.map(
                    function(item, i) {
                        return (
                        <ExportPricingItem item={item} unitSystem={unitSystem}  key={i} bold={false}  pricingLevel={pricingLevel}/>
                        )
                    }
                )
            }
           {
               category.summaries.map(
                   function(item, i) {
                       return (
                           <ExportPricingItem item={item} unitSystem={unitSystem} key={i} bold={true}  pricingLevel={pricingLevel}/>
                       )
                   }
               )
           }
           <tr><td>&nbsp;</td></tr>
       </>

    );
};