import axios from "axios";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setColorPalette} from '../unity/react-unity-adapter'

export const getColorPalette = createAsyncThunk('colorPalette/get', async () => {
    try {
        console.log("Get color palette")
        return await axios.get(`/api/colorPalette`)
    } catch (err) {
        return err.message
    }
})

const colorPaletteSlice = createSlice({
    name: 'colorPalette',
    initialState: {
        colors: [],
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getColorPalette.pending, (state) => {
                state.loading = true
            })
            .addCase(getColorPalette.fulfilled, (state, action) => {
                state.loading = false
                state.colors = action.payload.data
                setColorPalette(state.colors)
            })
            .addCase(getColorPalette.rejected, (state) => {
                state.loading = false
            })

    }
})

export default colorPaletteSlice.reducer