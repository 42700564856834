import {makeStyles} from "@mui/styles";
import {Typography} from "@mui/material";

const useStyles = makeStyles(_ => ({
    switchButton: {
        width:"23.5rem",
        height: "3.2rem",

        display: "flex",
        alignItems: "stretch",
        justifyContent: "center",

        background: "white",
        borderRadius: ".2rem",
        padding: ".2rem",
        gap: ".2rem",
        border: "solid .1rem #cccccc",
    },
    option:
        {
            cursor: "pointer",
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",

            background: "#ffd700",
            border: "solid .1rem #ffc637",
        }
}))


function StoSwitchButton({onChange = () => {}, activeOption, options = [], style, textStyle = {}}) {
    const classes = useStyles();

    function getContent() {
        return options.map((option, index) =>
            <div key={index} className={classes.option}
                 onClick={_ => onChange(option)}
                 style={{
                     background: option === activeOption ? "#ffd700" : "white",
                     border: option === activeOption ? "solid .1rem #ffc637" : "none",
                     width: `${100 / options.length}%`
                 }}>
                <Typography variant={"button"} style={textStyle}>{option}</Typography>
            </div>
        )
    }

    return (
        <div className={classes.switchButton} style={style}>
            {getContent()}
        </div>
    )
}

export default StoSwitchButton;