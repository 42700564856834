import React, {useEffect, useState} from 'react';

import {makeStyles} from '@mui/styles';
import {useDispatch, useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {
    fetchAllProjects,
    setUserRoles,
    fetchAllProjectsInBin,
    getUser,
    setCurrentProjectId,
    getCompany, acceptLicense
} from "../projectsSlice";
import StoPrimaryButton from "../../utils/components/StoPrimaryButton";
import CreateProjectDialog from "./CreateProjectDialog";
import ProjectList from "./ProjectList";
import theme from "../../theme";
import {LoadingSpinner} from "./LoadingSpinner";
import {useAuth0} from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import ProjectOverviewTabview from "./ProjectOverviewTabview";
import StoCheckbox from "../../utils/components/StoCheckbox";

const useStyle = makeStyles(() => ({
    root: {
        backgroundColor: theme.palette.neutral.primary10,
        minHeight: 'calc(100vh - 5.1rem)'
    },
    projectsHeader: {
        display: 'flex',
        height: '7.8rem',
        borderBottom: `1px solid ${theme.palette.neutral.greyLight}`,
    },
    projectsHeaderContent: {
        width: '112.8rem',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 auto 0 auto',
        padding: "0 1.8rem"
    },
    projectsList: {
        maxWidth: "113rem",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "5rem 0",
        display: 'flex',
        flexDirection: 'column',
        height: "calc(100vh - 25rem)"
    },
    card: {
        width: '7rem',
        height: '5rem',
    },

    version: {
        color: 'darkgrey',
        position: 'absolute',
        bottom: 10,
        right: 10
    },
    license: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '113rem',
        gap: '.8rem',
        padding: '2.4rem',
        border: `1px solid ${theme.palette.neutral.greyLight}`,
    }
}));

function ProjectsOverview(props) {
    const {getAccessTokenSilently} = useAuth0();

    const classes = useStyle(props.theme);
    const loading = useSelector(state => state.projects.listLoading)
    let projects = useSelector(state => state.projects.projectList)
    const dispatch = useDispatch()

    const [createProjectOpen, setCreateProjectOpen] = React.useState(false)
    const [showLicense, setShowLicense] = React.useState(false)
    const [acceptCheck, setAcceptCheck] = React.useState(false)
    const [confirmCheck, setConfirmCheck] = React.useState(false)

    const [inBin, setInBin] = React.useState(false);

    const [text, setText] = useState(""); // init with an empty string

    useEffect(() => {
        get_text_file().then(setText).catch(console.error);
    }, []);

    useEffect(() => {
        dispatch(setCurrentProjectId(null))
        dispatch(fetchAllProjects())
        dispatch(getCompany())
        getUserRoles()
        //eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        dispatch(getUser()).then((user) => {
            if (user.payload.data.user_metadata.agreedToLicense === false) {
                setShowLicense(true)
                console.log("redirect to license page")
            }
        })
    }, [dispatch]);

    const {logout} = useAuth0();

    const handleLogout = () => {
        logout({
            returnTo: window.location.origin,
        });
    };

    const handleAcceptLicense = () => {
        dispatch(acceptLicense()).then(() => {
            setShowLicense(false)
        })
    }


    useEffect(() => {
        const styleElement = document.createElement('style');
        styleElement.innerHTML = 'iframe { display: none; }';
        document.head.appendChild(styleElement);

        // Cleanup, wenn die Komponente unmounted wird
        return () => {
            document.head.removeChild(styleElement);
        };
    }, []);

    const getUserRoles = async () => {
        if (process.env.REACT_APP_PROFILE === "testing" || process.env.REACT_APP_PROFILE === "prod") {
            const accessToken = await getAccessTokenSilently();
            const decoded = jwt_decode(accessToken)
            dispatch(setUserRoles(decoded.permissions))
        } else {
            dispatch(setUserRoles(["admin:all", "admin:support"]))
        }

    }

    const getVersion = () => {
        if (window.location.href.includes("dev")) {
            return process.env.REACT_APP_VERSION
        } else {
            return process.env.REACT_APP_VERSION + "-rc" + process.env.REACT_APP_RC
        }
    }

    const content = () => {
        if (loading) {
            return <LoadingSpinner/>
        } else if (projects.length > 0) {
            return <ProjectList projects={projects}/>;
        } else {
            // if project list is empty check if we are in trash bin or my projects section
            if (!inBin)
                return <Typography variant="body1">No Projects</Typography>;
            else
                return <Typography variant="body1">Trash bin is empty.</Typography>;
        }
    }


    const get_text_file = async () => {
        // prefix public dir files with `process.env.PUBLIC_URL`
        // see https://create-react-app.dev/docs/using-the-public-folder/
        const res = await fetch(`${process.env.PUBLIC_URL}/license.html`);

        // check for errors
        if (!res.ok) {
            throw res;
        }

        return res.text();
    };
    const handleDownload = () => {
        const pdfUrl = process.env.PUBLIC_URL + "/license.pdf"
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "license.pdf"
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    const openTrashCan = () => {
        if (!inBin)
            return dispatch(fetchAllProjectsInBin())

    }
    const openMyProjects = () => {
        if (inBin)
            return dispatch(fetchAllProjects())
    }

    const handleTabChange = (selectedTab) => {
        if (selectedTab === 0) {
            // My projects
            setInBin(false)
            openMyProjects()
        } else if (selectedTab === 1) {
            // Trash Can
            setInBin(true)
            openTrashCan()
        }
    }

    const licenseStuff = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center', padding: '4rem'}}>
                <div className={classes.license}>
                    <div style={{fontSize: '20px', textAlign: 'left'}} dangerouslySetInnerHTML={{__html: text}}></div>
                    <div style={{paddingBottom: '2rem', paddingLeft: '8rem', width: 'inherit', textAlign: 'left'}}>
                        <StoCheckbox title={"I accept all terms of the preceding license agreement"} width={"60rem"}
                                     orientation={"left"} onChange={() => setAcceptCheck(!acceptCheck)}></StoCheckbox>
                        <StoCheckbox title={"I confirm that I am authorized by my company to take that decision"}
                                     width={"60rem"} orientation={"left"}
                                     onChange={() => setConfirmCheck(!confirmCheck)}></StoCheckbox>
                    </div>
                    <StoPrimaryButton disabled={!acceptCheck || !confirmCheck} title={"Accept"}
                                      onClick={() => handleAcceptLicense()} style={{marginTop: '30px'}}/>
                    <StoPrimaryButton title={"Download"} onClick={() => handleDownload()} style={{marginTop: '30px'}}/>
                    <StoPrimaryButton title={"Decline"} onClick={() => handleLogout()} style={{marginTop: '30px'}}/>

                </div>
            </div>
        )
    }

    return (
        <div>
            {showLicense ? licenseStuff() :


                <div className={classes.root}>


                    <div className={classes.projectsHeader}>
                        <div className={classes.projectsHeaderContent}>

                            <ProjectOverviewTabview onTabChange={handleTabChange}/>
                            {!inBin ? (<StoPrimaryButton
                                onClick={() => setCreateProjectOpen(true)}
                                title={"New Project"}
                                width={"14rem"}/>) : null
                            }
                            <CreateProjectDialog open={createProjectOpen} setOpen={setCreateProjectOpen}/>
                        </div>
                    </div>


                    <div className={classes.projectsList}>
                        {content()}
                    </div>
                    <div className={classes.version}>{getVersion()}</div>

                </div>
            }
        </div>


    );
}

export default ProjectsOverview;