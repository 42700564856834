import {createSlice} from "@reduxjs/toolkit";

const unitySlice = createSlice({
    name: 'unity',
    initialState: {
        loaded: false,
        progress: 0,
        createPlan: false,
        isExportOpen: false,
        isExportInfoOpen: false,
        deleteOutlineData: {},
        editMouldingTypeId: null,
        isMainScreen: false,
    },
    reducers: {
        setProgress(state, action) {
            state.progress = action.payload
        },
        setLoaded(state, action) {
            state.loaded = action.payload
        },
        setCreatePlan(state, action) {
            state.createPlan = action.payload
        },
        setDeleteOutline(state, action) {
            state.deleteOutlineData = action.payload
        },
        setEditMouldingType(state, action) {
            state.editMouldingTypeId = action.payload
        },
        setIsExportOpen(state, action) {
            state.isExportOpen = action.payload
        },
        setIsExportInfoOpen(state, action) {
            state.isExportInfoOpen = action.payload
        },
        setIsMainScreen(state, action) {
            state.isMainScreen = action.payload
        }
    }
})

export const {
    setProgress, setLoaded, setCreatePlan, setEditMouldingType, setIsExportOpen, setIsExportInfoOpen, setDeleteOutline,
    setIsMainScreen
} = unitySlice.actions
export default unitySlice.reducer
