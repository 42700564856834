import axios from "axios";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {setMouldingTypes} from "../unity/react-unity-adapter";
import {getElevation} from "../elevation/elevationSlice";
import {getPricing} from "../pricingLevel/pricingSlice";

export const getMouldingTypes = createAsyncThunk('mouldingType/get', async ({projectId}) => {
    try {
        console.log('Get moulding types: ', projectId)
        let result = await axios.get(`/api/projects/${projectId}/mouldingType`)
        setMouldingTypes(result.data)
        console.log("got moulds")
        return result
    } catch (err) {
        return err.message
    }
})

export const getMouldingTypeProfiles = createAsyncThunk('mouldingTypeProfiles/get', async ({projectId}) => {
    try {
        console.log('Get moulding type profiles: ', projectId)
        let result = await axios.get(`/api/projects/${projectId}/mouldingType/profiles`)
        return result
    } catch (err) {
        return err.message
    }
})

export const createMouldingType = createAsyncThunk('mouldingType/create', async ({projectId}) => {
    try {
        console.log('Create moulding type: ', projectId)
        return await axios.post(`/api/projects/${projectId}/mouldingType`)
    } catch (err) {
        return err.message
    }
})

export const updateMouldingType = createAsyncThunk('mouldingType/update', async ({projectId, mouldingType}, thunkAPI) => {
    try {
        console.log('Update moulding type: ', projectId, mouldingType)
        const result = await axios.put(`/api/projects/${projectId}/mouldingType`, mouldingType)
        thunkAPI.dispatch(getPricing({projectId: projectId}))
        return result
    } catch (err) {
        return err.message
    }
})

export const addMouldingTypeToCompany = createAsyncThunk('mouldingType/addToCompany', async ({
                                                                                                 companyId,
                                                                                                 mouldingType
                                                                                             }) => {
    try {
        console.log('Add moulding type to company as preset: ', companyId, mouldingType)
        return await axios.post(`/api/company/moulding`, mouldingType, {params: {companyId: companyId}})
    } catch (err) {
        return err.message
    }
})

export const updateMouldingTypeInCompany = createAsyncThunk('mouldingType/updateInCompany', async ({
                                                                                                       companyId,
                                                                                                       mouldingType
                                                                                                   }) => {
    try {
        console.log('Add moulding type to company as preset: ', companyId, mouldingType)
        return await axios.put(`/api/company/moulding`, mouldingType, {params: {companyId: companyId}})
    } catch (err) {
        return err.message
    }
})

export const deleteMouldingPresetFromCompany = createAsyncThunk('mouldingType/deleteFromCompany', async ({
                                                                                                             companyId,
                                                                                                             mouldingTypeId
                                                                                                         }) => {
    try {
        console.log(`Delete
        moulding preset from company as: `, companyId, mouldingTypeId)
        return await axios.delete(`/api/company/moulding`, {
            params: {
                companyId: companyId,
                mouldingTypeId: mouldingTypeId
            }
        })
    } catch (err) {
        return err.message
    }
})

export const getCompanyMouldings = createAsyncThunk('mouldingType/getCompanyMouldings', async ({companyId}) => {
    try {
        console.log('Get company mouldings: ', companyId)
        return await axios.get(`/api/company/moulding/all`, {params: {companyId: companyId}})
    } catch (err) {
        return err.message
    }
})


export const deleteMouldingType = createAsyncThunk('mouldingType/delete', async ({projectId, mouldingTypeId}, {
    dispatch,
    getState
}) => {
    try {
        console.log('Delete moulding type: ', projectId, mouldingTypeId)
        const result = await axios.delete(`/api/projects/${projectId}/mouldingType/${mouldingTypeId}`)

        const state = getState()
        if (state.elevation && state.elevation.elevation) {
            console.log("getting elevation")
            const {id: elevationId} = state.elevation.elevation
            console.log(elevationId)
            dispatch(getElevation({projectId: projectId, elevationId: elevationId, forceUpdate: true}))
        }

        return result
    } catch (err) {
        return err.message
    }
})

const mouldingTypeSlice = createSlice({
    name: 'mouldingType',
    initialState: {
        mouldingTypes: [],
        loading: false,
        mouldingPresets: []
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getMouldingTypes.pending, (state) => {
                state.loading = true
            })
            .addCase(getMouldingTypes.fulfilled, (state, {payload}) => {
                state.mouldingTypes = payload.data
                state.loading = false
            })
            .addCase(getMouldingTypes.rejected, (state) => {
                state.loading = false
            })

            .addCase(getMouldingTypeProfiles.pending, (state) => {
                state.loading = true
            })
            .addCase(getMouldingTypeProfiles.fulfilled, (state, {payload}) => {
                state.mouldingTypeProfiles = payload.data
                state.loading = false
            })
            .addCase(getMouldingTypeProfiles.rejected, (state) => {
                state.loading = false
            })

            .addCase(createMouldingType.pending, (state) => {
                state.loading = true
            })
            .addCase(createMouldingType.fulfilled, (state, {payload}) => {
                const {data: mt} = payload
                state.mouldingTypes = state.mouldingTypes.concat([mt])
                setMouldingTypes(state.mouldingTypes)
            })
            .addCase(createMouldingType.rejected, (state) => {
                state.loading = false
            })

            .addCase(updateMouldingType.pending, (state) => {
                state.loading = true
            })
            .addCase(updateMouldingType.fulfilled, (state, {payload}) => {
                const {data: mt} = payload
                state.mouldingTypes = state.mouldingTypes.map(m => m.id === mt.id ? mt : m)
                state.loading = false
                setMouldingTypes(state.mouldingTypes)
            })
            .addCase(updateMouldingType.rejected, (state) => {
                state.loading = false
            })

            .addCase(deleteMouldingType.pending, (state) => {
                state.loading = true
            })
            .addCase(deleteMouldingType.fulfilled, (state, {payload}) => {
                const mouldingTypeId = payload.data
                state.mouldingTypes = state.mouldingTypes.filter(m => m.id !== mouldingTypeId)
                state.loading = false
                setMouldingTypes(state.mouldingTypes)
            })
            .addCase(deleteMouldingType.rejected, (state) => {
                state.loading = false
            })

            .addCase(getCompanyMouldings.pending, (state) => {
                state.loading = true
            })
            .addCase(getCompanyMouldings.rejected, (state) => {
                state.loading = false
            })

            .addCase(getCompanyMouldings.fulfilled, (state, {payload}) => {
                state.loading = false
                state.mouldingPresets = payload.data
            })
            .addCase(deleteMouldingPresetFromCompany.fulfilled, (state, {payload}) => {
                state.loading = false
                state.mouldingPresets = payload.data
            })
            .addCase(addMouldingTypeToCompany.fulfilled, (state, {payload}) => {
                state.loading = false
                state.mouldingPresets.unshift(payload.data)
            })

            .addCase(updateMouldingTypeInCompany.fulfilled, (state, {payload}) => {
                state.loading = false
                state.mouldingPresets.unshift(payload.data)
            })

    }
})

export default mouldingTypeSlice.reducer