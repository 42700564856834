import axios from "axios";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setPanelTypes} from "../unity/react-unity-adapter";
import {getPricing} from "../pricingLevel/pricingSlice";

export const getPanelTypes = createAsyncThunk('panelTypes/get', async ({projectId}) => {
    try {
        console.log("Get all panelTypes types")
        return await axios.get(`/api/projects/${projectId}/panel-types`)
    } catch (err) {
        return err.message
    }
})

export const updatePanelTypes = createAsyncThunk('panelType/updateAll', async({projectId, panelTypes}, thunkAPI) => {
    try {
        console.log("Update panel types", panelTypes)
        let result = await axios.put(`/api/projects/${projectId}/panel-types`, panelTypes)
        thunkAPI.dispatch(getPricing({projectId: projectId}))
        return result
    } catch(err) {
        return err.message
    }
})

export const updatePanelType = createAsyncThunk('panelType/update', async({projectId, panelType}, thunkAPI) => {
    try {
        console.log("Update panel type", panelType)
        let result = await axios.put(`/api/projects/${projectId}/panel-types/${panelType.id}`, panelType)
        thunkAPI.dispatch(getPricing({projectId: projectId}))
        return result
    } catch(err) {
        return err.message
    }
})

const panelTypeSlice = createSlice({
    name: 'panelTypes',
    initialState: {
        panelTypes: [],
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getPanelTypes.pending, (state) => {
                state.loading = true
            })
            .addCase(getPanelTypes.fulfilled, (state, action) => {
                const panelTypes = action.payload.data
                if (!panelTypes) {
                    return
                }
                state.panelTypes = [...panelTypes]
                state.loading = false
                setPanelTypes(panelTypes)
            })
            .addCase(getPanelTypes.rejected, (state) => {
                state.loading = false
            })
            .addCase(updatePanelType.pending, (state) => {
                state.loading = true
            })
            .addCase(updatePanelType.fulfilled, (state, action) => {
                const panelType = action.payload.data
                if (!panelType || !state.panelTypes) {
                    return
                }
                const index = state.panelTypes.findIndex((p) => p.id === panelType.id)
                state.panelTypes[index] = panelType
                state.loading = false
                setPanelTypes(state.panelTypes)
            })
            .addCase(updatePanelType.rejected, (state) => {
                state.loading = false
            })
            .addCase(updatePanelTypes.pending, (state) => {
                state.loading = true
            })
            .addCase(updatePanelTypes.fulfilled, (state, action) => {
                const panelTypes = action.payload.data
                if (!panelTypes) {
                    return
                }
                state.panelTypes = panelTypes
                state.loading = false
                setPanelTypes(panelTypes)
            })
            .addCase(updatePanelTypes.rejected, (state) => {
                state.loading = false
            })
    }
})

export default panelTypeSlice.reducer
