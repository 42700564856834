import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {Dialog, DialogContent, IconButton, Typography} from "@mui/material";
import CloseIcon from '../icons/close.svg'
import SaveIcon from '../icons/save.svg'
import BackIcon from '../icons/back_black.svg'
import OpenIcon from '../icons/open.svg'

const useStyle = makeStyles(theme => ({
    root: {
        '& .MuiDialog-paper': {
            backgroundColor: theme.palette.neutral.primary10,
            borderRadius: '2px',
            padding: '2.4rem',
            margin: 0,
        },
        '& .MuiIconButton-root:hover': {
            backgroundColor: 'transparent'
        },
        '& .MuiDialogContent-root': {
            padding: 0,
        }
    },
    dialogHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '25px'
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '5rem',
        userSelect: 'none',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',

    },
    closeIcon: {
        border: 'none',
        width: '25px',
        height: '25px',
    },
    divider: {
        display: 'inline-block',
        width: 0,
        height: '15px',
        borderLeft: '1px solid #ccc',
    },
    backIcon: {
        border: 'none',
        width: '25px',
        height: '25px',
        marginRight: '16px',
    },
    saveIcon: {
        border: 'none',
        width: '25px',
        height: '25px'
    },
    openIcon: {
        border: 'none',
        width: '25px',
        height: '25px',
    },

    dialogContent: {
        overflow: "hidden!important",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    actions: {
        overflow: "hidden!important",
        marginTop: "3.6rem"
    }
}));

function StoDialog({
                       maxWidth,
                       minHeight,
                       open,
                       title,
                       onClose,
                       onBack,
                       content,
                       onSave,
                       onOpen,
                       actions,
                       closeHidden,
                       maxHeight,
                       ...props
                   }) {
    const classes = useStyle(props.theme);
    maxWidth = `calc(${maxWidth} - 4.8rem)`
    return (
        <Dialog className={classes.root} maxWidth={maxWidth} open={open} onClose={onClose}
                PaperProps={{style: {minHeight: minHeight, maxHeight: maxHeight}}}
        >
            <div className={classes.dialogHeader} style={{width: maxWidth}}>
                <div className={classes.titleContainer}>


                    {/*only show, if onBack is not null*/}
                    {onBack &&
                        <IconButton size="small" onClick={onBack}>
                            <img src={BackIcon} alt={'close'} className={classes.backIcon}/>
                        </IconButton>
                    }

                    <Typography variant={"h1"} className={classes.title}>{title}</Typography>
                </div>
                {onSave &&
                    <IconButton size="small" onClick={onSave}>
                        <img src={SaveIcon} alt={'save'} className={classes.saveIcon}/>

                    </IconButton>
                }

                {onOpen &&
                    <IconButton size="small" onClick={onOpen}>
                        <img src={OpenIcon} alt={'open'} className={classes.openIcon}/>

                    </IconButton>
                }
                {onOpen && <span className={classes.divider}/>}

                {!closeHidden &&
                    <IconButton size="small" onClick={onClose}>
                        <img src={CloseIcon} alt={'close'} className={classes.closeIcon}/>
                    </IconButton>
                }
            </div>
            <DialogContent className={classes.dialogContent} style={{width: maxWidth}}>
                {content}
            </DialogContent>
            <div className={classes.actions}>
                {actions}
            </div>
        </Dialog>
    );
}

StoDialog.propTypes = {
    maxWidth: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onBack: PropTypes.func,
    title: PropTypes.string.isRequired,
    content: PropTypes.object,
    actions: PropTypes.object

};
StoDialog.defaultPropTypes = {};
export default StoDialog;