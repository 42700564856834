import axios from "axios";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setProjectInfoUnity} from "../unity/react-unity-adapter";

export const getProjectInfo = createAsyncThunk('projectInfo/get', async ({projectId}) => {
    try {
        console.log('Get project info: ', projectId)
        return await axios.get(`/api/projects/${projectId}/projectInfo`)
    } catch (err) {
        return err.message
    }
})

export const updateProjectInfo = createAsyncThunk('projectInfo/update', async ({projectId, projectInfo}) => {
    try {
        console.log('Update project info: ', projectInfo)
        return await axios.put(`/api/projects/${projectId}/projectInfo`, projectInfo)
    } catch (err) {
        return err.message
    }
})

const projectInfoSlice = createSlice({
    name: 'projectInfo',
    initialState: {
        projectInfo: {
            id: '',
            name: '',
            key: '',
            location: null,
            investor: '',
            architect: '',
            characteristics: '',
            notes: '',
        },
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getProjectInfo.pending, (state) => {
                state.loading = true
            })
            .addCase(getProjectInfo.fulfilled, (state, {payload}) => {
                state.projectInfo = payload.data
                state.loading = false
                setProjectInfoUnity(state.projectInfo)
            })
            .addCase(getProjectInfo.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateProjectInfo.pending, (state) => {
                state.loading = true
            })
            .addCase(updateProjectInfo.fulfilled, (state, {payload}) => {
                state.projectInfo = payload.data
                state.loading = false
                //setProjectUnity(state.project)
            })
            .addCase(updateProjectInfo.rejected, (state) => {
                state.loading = false
            })
    }
})

export default projectInfoSlice.reducer