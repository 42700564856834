import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import {setPanelizationSettings} from '../unity/react-unity-adapter'

export const getPanelizationSettings = createAsyncThunk('panelizationSettings/get', async ({projectId}) => {
    try {
        console.log("receiving PanelizationSettings from Server")
        return await axios.get(`/api/projects/${projectId}/panelizationSettings`)
    } catch (err) {
        return err.message
    }
})

export const updatePanelizationSettings = createAsyncThunk('panelizationSettings/update', async ({projectId, panelizationSettings}) => {
    try {
        return await axios.put(`/api/projects/${projectId}/panelizationSettings`, panelizationSettings)
    } catch (err) {
        return err.message
    }
})

const panelizationSettingsSlice = createSlice({
    name: 'panelizationSettings',
    initialState: {
        panelizationSettings: {},
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getPanelizationSettings.pending, (state) => {
                state.loading = true
            })
            .addCase(getPanelizationSettings.fulfilled, (state, {payload}) => {
                state.panelizationSettings = payload.data
                state.loading = false
                setPanelizationSettings(state.panelizationSettings)
            })
            .addCase(getPanelizationSettings.rejected, (state) => {
                state.loading = false
            })
            .addCase(updatePanelizationSettings.pending, (state) => {
                state.loading = true
            })
            .addCase(updatePanelizationSettings.fulfilled, (state, {payload}) => {
                state.panelizationSettings = payload.data
                state.loading = false
                setPanelizationSettings(state.panelizationSettings)
            })
            .addCase(updatePanelizationSettings.rejected, (state) => {
                state.loading = false
            })


    }
})

export default panelizationSettingsSlice.reducer