import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import StoImageOption from "./StoImageOption";
import PropTypes from "prop-types";

const useStyle = makeStyles(() => ({
    imageSelection: {
        display: 'flex',
        height: '20rem',
        flexDirection: 'row',
        paddingTop: '1rem',
        gap: '2rem',
        justifyContent: "space-around"
    },
    imageRow: {
        margin: "2rem 0 8rem 0",
    }
}))

function StoImageOptions({title, options = [], onChange}) {
    const classes = useStyle();

    function getContent() {
        return options.map((option, index) => {
            return <StoImageOption key={index}
                                   option={option.option}
                                   image={option.image}
                                   checked={option.checked}
                                   onChange={onChange}
                                   subtitle={option.subtitle}/>
        })
    }

    return <div className={classes.imageRow}>
        <Typography variant="h5">{title}</Typography>
        <div className={classes.imageSelection}>
            {getContent()}
        </div>
    </div>

}

StoImageOptions.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
}

export default StoImageOptions