import * as React from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {makeStyles} from "@mui/styles";
import {getDayName} from "../timeUtils";
import "./StoTimePicker.css"

const useStyles = makeStyles(_ => ({
    datePicker: {
        "& .MuiInputBase-root": {
            backgroundColor: "transparent",
            width: "16rem",

            "& .MuiInputBase-input": {
                paddingLeft: "2.4rem",
            },

            "& .MuiInputAdornment-root": {


                "& .MuiSvgIcon-root": {
                    width: "2rem",
                }
            },

            "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
            }
        },
    },
}))

export default function StoTimePicker({
                                          defaultValue, onChange = () => {
    }, workingDays = ['Monday', 'Thursday', 'Wednesday', 'Tuesday', 'Friday', 'Saturday', 'Sunday']
                                      }) {
    const classes = useStyles();

    const time = defaultValue
        ? dayjs(defaultValue.year + "-" + defaultValue.month + "-" + defaultValue.day)
        : dayjs("2024-01-01")

    function onValueChange(value) {
        const timeStamp = dayjs(value).format("YYYY-MM-DD").toString().split("-")
        const time = {
            year: timeStamp[0],
            month: timeStamp[1],
            day: timeStamp[2]
        }
        onChange(time)
    }

    function disableWeekends(date) {
        return !workingDays?.includes(getDayName(date.toDate()))
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker className={classes.datePicker} value={time} views={['year', 'month', 'day']}
                        onChange={(event) => onValueChange(event)}
                        shouldDisableDate={disableWeekends}/>
        </LocalizationProvider>
    );
}