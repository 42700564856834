import {Chip} from "@mui/material";

function ColorPaletteItem({name, r, g, b, onClick, isActive}) {
    const color = 'rgb(' + r + ',' + g + ',' + b + ')'
    //calculate if color is closer to white or black and choose font color accordingly
    const Y = 0.2126 * r + 0.7152 * g + 0.0722 * b
    const fontColor = Y > 128 ? "black" : "white"


    return (
        <Chip label={name} style={{
            backgroundColor: color,
            color: fontColor,
            fontSize: '14px',
            borderRadius: '2px',
            height: '40px',
            width: '90px',
            margin: '5px 5px 5px 5px',
            outline: isActive ? '3px solid #ffd700 ' : '',
            fontWeight: isActive ? 'bold' : '',
            outlineOffset: '2px'
        }}
              onClick={onClick}/>

    )
}

export default ColorPaletteItem;