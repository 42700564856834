import React from 'react'
import './export.css'
import {ExportPanelTypes} from './ExportPanelTypes'
import {ExportMaterials} from './ExportMaterials'
import {ExportLevel3CostTable} from './ExportLevel3CostTable'
import {useParams} from 'react-router-dom'
import {ExportProjectData} from './ExportProjectData'


export const ExportElevationOverview = (props) => {

    const {elevationId} = useParams();
    const exportData = props.data
    const hiddenBox = {
        display: 'block',
        opacity: 0,
    }

    const elevation = (elevationId === "hiddenFaces") ? "??" : elevationId
    const name = exportData.elevations.find(result => result.id === elevationId) ? exportData.elevations.find(result => result.id === elevationId).name : "No Assigned Materials"


    const project = props.project
    const image = (elevation === "??") ?
        project.blobStorageUrl + "projects/" + project.id + "/screenshots/elevations/visual_hidden.png"
        :
        project.blobStorageUrl + "projects/" + project.id + "/screenshots/elevations/visual_" + elevation +".png"

    const panelImage = (elevation === "??") ?
        project.blobStorageUrl + "projects/" + project.id + "/screenshots/elevations/visual_hidden.png"
        :
        project.blobStorageUrl + "projects/" + project.id + "/screenshots/elevations/panels_" + elevation +".png"

    const panelImageTag = (elevation === "??") ? "" : <img src={panelImage} draggable="true" alt="Panel View"/>

    return (
        <div id="container">
            <div id="content">
                <div className="row">
                    <div className="col">
                     <h1>{name}</h1>
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="row">
                    <div className="col">
                        <img src={image} draggable="true" alt="Visualisation"/>
                    </div>
                    <div className="col">
                        <ExportProjectData  data={exportData} elevation={elevation}/>
                    </div>
                </div>

                <div className="spacer"></div>
                <div className="subheader">Panel View</div>

                <div className="row">

                    {panelImageTag}

                </div>


                <div className="spacer"></div>
                <div className="row">
                    <div className="col">
                        <div className="text">
                            <ExportPanelTypes data={exportData.panelizationResult.elevationResults.find(result => result.location === elevation).panelTypes} elevationId={elevation}
                                              panelTypes={exportData.panelTypes} unitSystem={exportData.unitSystem}/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="text">
                            <ExportMaterials data={exportData.panelizationResult.elevationResults.find(result => result.location === elevation).finishes}
                                             materials={exportData.materials} unitSystem={exportData.unitSystem}/>
                        </div>
                    </div>
                </div>
                <div className="spacer"></div>

                <div className="subheader">Detailed Costs</div>
                <div className="text">
                    <ExportLevel3CostTable data={exportData.pricingSummary.pricingSummaryElevations.find(result => result.location === elevation)} unitSystem={exportData.unitSystem}
                                           panelizationResult={exportData.panelizationResult.elevationResults.find(result => result.location === elevation)} pricingLevel={exportData.settings.pricingLevel}/>
                </div>
            </div>
            <div id="copied" style={hiddenBox}>Copied to Clipboard</div>
        </div>


    )
}