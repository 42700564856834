import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@mui/styles";
import {Button, Tooltip, Typography} from "@mui/material";
import {getToolTipTitle} from "../toolTipUtil";

const useStyle = makeStyles(theme => ({
    primary: {
        height: '3.6rem!important',
        width: '100%important!',
        borderRadius: '2px!important',
        backgroundColor: `${theme.palette.primary.main}!important`,
        border: '1px solid #FFC637!important',
        '&:disabled': {
            backgroundColor: '#fff0a2!important',
            borderColor: '#ffe65d!important',
        }
    },
    secondary: {
        height: '3.6rem!important',
        width: '100%important!',
        borderRadius: '2px!important',
        backgroundColor: `${theme.palette.neutral.primary10}!important`,
        '&:hover': {
            backgroundColor: `${theme.palette.neutral.primary20}!important`
        },
        '& span': {
            color: `${theme.palette.neutral.primary60}!important`,
        }
    },
}));

function StoPrimaryButton({title, onClick, secondary, disabled, width, tooltipTitle = null, tooltipText, ...props}) {
    const classes = useStyle(props.theme);

    function getButton() {
        return (<Button className={secondary ? classes.secondary : classes.primary} style={{width: width}}
                        onClick={onClick} fullWidth disabled={disabled}
        >
            <Typography variant={'button'}
                        style={{color: disabled ? '#ccc' : '', cursor: disabled ? 'default' : 'pointer'}}>
                {title}
            </Typography>
        </Button>)
    }

    return (
        tooltipTitle
            ? <Tooltip title={getToolTipTitle(tooltipTitle, tooltipText)} arrow={true} placement={"bottom-start"}>
                <div>
                    {getButton()}
                </div>
            </Tooltip>
            : getButton()
    );
}

StoPrimaryButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    secondary: PropTypes.bool,
    disabled: PropTypes.bool,
    width: PropTypes.string
};
StoPrimaryButton.defaultPropTypes = {};
export default StoPrimaryButton;