import React from 'react'
import './export.css'
import {selectText} from './Helpers'


export const ExportCustomerInfo = (props) => {

    const exportData = props.data


    return (
        <div>
            <table id="customerTable" className={"export-table"}>
                <tbody>
                <tr>
                    <td colSpan="2" className="infoblockTitle copytable"  onMouseDown={() => selectText("customerTable")}>
                        Customer Information
                    </td>
                </tr>
                <tr>
                    <td className="tlabel">Company Name</td>
                    <td className="tcontent">{exportData.client.companyName}</td>
                </tr>
                <tr>
                    <td className="tlabel">Country</td>
                    <td className="tcontent">{exportData.client.location.country}</td>
                </tr>
                <tr>
                    <td className="tlabel">City</td>
                    <td className="tcontent">{exportData.client.location.city}</td>
                </tr>
                <tr>
                    <td className="tlabel">Postal Code</td>
                    <td className="tcontent">{exportData.client.location.postCode}</td>
                </tr>
                <tr>
                    <td className="tlabel">Address</td>
                    <td className="tcontent">{exportData.client.location.address}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};