import React, {useEffect} from 'react'

import {getExportData} from '../exportSlice'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {ExportElevationOverview} from './ExportElevationOverview'
import {getProject} from '../../projects/projectsSlice'
import './export.css'


function ExportElevation() {
    const {projectId} = useParams();
    const dispatch = useDispatch()

    const exportData = useSelector(state => state.export.export)
    const project = useSelector(state => state.projects.project)

    useEffect(() => {
        dispatch(getExportData({projectId}))
        dispatch(getProject({projectId}))
        document.body.classList.add('export')
        //eslint-disable-next-line
    }, [dispatch]);

    return (
        <div>
            {exportData.panelizationResult ? <ExportElevationOverview data={exportData} project={project} pricingLevel={exportData.settings.pricingLevel}/> : null}
        </div>

    );
}

export default ExportElevation;