import React, {Fragment} from "react";
import {makeStyles} from "@mui/styles";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import PricingSummaryCategory from "./PricingSummaryCategory";
import {ArrowDropDown} from "@mui/icons-material";
import PricingSummaryCustomElement from "./PricingSummaryCustomElement";
import {addPricingSummaryItem} from "../pricingSummarySlice";
import {useParams} from "react-router-dom";
import PricingSummaryResult from "./PricingSummaryResult";
import PlusIcon from "../../utils/icons/plus.svg";

const useStyle = makeStyles(() => ({
    levelButton: {
        width: '125px',
        height: "inherit",
        borderRadius: '2px'
    },
    buttonGroup: {
        height: '25px',
        borderRadius: '2px',
        marginLeft: '10rem',
        outline: '1px solid lightgrey',
        outlineOffset: '8px',
    },
    pricingSelector: {
        float: 'left',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '280px',
        background: 'white',
        boxSizing: 'border-box',
        paddingTop: '1rem',
        borderRight: '1px solid lightgrey',
        bottom: '0',
        top: '0',
        position: 'absolute',

    },
    pricingSummaryRoot: {
        boxSizing: 'border-box',
        paddingBottom: '5rem'
    },
    pricingCategoryFont: {
        fontFamily: 'FrutigerNext-Bd',
        fontSize: '16px !important',
        margin: '3rem 0',
    },
    radioButton: {
        margin: '1rem 0',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        width: '100%',
        fontFamily: 'FrutigerNext',
        fontSize: '16px',
    },
    radioGroup: {
        width: 'auto',
        paddingLeft: '4rem',
        borderBottom: '1px solid lightgrey',
        paddingBottom: '1rem',
    },
    contentArea: {
        overflowY: "auto",
        width: "calc(100% - 30rem)",
        boxSizing: "border-box",

        paddingTop: '3.6rem',
        paddingLeft: '3.2rem',
        paddingRight: '3.2rem',
    },
    formControl: {
        width: '100%',

    },
    addCustom: {
        display: 'flex',
        alignItems: 'center',
        height: '4.8rem',
        width: '83.6rem',

        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#f5f5f5',
        },
        marginBottom: '1rem',
    }

}))

function PricingSummary(props) {
    const classes = useStyle(props.theme);
    const pricingSummary = useSelector(state => state.pricingSummary.pricingSummary)
    const unitSystem = useSelector(state => state.unitSystem.unitSystem)
    const customValues = useSelector(state => state.pricingSummary?.pricingSummary?.customValues?.[0]?.items || [])
    const {projectId} = useParams();
    const dispatch = useDispatch()
    const [isExpanded, setIsExpanded] = React.useState(true);

    const addItem = () => {
        dispatch(addPricingSummaryItem({projectId}))
    }

    if (!pricingSummary) {
        return null
    }

    return (
        <div className={classes.pricingSummaryRoot}>
            <Typography variant="h1">Pricing Summary</Typography>
            {
                pricingSummary.pricingSummaries?.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <PricingSummaryCategory category={item} unitSystem={unitSystem}/>
                        </Fragment>
                    )
                })
            }

            <Accordion className={classes.root} elevation={0} disableGutters={true} defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ArrowDropDown/>}
                    aria-controls={'custom-values'}
                    id={'custom-values'}
                    focusVisibleClassName={classes.focusVisible}
                    style={{height: '6rem', width: '85rem', background: 'white'}}
                    expanded={isExpanded}
                    onChange={() => setIsExpanded(!isExpanded)}
                >
                    <Typography
                        className={classes.pricingCategoryFont}>Custom Price
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {customValues && customValues.length > 0 && customValues.map((item, i) => {
                        return (
                            <div key={i} style={{display: 'flex', alignItems: 'center'}}>
                                <PricingSummaryCustomElement item={item} unitSystem={unitSystem} key={i}
                                                             lastElement={i + 1 === customValues.length}/>
                            </div>
                        )
                    })}
                    <div className={classes.addCustom} onClick={addItem}>
                        <img alt={'custom'} src={PlusIcon}/>
                        <Typography variant="body1" style={{marginLeft: '1rem'}}>Add Custom Price</Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Typography variant="h1">Result</Typography>
            {
                pricingSummary.result?.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <PricingSummaryResult category={item} unitSystem={unitSystem} isResult={true}/>
                        </Fragment>
                    )
                })
            }
        </div>
    );
}

export default PricingSummary