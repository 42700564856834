import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    checkbox: {
        width: '32px',
        height: '32px',
        borderRadius: '32px',
        gap: '10px',
        border: '1px solid',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    title: {

        userSelect: 'none',
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
    }
}))

function StoRoundCheckbox({initialChecked, title, onCheckboxChange}) {
    const classes = useStyles();
    const [checked, setChecked] = useState(null);

    useEffect(() => {
        setChecked(initialChecked)
    }, [initialChecked])

    const handleCheckboxClick = (newValue) => {
        setChecked(newValue);
        onCheckboxChange(newValue)
    };

    const isChecked = (checked == null ? initialChecked : checked)
    const background = isChecked ? '#FFD700' : '#EDEDED'
    const borderColor = isChecked ? "#FFC637" : "#EDEDED"

    return (
        <div className={classes.checkbox}
             onClick={() => handleCheckboxClick(!isChecked)}
             style={{background: background, borderColor: borderColor}}
        >
            <span className={classes.title}>{title}</span>
        </div>
    )
}

export default StoRoundCheckbox;
