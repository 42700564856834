import {Collapse, Typography} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import CollapseIcon from "../../utils/icons/collapse.svg";
import StoInput from "../../utils/components/StoInput";
import {makeStyles} from "@mui/styles";
import StoTag from "../../utils/components/StoTag";
import {useSelector} from "react-redux";

import LinkIcon from "../../utils/icons/link.svg"
import UnlinkIcon from "../../utils/icons/unlink.svg"
import {unitSystems} from "../../unitSystem/unitSystemSlice";

const useStyle = makeStyles(_ => ({
    collapse: {
        display: "flex",
        alignItems: "center",
        height: "4.8rem",
        gap: ".8rem",
        width: "46rem",
    },
    title: {
        paddingTop: ".4rem",
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    tags: {
        display: "flex",
        alignItems: "center",
        gap: ".4rem",
    },

    orderRow: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
    },

    content: {
        display: "flex",
        flexDirection: "column",
        gap: ".4rem",
        width: "43.6rem",
    },
    finishType: {
        display: "flex",
        alignItems: "center",
        height: '4.4rem',
    },

    finishTypeName: {
        display: "flex",
        paddingLeft: "2.4rem",
        width: "68.7rem",
        boxSizing: "border-box",
    },

    connection: {
        display: "flex",
        width: "2.4rem",
        height: "max-content",
        paddingTop: "2rem",
        paddingBottom: "2.6rem",
        boxSizing: "border-box",
    },
    border: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    verticalBorder: {
        height: "4.6rem",
        marginLeft: ".6rem",
    },
    connectButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "1rem",
        marginLeft: "-.6rem",
    },
    icon: {
        userSelect: "none",
        width: "2.4rem",
        height: "2.4rem",
        borderRadius: ".2rem",
        border: ".1rem solid #CCCCCC",
    },
    smallIcon: {
        width: "1.6rem",
    },
}))

function SchedulingWorkPackageSettings({
                                           workPackage: data,
                                           onChangeValue,
                                           onChangeFinishTypeValue,
                                           collapse,
                                           toggleCollapse
                                       }) {
    const classes = useStyle()

    const [connected, setConnected] = useState(true)
    const {unitSystem} = useSelector(state => state.unitSystem)

    const [rerender, setRerender] = useState(false)

    const [workPackage, setWorkPackage] = useState(JSON.parse(JSON.stringify(data)))

    useEffect(() => {
        setWorkPackage(JSON.parse(JSON.stringify(data)))
    }, [data])

    function changeValue(finishTypeId, value) {
        value = value.replace(",", ".")

        value = parseFloat(value)

        if (isNaN(value)) {
            return
        }

        if (connected) {
            workPackage.finishTypes.forEach(finishType =>
                finishType.value = value
            )
            onChangeValue(value)
            setRerender(!rerender)
        } else {
            onChangeFinishTypeValue(finishTypeId, value)
        }
    }

    function getTagName(name) {
        switch (name) {
            case "PANEL_PER_DAY":
                return "Panel"
            case "PER_HOUR":
                return "hour"
            case "PER_DAY":
                return "day"
            case "AREA_PER_DAY":
                if (unitSystem === unitSystems.IMPERIAL) {
                    return "Square Feet"
                }
                return "Square Meter"
            default:
                return name
        }
    }

    function toggleConnected() {
        setConnected(!connected)
    }

    const borderStyle = connected ? ".1rem solid #cccccc" : ".1rem dashed #cccccc"

    return (
        <Fragment>
            <div className={classes.collapse} onClick={(event) => toggleCollapse(workPackage, event.altKey)}>
                <img className={classes.smallIcon} src={CollapseIcon} alt={'icon'}
                     style={{transform: collapse ? "rotate(0deg" : "rotate(270deg)"}}/>
                <div className={classes.header}>
                    <Typography className={classes.title} variant={"button"}>{workPackage.workPackageName}</Typography>
                    <div className={classes.tags}>
                        <StoTag text={getTagName(workPackage.calculationType)}/>
                        <Typography variant={"subtitle1"}>/</Typography>
                        <StoTag text={getTagName(workPackage.calculationUnit)}/>
                    </div>
                </div>
            </div>
            <Collapse in={collapse}>
                <div className={classes.orderRow}>
                    <div className={classes.content}>
                        {workPackage.finishTypes.map((finishType, index) => {
                            return <div key={index} className={classes.finishType}>
                                <Typography className={classes.finishTypeName}
                                            variant={"subtitle1"}>{finishType.finishTypeName}</Typography>
                                <StoInput label={"Value"} value={finishType.value}
                                          onBlur={event => changeValue(finishType.finishTypeId, event.target.value)}/>
                            </div>
                        })}
                    </div>
                    {workPackage.finishTypes.length > 1 &&
                        <div className={classes.connection}>
                            <div className={classes.border} style={{borderRight: borderStyle}}>
                                {workPackage.finishTypes.map((finishType, index) => {
                                    if (index !== workPackage.finishTypes.length - 1)
                                        return <div key={index} className={classes.verticalBorder}
                                                    style={{
                                                        borderTop: borderStyle,
                                                        borderBottom: borderStyle
                                                    }}/>
                                    return null
                                })}
                            </div>
                            <div className={classes.connectButton} style={{
                                paddingTop: workPackage.finishTypes.length % 2 === 0 ? ".2rem" : '.4rem'
                            }}>
                                <img alt={'icon'} src={connected ? LinkIcon : UnlinkIcon} className={classes.icon}
                                     onClick={toggleConnected}
                                     style={{
                                         background: connected ? "#EDEDED" : "#ffffff",
                                         border: connected ? ".1rem solid #CCCCCC" : "none"
                                     }}/>
                            </div>
                        </div>
                    }
                </div>
            </Collapse>
        </Fragment>
    )

}

export default SchedulingWorkPackageSettings