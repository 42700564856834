import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';

import {List, ListItem, Paper, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import StoTextInput from "../../utils/components/StoTextInput";
import StoDialog from "../../utils/components/StoDialog";
import StoPrimaryButton from "../../utils/components/StoPrimaryButton";
import StoFileInput from "../../utils/components/StoFileInput";

import {setCreatePlan} from "../../unity/unitySlice";
import {useDispatch} from "react-redux";
import StoRadioDelete from "../../utils/components/StoRadioDelete";
import {deletePlan} from "../../plan/plansSlice";

const useStyle = makeStyles(theme => ({
    root: {},
    toggleButtons: {
        marginTop: "0.6rem",
        backgroundColor: theme.palette.neutral.primary20,
        borderRadius: "2px!important",
        marginBottom: "0.6rem",
        height: "3.2rem",
        '& .MuiToggleButton-root': {
            borderRadius: "2px",
            fontSize: '13px',
            lineHeight: 9,
            backgroundColor: '#e4e4e4'
        },
    },
    planList: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        height: '12.8rem',
        overflow: "hidden",
        backgroundColor: theme.palette.neutral.white,
        border: `1px solid ${theme.palette.neutral.primary40}`,
        borderRadius: "2px!important",
        boxShadow: 'none!important',
        '& .MuiList-root': {
            padding: "0!important"
        }
    },
    listItem: {
        height: "3.5rem",
        padding: '0 2rem',
        ' &:not(:first-child)': {
            borderTop: `1px solid ${theme.palette.neutral.primary40}`
        },
    },
    planname: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    actions: {
        height: "8.4rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }
}));

function CreateDialog({
                          open,
                          setOpen,
                          type,
                          plans,
                          initialName,
                          name,
                          setName,
                          plan,
                          setPlan,
                          onSubmit,
                          ...props
                      }) {
    const classes = useStyle(props.theme);

    const dispatch = useDispatch()
    const [toggleValue, setToggleValue] = React.useState(0)
    const [selectedPlanId, setSelectedPlanId] = React.useState();
    const [disabled, setDisabled] = React.useState(true)
    const [deleteOpen, setDeleteOpen] = React.useState(false)
    const [deleteDone, setDeleteDone] = React.useState(0) // 0 = false, 1 = true, 2 = error


    useEffect(() => {
        if (name && plan) {
            if (toggleValue === 1 || (toggleValue === 0 && plan.type === "application/pdf"))
                setDisabled(false)
            else
                setDisabled(true)

        } else {
            setDisabled(true)
        }
    }, [name, plan, toggleValue])

    const handleClose = () => {
        setToggleValue(0)
        setSelectedPlanId(undefined)
        setPlan(0)
        setName('')
        setOpen(false)
        dispatch(setCreatePlan(null))
    }

    const handleSubmit = async () => {
        onSubmit()
        handleClose()
    }

    const onPlanChange = (plan) => {
        if (plan !== null) {
            let name = plan.name
            setPlan(plan)
            setName(name.substr(0, name.lastIndexOf('.')))
        } else {
            setPlan(null)
            setName("")
        }
    }

    const handleToggle = (event, toggle) => {
        if (toggle !== null) {
            setToggleValue(toggle)
            if (name === "")
                setName(initialName)
        }

    };

    const handleRadio = (plan) => () => {
        setPlan(plan)
        setSelectedPlanId(plan.id)
    };

    const handleDelete = async (plan, type) => {

        const res = await dispatch(deletePlan({
            projectId: props.projectId,
            planId: plan.id,
            planType: type.toUpperCase()
        }))

        if (res.payload !== undefined) {
            setDeleteDone(1)
        } else {
            setDeleteDone(2)
        }

    };

    const openDeleteDialog = (event, plan) => {
        event.stopPropagation()
        setPlan(plan)
        setDeleteOpen(true)
        // unselect all the radio buttons
        setSelectedPlanId(undefined)
    }

    const closeDeleteDialog = (event) => {
        event.stopPropagation()
        setPlan(0)
        setDeleteOpen(false)
        setDeleteDone(0)
    }

    const getContent = () => {
        if (deleteDone === 0) {
            return <div>
                <Typography variant={"h1"} className={classes.deleteProjectTitle}>
                    {"Are you sure you want to delete this plan?"}</Typography>
                <Typography variant={"subtitle1"} style={{paddingTop: "2rem"}}>
                    {"All your designs will be lost. This can not be reversed."}</Typography>
            </div>
        }
        if (deleteDone === 1) {
            return <div>
                <Typography variant={"h1"} textAlign={"center"}>
                    {"Plan successfully deleted!"}
                </Typography>
            </div>
        }
        if (deleteDone === 2) {
            return <div>
                <Typography variant={"h1"} textAlign={"center"}>
                    {"Plans in use cannot be deleted! Delete the " + type + " first!"}
                </Typography>
            </div>
        }
    }

    const getPlanName = (name) => {
        // removing the uniqueID from beginning of the string
        const modifiedName = name.replace(/^\d{6}_/, '');

        if (name.length > 25) {
            return modifiedName.substring(0, 22) + "..."
        } else {
            return modifiedName
        }
    }


    return (
        <>
            <StoDialog maxWidth={"36rem"} open={open} onClose={handleClose} title={"New " + type}
                       content={
                           <div style={{maxWidth: '36rem'}}>
                               <StoTextInput title={"Name"} value={name || ''} tabIndex={2}
                                             placeholder={"Enter the " + type + "name here"}
                                             onChange={(event) => setName(event.target.value)}/>

                               <Typography variant={"subtitle1"}>{type + "planfile"}</Typography>

                               <ToggleButtonGroup value={toggleValue} exclusive onChange={handleToggle} fullWidth
                                                  size={"small"} color={"standard"} className={classes.toggleButtons}>
                                   <ToggleButton value={0}>{"Upload new file"}</ToggleButton>
                                   <ToggleButton value={1}>{"Use existing file"}</ToggleButton>
                               </ToggleButtonGroup>

                               {toggleValue === 0 ?
                                   <StoFileInput onChange={(event) => onPlanChange(event.target.files[0])}
                                                 onDelete={() => onPlanChange(null)}
                                                 file={(plan && plan.type === "application/pdf") ? plan : null}/>
                                   :
                                   <div>
                                       <Paper className={classes.planList}
                                              style={{
                                                  alignItems: plans.length === 0 ? "center" : null,
                                                  overflow: 'auto'
                                              }}>
                                           <List dense sx={{width: '100%', maxWidth: "36rem", maxHeight: '12.8rem'}}>
                                               {plans.length === 0 ?
                                                   <Typography variant={"subtitle1"}>
                                                       {"No plans have been uploaded in this project yet"}
                                                   </Typography>
                                                   :
                                                   plans.map((plan) => {
                                                       return (
                                                           <ListItem className={classes.listItem} key={plan.id}

                                                                     secondaryAction={

                                                                         <StoRadioDelete
                                                                             onClickDelete={(event) => openDeleteDialog(event, plan)}
                                                                             radioChecked={plan.id === selectedPlanId && selectedPlanId !== undefined}
                                                                             onClickRadio={handleRadio(plan)}
                                                                         />
                                                                     }>
                                                               <Typography variant={"body1"}
                                                                           className={classes.planname}
                                                                           style={{fontWeight: selectedPlanId === plan.id ? "bold" : null}}>
                                                                   {plan.name ? getPlanName(plan.name) : plan.id}</Typography>
                                                           </ListItem>
                                                       );
                                                   })
                                               }
                                           </List>
                                       </Paper>
                                   </div>
                               }
                           </div>
                       }
                       actions={
                           <div className={classes.actions}>
                               <StoPrimaryButton title={"Create " + type} onClick={handleSubmit}
                                                 disabled={disabled}/>
                               <StoPrimaryButton title={"Cancel"} onClick={handleClose} secondary/>
                           </div>
                       }
            />
            <StoDialog open={deleteOpen} onClose={(event) => closeDeleteDialog(event)}
                       title={""}
                       maxWidth={"36rem"}

                       content={getContent()}
                       actions={deleteDone === 0 &&

                           <div className={classes.dialogActions}>
                               <StoPrimaryButton onClick={() => handleDelete(plan, type)} title={"Delete Plan"}/>
                               <StoPrimaryButton onClick={(event) => closeDeleteDialog(event)} title={"Cancel"}
                                                 secondary/>
                           </div>}/>

        </>);
}


CreateDialog.propTypes = {
    type: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    projectId: PropTypes.string,
    plans: PropTypes.array,
    name: PropTypes.string,
    setName: PropTypes.func,
    initialName: PropTypes.string,
    plan: PropTypes.any,
    setPlan: PropTypes.func,
    onSubmit: PropTypes.func
};
CreateDialog.defaultPropTypes = {};
export default CreateDialog;