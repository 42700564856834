import {makeStyles} from "@mui/styles";
import {Typography} from "@mui/material";
import PropTypes from "prop-types";

const useStyle = makeStyles(theme => ({
    textIcon: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& .MuiOutlinedInput-root': {
            borderRadius: "2px",
            backgroundColor: theme.palette.neutral.white,
            fontSize: '14px',
            marginTop: '0.6rem',
        },
    },
}));


function TextIcon({text, icon, onClick, altText, hideIcon, ...props}) {
    const classes = useStyle(props.theme);

    return (
        <div className={classes.textIcon} onClick={onClick}>
            <Typography >{text} </Typography>
            <img src={icon} alt={altText} hidden={hideIcon}/>
        </div>
    )
}

TextIcon.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.any,
    onClick: PropTypes.func,
    altText: PropTypes.string.isRequired

};
TextIcon.defaultPropTypes = {};
export default TextIcon;