import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import StoRadio from "./StoRadio";
import React from "react";
import TrashIcon from "../icons/TrashIcon";


const useStyle = makeStyles(theme => ({
    StoRadioDelete: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& .MuiOutlinedInput-root': {
            borderRadius: "2px",
            backgroundColor: theme.palette.neutral.white,
            fontSize: '14px',
            marginTop: '0.6rem',
        },
    },
}));


function StoRadioDelete({onClickRadio, radioChecked, onClickDelete, ...props}) {

    const classes = useStyle(props.theme);

    return (

        <div className={classes.StoRadioDelete}>

            <StoRadio edge="end" onChange={onClickRadio}
                      checked={radioChecked}/>

            <IconButton size="large" onClick={onClickDelete} >
                <TrashIcon fontSize="large" />
            </IconButton>

        </div>
    )
}

StoRadioDelete.propTypes = {
    onClickDelete: PropTypes.func,
    onClickRadio: PropTypes.func,
    radioChecked : PropTypes.bool,
};
StoRadioDelete.defaultPropTypes = {};
export default StoRadioDelete;