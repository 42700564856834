import {makeStyles} from "@mui/styles";
import React from "react";

const useStyle = makeStyles(() => ({
    profileCanvas: {
        margin: 0,
    },
    activeSquare: {
        borderColor: '#FFD700'
    },
}));

function MouldingPreview({previewItem, width, height, ...props}) {

    const classes = useStyle(props.theme);

    const [mtPreviewItem] = React.useState(previewItem)

    const drawCanvas = (canvas, topVertices, bottomVertices) => {
        if (!canvas) return;
        const ctx = canvas.getContext('2d');

        if (topVertices.length === 0) return;

        //get the lowest y Value of topVertices
        let yValue = 0;
        for (let i = 0; i < topVertices.length; i++) {
            if (topVertices[i].y < yValue) {
                yValue = topVertices[i].y;
            }
        }
        //get the highest y Value of bottomVertices
        let yValue2 = 0;
        for (let i = 0; i < bottomVertices.length; i++) {
            if (bottomVertices[i].y > yValue2) {
                yValue2 = bottomVertices[i].y;
            }
        }

        let profileHeight = yValue2 - yValue;

        //create a height factor which is the canvas height divided by the profileHeight
        switch (mtPreviewItem.applyProfileMode) {
            case 0:
                drawHeightScalingMouldingProfile(ctx, canvas, topVertices, bottomVertices, 1);
                break;
            default:
                drawHeightScalingMouldingProfile(ctx, canvas, topVertices, bottomVertices, profileHeight);
                break;
        }


    };

    const drawHeightScalingMouldingProfile = (ctx, canvas, topVertices, bottomVertices, profileHeight) => {
        let heightFactor = canvas.height / profileHeight;
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Löscht den Canvas
        ctx.beginPath();
        ctx.moveTo(0, 0);
        for (let i = 0; i < topVertices.length; i++) {
            ctx.lineTo(topVertices[i].x * canvas.width, -topVertices[i].y * heightFactor);
        }


        let reversedBottomVertices = [...bottomVertices].reverse();
            //now add the points of reversedBottomVertices to the path but use as y value the profileHeight - value.y
            for (let i = 0; i < reversedBottomVertices.length; i++) {
                ctx.lineTo(reversedBottomVertices[i].x * canvas.width, (profileHeight - reversedBottomVertices[i].y) * heightFactor);
            }

        ctx.lineTo(0,  profileHeight * heightFactor);

        ctx.fillStyle = "#eeeeee";
        ctx.fill();
        ctx.lineWidth = 2;
        ctx.stroke();
    }

    return (
        <>
            <canvas className={classes.profileCanvas} ref={canvas => drawCanvas(canvas, mtPreviewItem.topPoints, mtPreviewItem.bottomPoints)} width={width} height={height}></canvas>
        </>);
}

export default MouldingPreview;