import {configureStore} from '@reduxjs/toolkit'

import projectsReducer from './projects/projectsSlice'
import unitSystemReducer from './unitSystem/unitSystemSlice'
import clientInfoReducer from './clientInfo/clientInfoSlice'
import settingsReducer from './settings/settingsSlice'
import projectInfoReducer from './projectInfo/projectInfoSlice'
import floorPlanSettingsReducer from './floorPlanSettings/floorPlanSettingsSlice'
import plansReducer from './plan/plansSlice'
import floorsReducer from './floors/floorsSlice'
import floorReducer from './floor/floorSlice'
import stackingReducer from './stacking/stackingSlice'
import elevationsReducer from './elevations/elevationsSlice'
import elevationReducer from './elevation/elevationSlice'
import materialsReducer from './material/materialSlice'
import mouldingTypeReducer from './mouldingType/mouldingTypeSlice'
import lookTypesReducer from './lookType/lookTypeSlice'
import finishTypesReducer from './finishType/finishTypeSlice'
import panelTypesReducer from './panelType/panelTypeSlice'
import pricingReducer from './pricingLevel/pricingSlice'
import pricingSummaryReducer from './pricingSummary/pricingSummarySlice'
import panelizationSettingsReducer from './panelization/panelizationSlice'
import panelizationResultReducer from './panelizationResult/panelizationResultSlice'
import hiddenFacesReducer from './hiddenFaces/hiddenFacesSlice'
import exportReducer from './export/exportSlice'
import bigBlockReducer from './bigBlock/bigBlockSlice'
import colorReducer from './colorPalette/colorPaletteSlice'

import unityReducer from './unity/unitySlice'
import viewSlice from "./view/viewSlice";
import schedulingSettingsSlice from "./scheduling/Settings/schedulingSettingsSlice";
import schedulingPlaningSlice from "./scheduling/planing/schedulingPlaningSlice";

export const store = configureStore({
    reducer: {
        projects: projectsReducer,
        unitSystem: unitSystemReducer,
        clientInfo: clientInfoReducer,
        settings: settingsReducer,
        projectInfo: projectInfoReducer,
        floorPlanSettings: floorPlanSettingsReducer,
        plans: plansReducer,
        floors: floorsReducer,
        floor: floorReducer,
        stacking: stackingReducer,
        elevations: elevationsReducer,
        elevation: elevationReducer,
        mouldingTypeReducer: mouldingTypeReducer,
        materials: materialsReducer,
        lookTypes: lookTypesReducer,
        finishTypes: finishTypesReducer,
        panelTypes: panelTypesReducer,
        pricing: pricingReducer,
        pricingSummary: pricingSummaryReducer,
        panelizationSettings: panelizationSettingsReducer,
        panelizationResult: panelizationResultReducer,
        hiddenFaces: hiddenFacesReducer,
        export: exportReducer,
        bigBlock: bigBlockReducer,
        colors: colorReducer,

        unity: unityReducer,

        view: viewSlice,

        schedulingSettings: schedulingSettingsSlice,
        schedulingPlaning: schedulingPlaningSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})