import {makeStyles} from "@mui/styles";
import React from "react";
import StoInput from "../../utils/components/StoInput";
import {useDispatch} from "react-redux";
import {deletePricingSummaryItem, updatePricingSummaryItem} from "../pricingSummarySlice";
import {useParams} from "react-router-dom";
import {FormControl, MenuItem, Select, Typography} from "@mui/material";
import DeleteIcon from '../../utils/icons/trash.svg'


const useStyle = makeStyles((theme) => ({
    box: {
        display: 'grid',

        gridTemplateColumns: '1fr 1fr 1fr',
        justifyContent: 'center',
        "&.MuiButtonBase-root-MuiAccordionSummary-root": {
            alignItems: 'flex'
        }
    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        justifyContent: 'flex-start',
        borderTop: '1px solid',
        borderRight: '1px solid',
        borderColor: theme.palette.neutral.primary20,
        borderRightColor: theme.palette.neutral.primary20,
        width: '83.5rem',
        padding: '0',
        /*add borderbottom to last element*/
    },
    lastBox: {
        borderColor: theme.palette.neutral.primary20,
        borderBottomColor: theme.palette.neutral.primary20,
        borderTop: '1px solid',
        borderBottom: '1px solid',
    },
    backup: {
        paddingTop: '0.5rem',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: '50px',
        paddingBottom: '1rem'
        // paddingLeft: '7px'
    },
    pricingItemFont: {
        fontSize: '14px !important',
        fontFamily: 'FrutigerNext !important',
        fontWeight: '400 !important',
        display: 'flex',
        alignItems: 'center',
        color: '#767676 !important',

    },
    pricingItemFontBold: {

        fontSize: '14px !important',
        fontFamily: 'FrutigerNext-Bd !important',
        fontWeight: '400 !important',
        display: 'flex',
        alignItems: 'center',
        color: '#767676 !important',

    },
    pricingInput: {
        textAlign: 'right',
        display: 'inline',
        color: '#767676 !important'
    },
    vspacer: {
        width: '1px',
        height: '100%',
        backgroundColor: theme.palette.neutral.primary20,
        margin: '0 0 0 1rem'
    },
    vspacerFat: {
        width: '2px',
        height: '100%',
        backgroundColor: theme.palette.neutral.primary20,
        margin: '0 1rem 0 1rem'
    },
    inlineInput: {
        '& .MuiOutlinedInput-root': {
            borderRadius: "2px",
            backgroundColor: theme.palette.neutral.white,
            fontSize: '13px',
            marginTop: '0 !important',
            width: '8rem',
        },
    },
    icon: {
        width: '2.4rem',
        cursor: 'pointer'
    }

}));

function PricingSummaryCustomElement({item, key, backgroundColor, unitSystem, lastElement, ...props}) {
    const classes = useStyle({...props.theme});
    const dispatch = useDispatch()
    const {projectId} = useParams();

    /*const getUnit = (item) => {
        switch (item.unit) {
            case 'AREA':
                return unitSystem === 'METRIC' ? 'm²' : 'ft²';
            case 'PERCENTAGE':
                return '%';
            default:
                return '';
        }

    }*/

    const updatePricingSummaryItemTitle = (item, value) => {
        const newItem = {...item}
        newItem.name = value
        dispatch(updatePricingSummaryItem({projectId: projectId, pricingSummaryItem: newItem}))
    }

    const updatePricingSummaryItemValue = (item, value) => {
        const newItem = {...item}
        newItem.value = value
        dispatch(updatePricingSummaryItem({projectId: projectId, pricingSummaryItem: newItem}))
    }

    const updatePricingSummaryReferenceItemValue = (item, value) => {
        const newItem = {...item}
        newItem.referenceValue = value
        dispatch(updatePricingSummaryItem({projectId: projectId, pricingSummaryItem: newItem}))
    }

    const updatePricingSummaryItemUnit = (item, value) => {
        const newItem = {...item}
        newItem.customUnit = value
        dispatch(updatePricingSummaryItem({projectId: projectId, pricingSummaryItem: newItem}))
    }

    /*const getFont = (item) => {
        switch (item.method) {
            case 'SALES':
                return classes.pricingItemFontBold;
            default:
                return classes.pricingItemFont;
        }
    }*/

    const getBox = () => {
        if (lastElement) {
            return classes.lastBox;
        }
        return classes.box
    }

    const deleteItem = (itemId) => {
        dispatch(deletePricingSummaryItem({projectId, itemId}))
    }

    function getUnitDropDown() {
        return (<FormControl>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={item.customUnit}
                style={{width: '8rem', textAlign: 'right', marginLeft: '1rem', padding: '0', height: '3.6rem'}}
                onChange={(event) => updatePricingSummaryItemUnit(item, event.target.value)}
            >
                {unitSystem === 'METRICAL' &&
                    <MenuItem value={'m'}>m</MenuItem>

                }
                {unitSystem === 'METRICAL' &&

                    <MenuItem value={'m²'}>m²</MenuItem>
                }
                {unitSystem === 'IMPERIAL' &&
                    <MenuItem value={'lf'}>lf</MenuItem>

                }
                {unitSystem === 'IMPERIAL' &&

                    <MenuItem value={'ft²'}>ft²</MenuItem>
                }
                <MenuItem value={'piece'}>piece</MenuItem>
                <MenuItem value={'panel'}>panel</MenuItem>
                <MenuItem value={'fixed'}>fixed</MenuItem>
            </Select>
        </FormControl>)
    }

    function parseValue(value) {
        return parseFloat(value).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }

    return (
        <div className={`${classes.innerBox} ${getBox(item)}`} style={{backgroundColor: backgroundColor}} key={key}>
            <StoInput title={''} onBlur={(event) => updatePricingSummaryItemTitle(item, event.target.value)}
                      className={classes.inlineInput}
                      value={item.name}
                      style={{width: '18rem', paddingRight: 0, textAlign: 'right', marginLeft: '0'}}/>

            <img alt={'icon'} src={DeleteIcon} onClick={() => deleteItem(item.id)} className={classes.icon}/>
            <div style={{width: '16.5rem'}}/>
            <StoInput title={''} onBlur={(event) => updatePricingSummaryItemValue(item, event.target.value)}
                      className={classes.inlineInput}
                      value={parseValue(item.value)}
                      style={{width: '8rem', paddingRight: 0, textAlign: 'right', marginLeft: '0'}}/>
            {getUnitDropDown()}
            <Typography style={{margin: '0 1rem', fontSize: '2rem'}} className={classes.pricingItemFont}>
                x
            </Typography>
            <StoInput title={''} onBlur={(event) => updatePricingSummaryReferenceItemValue(item, event.target.value)}
                      className={classes.inlineInput}
                      value={parseValue(item.referenceValue)}
                      style={{width: '8rem', paddingRight: 0, textAlign: 'right', marginLeft: '0'}}/>
            <div className={classes.vspacerFat}/>
            <Typography className={`${classes.pricingItemFont} ${classes.pricingInput}`}
                        style={{width: '12rem'}}>
                {parseValue(item.price)} $
            </Typography>
        </div>
    );
}

export default PricingSummaryCustomElement;