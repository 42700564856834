import App from './App'
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import {BrowserRouter, Route, Routes, useNavigate} from 'react-router-dom'
import axios from "axios";
import ProjectPage from './projects/components/ProjectPage'
import ProjectsOverview from './projects/components/ProjectsOverview'
import Export from './export/components/Export'

import './index.css'
import {ThemeProvider} from '@mui/styles'
import theme from './theme'
import {Auth0Provider} from '@auth0/auth0-react'
import {AxiosInterceptorSetup, setupResponseInterceptor} from './utils/axiosUtils'
import ExportElevation from './export/components/ExportElevation'
import {NotificationProvider, useNotification} from "./notificationProvider/notificationProvider";
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));

function AxiosInterceptorNavigate() {
    const navigate = useNavigate();
    AxiosInterceptorSetup(navigate)
    return <></>
}
function AxiosInterceptorNotification() {
    const { showNotification } = useNotification();
    setupResponseInterceptor(showNotification);
    return <></>
}

if (document.querySelectorAll('iframe').length <= 0) {
    console.log("ADDIN IFRAME")
    window.zESettings = {
        webWidget: {
            position: {horizontal: 'right', vertical: 'top'},
            offset: {horizontal: '-8px', vertical: '-8px'},
            authenticate: {
                jwtFn: async function (callback) {
                    const tokenResponse = await axios.get("/api/helpdesk/zendesk");
                    callback(tokenResponse.data);
                }
            }
        }
    };
    const script = document.createElement('script');
    script.src = "https://static.zdassets.com/ekr/snippet.js?key=dd577f59-c03e-4bd9-b59e-1fda2c0b1866";
    script.id = "ze-snippet";
    document.body.appendChild(script);

}

if (process.env.REACT_APP_PROFILE === 'dev') {
    root.render(
        <NotificationProvider>
            <AxiosInterceptorNotification></AxiosInterceptorNotification>


        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <BrowserRouter>

                        <Routes>
                            <Route path="/" element={<App/>}>
                                <Route index element={<ProjectsOverview/>}/>
                                <Route path={':projectId'} element={<ProjectPage/>}/>
                                <Route path={':projectId/export'} element={<Export/>}/>
                                <Route path={':projectId/export/:elevationId'} element={<ExportElevation/>}/>
                            </Route>
                        </Routes>

                </BrowserRouter>
            </ThemeProvider>
        </React.StrictMode>
        </NotificationProvider>
    );
} else if (process.env.REACT_APP_PROFILE === 'testing') {
    root.render(
        <React.StrictMode>

            <ThemeProvider theme={theme}>
                <BrowserRouter>

                        <Auth0Provider domain={process.env.REACT_APP_AUTH0_DOMAIN}
                                       clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                                       audience={process.env.REACT_APP_AUTH0_AUDIENCE}
                                       scope={process.env.REACT_APP_SCOPE}
                                       useRefreshTokens={true}
                                       redirectUri={window.location.origin}>
                            {<AxiosInterceptorNavigate/>}
                            <Routes>
                                <Route path="/" element={<App/>}>
                                    <Route index element={<ProjectsOverview/>}/>
                                    <Route path={':projectId'} element={<ProjectPage/>}/>
                                    <Route path={':projectId/export'} element={<Export/>}/>
                                    <Route path={':projectId/export/:elevationId'} element={<ExportElevation/>}/>
                                </Route>
                            </Routes>
                        </Auth0Provider>

                </BrowserRouter>
            </ThemeProvider>
        </React.StrictMode>
    );

} else if (process.env.REACT_APP_PROFILE === 'prod') {
    root.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <BrowserRouter>

                        <Auth0Provider domain={process.env.REACT_APP_AUTH0_DOMAIN_PROD}
                                       clientId={process.env.REACT_APP_AUTH0_CLIENT_ID_PROD}
                                       audience={process.env.REACT_APP_AUTH0_AUDIENCE_PROD}
                                       scope={process.env.REACT_APP_SCOPE}
                                       useRefreshTokens={true}
                                       redirectUri={window.location.origin}>
                            {<AxiosInterceptorNavigate/>}
                            <Routes>
                                <Route path="/" element={<App/>}>
                                    <Route index element={<ProjectsOverview/>}/>
                                    <Route path={':projectId'} element={<ProjectPage/>}/>
                                    <Route path={':projectId/export'} element={<Export/>}/>
                                    <Route path={':projectId/export/:elevationId'} element={<ExportElevation/>}/>
                                </Route>
                            </Routes>
                        </Auth0Provider>

                </BrowserRouter>
            </ThemeProvider>
        </React.StrictMode>
    );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
