import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@mui/styles";
import StoPrimaryButton from "../../utils/components/StoPrimaryButton";
import StoDialog from "../../utils/components/StoDialog";
import StoTimePicker from "../../utils/components/StoTimePicker";
import {DateTime} from "luxon";
import {FormControl, FormControlLabel, RadioGroup, Tooltip, Typography} from "@mui/material";
import {
    adjustLastMinuteOfDay,
    adjustMidnight,
    getDiffDays,
    getDiffDaysInWorkingDays,
    getExactAmountOfWorkingDay,
    getNextWorkingDayByDuration,
    getNextWorkingDayByDurationHalfDay,
    getPreviousWorkingDayByDuration
} from "../../utils/timeUtils";
import StoStepperInput from "../../utils/components/StoStepperInput";
import StoInput from "../../utils/components/StoInput";
import {getToolTipTitle} from "../../utils/toolTipUtil";
import Radio from "@mui/material/Radio";
import {updateTasksExpandEnd, updateTasksExpandStart} from "./TaskUtils";

const useStyle = makeStyles(() => ({
    root: {},
    radioGroup: {
        display: "flex",
        paddingTop: "0.6rem",
        justifyContent: "space-between",
    },
    dialogActions: {
        height: "8.4rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    content: {
        paddingTop: "1.8rem",
        display: "flex",
        flexDirection: "column",
        gap: ".4rem",

    },
    timePicker: {
        height: "4.8rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    viewType: {
        display: "flex",
        justifyContent: "space-between",
    }
}));

function EditSchedulingPlaningItemDialog({open, setOpen, workPackage, schedulingPlaningType, ...props}) {
    const classes = useStyle(props.theme);

    const [item, setItem] = useState(workPackage)
    const [start, setStart] = useState(null)
    const [teamSize, setTeamSize] = useState(null)
    const [viewType, setViewType] = useState(null)

    useEffect(() => {
        if (workPackage?.type === 'WorkPackage') {
            setViewType(workPackage?.showSubTasks ? 'Floor/Elevation' : 'WorkPackage')
            setItem(workPackage)
            setStart(workPackage?.start)
            setTeamSize(workPackage?.customTeamSize)
            if (workPackage?.type === 'WorkPackage') {
                setViewType(workPackage?.showSubTasks ? 'Floor/Elevation' : 'WorkPackage')
            }

        }
    }, [workPackage, workPackage?.customTeamSize, workPackage?.start, workPackage?.showSubTasks])

    const closeDialog = () => {
        setOpen(false)
    }

    const workingDays = props.workingDays
    // const workHoursPerDay = props.workHoursPerDay

    const name = item?.name || 'Name'

    const date = item ? DateTime.fromJSDate(item.start) : null

    function setStartDate(date) {
        const {year, month, day} = date
        const start = new Date(year + '-' + month + '-' + day)

        setStart(adjustMidnight(start))
    }

    function updateItem() {
        updateStartAndEndDate()
        updateTeamSize()
        updateViewType()
        props.updateItem(item)
        closeDialog()
    }

    function updateViewType() {
        if (viewType) {
            item.showSubTasks = viewType === 'Floor/Elevation'
            item.isCollapsed = viewType === 'Floor/Elevation' ? false : undefined
        }
    }

    function updateStartAndEndDate() {
        if (!start) {
            return
        }

        const diff = getDiffDays(start, item.start)

        if (isNaN(diff) || diff === 0) {
            return
        }

        let diffInWorkingDays = getDiffDaysInWorkingDays(start, item.start, workingDays)

        if (diffInWorkingDays === 0) {
            return
        }

        let optimizedStart = diffInWorkingDays < 0
            ? getPreviousWorkingDayByDuration(start, Math.abs(diffInWorkingDays), workingDays)
            : getNextWorkingDayByDuration(adjustMidnight(item.start), diffInWorkingDays, workingDays)
        let end = diffInWorkingDays < 0
            ? getPreviousWorkingDayByDuration(item.end, Math.abs(diffInWorkingDays), workingDays)
            : getNextWorkingDayByDuration(adjustLastMinuteOfDay(item.end), diffInWorkingDays, workingDays)

        item.start = optimizedStart
        item.end = end
    }

    function updateTeamSize() {
        const teamS = typeof (teamSize) === 'string'
            ? Number(teamSize.replace(',', '.'))
            : teamSize

        if (isNaN(teamS)) {
            return
        }

        const factor = Number(item.customTeamSize) / teamS

        if (factor === 1) {
            return
        }

        item.customTeamSize = teamS

        const duration = getExactAmountOfWorkingDay(item.start, item.end, workingDays) * factor
        item.duration = duration
        item.end = getNextWorkingDayByDurationHalfDay(item.start, duration, workingDays)

        if (schedulingPlaningType === 'START') {
            updateTasksExpandStart(item.subTasks, factor, item.start, workingDays)
        } else {
            updateTasksExpandEnd(item.subTasks, factor, item.end, workingDays)
        }
    }

    function changeShowSubTasks() {
        setViewType(viewType === 'Floor/Elevation' ? 'WorkPackage' : 'Floor/Elevation')
    }

    function getContent() {
        if (!item) {
            return null
        }

        return (<div className={classes.content}>
            {item?.isCustom &&
                <div className={classes.timePicker}>
                    <Typography variant={"button"}>Name</Typography>
                    <StoInput value={item.name} onBlur={(event) => item.name = event.target.value}
                              label={"Name"}/>
                </div>
            }
            <div className={classes.timePicker}>
                <Typography variant={"button"}>Startdate</Typography>
                <StoTimePicker defaultValue={date} onChange={setStartDate} workingDays={workingDays}/>
            </div>
            {!item?.isCustom &&
                <div className={classes.timePicker}>
                    <Typography variant={"button"}>Team Size</Typography>
                    <StoStepperInput defaultValue={teamSize} onBlur={setTeamSize} step={0.5}
                                     minValue={.5}/>
                </div>
            }
            {
                viewType &&
                <div className={classes.viewType}>
                    <Typography variant={"button"}>View Type</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup value={viewType}
                                    onChange={changeShowSubTasks}>
                            <Tooltip
                                title={getToolTipTitle("Work Package", "Show the task as a work package with a single bar in the gantt chart")}
                                arrow={true}
                                placement={"right"}
                                hidden={true}>
                                <FormControlLabel value={"WorkPackage"}
                                                  control={<Radio color="primary"/>}
                                                  label="Work Package"/>
                            </Tooltip>
                            <Tooltip
                                title={getToolTipTitle("Floors & Elevations", "Show the subtasks of the work package in a floors and elevations view")}
                                arrow={true}
                                placement={"right"}
                                hidden={true}>
                                <FormControlLabel value={"Floor/Elevation"}
                                                  control={<Radio color="primary"/>}
                                                  label="Floors & Elevations"/>
                            </Tooltip>
                        </RadioGroup>
                    </FormControl>
                </div>
            }
        </div>)
    }

    function getActions() {
        return (
            <div className={classes.dialogActions}>
                <StoPrimaryButton title={"Apply"} onClick={updateItem}/>
                <StoPrimaryButton title={"Cancel"} onClick={closeDialog} secondary/>
            </div>
        )
    }

    return (
        <StoDialog open={open} title={name} onClose={closeDialog} maxWidth={"32rem"}
                   content={getContent()}
                   actions={getActions()}/>
    );
}

EditSchedulingPlaningItemDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};
EditSchedulingPlaningItemDialog.defaultPropTypes = {};
export default EditSchedulingPlaningItemDialog;