import React from 'react'
import {getArea, selectText} from './Helpers'
import './export.css'


export const ExportMaterials = (props) => {

    const exportData = props.data
    const materials = props.materials
    const unitSystem = props.unitSystem




    return (
        <div>
            <table id="materialTable" className={"export-table"}>
                <tbody>
                <tr>
                    <td colSpan="2" className="infoblockTitle copytable"  onMouseDown={() => selectText("materialTable")}>
                        Materials
                    </td>
                </tr>
                {
                    exportData.map(
                        function(material, i) {
                            if (material.area > 0)
                                return (
                                    <tr key={i}>
                                        <td className="tlabel">{materials[material.materialId].name}</td>
                                        <td className="tcontent">{getArea(material.area, unitSystem)}</td>
                                    </tr>
                                )
                            else return (null)
                        }
                    )
                }
                </tbody>
            </table>
        </div>
    );
};