import {IconButton, Snackbar, SnackbarContent} from "@mui/material";
import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {makeStyles} from "@mui/styles";
import CloseIcon from '../icons/close.svg'
import SaveDoneIcon from '../icons/save-done.svg'
import CircularProgressWithContent from "./CircularProgressWithContent";

const useStyle = makeStyles(theme => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    snackbar: {
        borderRadius: '2px',
        border: '1px solid #FFC637',
        fontSize: '14px',
        fontWeight: '700',
        width: '540px',
        height: '52px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.neutral.black,
        display: 'block',
        textAlign: 'center',
    },
    icon: {
        border: 'none',
        width: '28px',
        height: '28px',
    },
}));

//eslint-disable-next-line
function StoSnackbar({message, open, vertical, horizontal, duration, onClose, icon = SaveDoneIcon, ...props}) {

    const classes = useStyle({...props.theme});
    const content = (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                height: '28px'
            }}>
                {icon && <img src={icon} alt={'save'} className={classes.icon}/>}
                <p style={{paddingLeft: '2rem'}}>{message}</p>
            </div>
            <CircularProgressWithContent content={<IconButton size="small" onClick={onClose}>
                <img src={CloseIcon} alt={'close'} className={classes.icon}/>
            </IconButton>}/>
        </div>
    )

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                onClose()
            }, duration)
        }
    }, [open, duration, onClose])


    return (
        <div>
            <Snackbar
                anchorOrigin={{vertical, horizontal}}
                autoHideDuration={duration}
                open={open}
                onClose={onClose}
                key={vertical + horizontal}>
                <SnackbarContent
                    message={content}
                    style={{
                        borderRadius: '2px',
                        border: '1px solid #FFC637',
                        fontSize: '14px',
                        fontWeight: '700',
                        width: '540px',
                        height: '52px',
                        backgroundColor: '#ffd700',
                        color: '#000000',
                        display: 'block',
                        textAlign: 'center',
                    }}/>

            </Snackbar>
        </div>
    )
}

StoSnackbar.propTypes = {
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    duration: PropTypes.number,
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
    showIcon: PropTypes.bool
};

StoSnackbar.defaultProps = {
    duration: 3000,
    horizontal: 'center',
    vertical: 'top',
};
export default StoSnackbar;