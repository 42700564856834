import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {PRICING_LEVEL} from "../pricingSlice";
import PricingLevel1 from "./pricingLevel1";
import {useSelector} from "react-redux";
import PricingSidebar from "./PricingSidebar";
import PricingLevel2And3 from "./pricingLevel2_3";

const useStyle = makeStyles(() => ({
    levelButton: {
        width: '125px',
        height: "inherit",
        borderRadius: '2px'
    },
    buttonGroup: {
        height: '25px',
        borderRadius: '2px',
        marginLeft: '10rem',
        outline: '1px solid lightgrey',
        outlineOffset: '8px',
    },
    pricingSelector: {
        float: 'left',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '280px',
        background: 'white',
        boxSizing: 'border-box',
        paddingTop: '1rem',
        borderRight: '1px solid lightgrey',
        bottom: '0',
        top: '0',
        position: 'absolute',

    },
    pricingRoot: {

        boxSizing: 'border-box',
        display: 'flex',
        height: '100%',

    },
    pricingCategoryFont: {
        fontFamily: 'FrutigerNext-Bd',
        fontSize: '16px !important',
        margin: '3rem 0'
    },
    radioButton: {
        margin: '1rem 0',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        width: '100%',
        fontFamily: 'FrutigerNext',
        fontSize: '16px',
    },
    radioGroup: {
        width: 'auto',
        paddingLeft: '4rem',
        borderBottom: '1px solid lightgrey',
        paddingBottom: '1rem',
    },
    contentArea: {
        overflowY: "auto",
        width: "calc(100% - 30rem)",
        boxSizing: "border-box",

        paddingTop: '3.6rem',
        paddingLeft: '3.2rem',
        paddingRight: '3.2rem',
    },
    formControl: {
        width: '100%',

    },

}))

function PricingLevel(props) {
    const classes = useStyle(props.theme);
    const pricingLevel = useSelector(state => state.settings.settings.pricingLevel)
    const pricing = useSelector(state => state.pricing)

    const [scrollToItem, setScrollToItem] = useState(null);

    const [pricingLevel2Categories, setPricingLevel2Categories] = useState([])
    const [pricingLevel3Categories, setPricingLevel3Categories] = useState([])
    const [pricingCategories, setPricingCategories] = useState([]);


    useEffect(() => {
        if (pricing.singleUpdate && pricingLevel2Categories.length !== 0 && pricingLevel3Categories.length !== 0) {
            return
        }

        setPricingLevel2Categories(pricing.pricingCategoriesLevel2)
        setPricingLevel3Categories(pricing.pricingCategoriesLevel3)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricing, pricingLevel2Categories.length, pricingLevel3Categories.length])

    useEffect(() => {
        console.log('Pricing level: ', pricingLevel)
        setPricingCategories(pricingLevel === PRICING_LEVEL.LEVEL_2 ? pricingLevel2Categories : pricingLevel3Categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingLevel, pricing, pricingLevel2Categories, pricingLevel3Categories]);

    function getContent() {
        switch (pricingLevel) {
            case PRICING_LEVEL.LEVEL_1:
                return <PricingLevel1/>;
            case PRICING_LEVEL.LEVEL_2:
            case PRICING_LEVEL.LEVEL_3:
                return <PricingLevel2And3 scrollToItem={scrollToItem} pricingLevel={pricingLevel}
                                          pricingCategories={pricingCategories}/>;
            default:
                return null;
        }
    }

    const content = getContent();

    return (
        <div className={classes.pricingRoot}>
            <PricingSidebar setScrollToItem={setScrollToItem} pricingCategories={pricingCategories}/>
            <div className={classes.contentArea} style={{background: 'white'}}>
                <div style={{display: 'flex', whiteSpace: 'nowrap'}}>
                    {content}
                </div>
            </div>
        </div>
    );
}

export default PricingLevel