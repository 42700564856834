import {useSelector} from "react-redux";
import {viewTypes} from "../view/viewSlice";
import {makeStyles} from "@mui/styles";

const useStyle = makeStyles(theme => ({
    root: {
        height: 'calc(100vh - 100px - 200px - 65px)',
        position: 'absolute',
        top: '301px',
        right: '0',
        padding: '0',
        overflowY: 'clip',
        borderTop: '1px solid #e0e0e0',
        borderLeft: '1px solid #e0e0e0',
        borderBottom: '1px solid #e0e0e0',
        "&::-webkit-scrollbar": {
            margin: '1rem',
            width: "1rem"
        },
        "&::-webkit-scrollbar-thumb": {
            background: theme.palette.primary.main,
        },
    },
    content: {
        background: 'white',
        width: '100%',
        height: '100%',
        textAlign: 'left',
        boxSizing: 'border-box',
        overflowY: 'auto',
        "&::after": {
            minWidth: "1rem",
            height: "10rem",
            content: '""arn star'
        }
    },
}))

function UnitySidebarOverlay(props) {
    const classes = useStyle(props.theme);

    const view = useSelector(state => state.view.currentView)

    function getContent() {
        switch (view) {
            case viewTypes.IndividualSettings: {
                return null
                //return <IndividualSettings/>
            }
            default:
                return null
        }
    }

    const content = getContent()
    const width = 35.9

    return content ?
        <div className={classes.root} style={{width: width + 'rem'}}>
            <div className={classes.content}>
                {content}
            </div>
        </div> : null
}

export default UnitySidebarOverlay;