import axios from 'axios'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setMaterials} from '../unity/react-unity-adapter'

export const getMaterials = createAsyncThunk('material/get', async ({projectId}) => {
    try {
        console.log('Get materials: ', projectId)
        return await axios.get(`/api/projects/${projectId}/material`)
    } catch (err) {
        return err.message
    }
})

export const updateMaterialsFromCore = createAsyncThunk('material/coreupdate', async ({projectId}) => {
    try {
        console.log('Update materials from core: ', projectId)
        return await axios.get(`/api/projects/${projectId}/material/coreUpdate`)
    } catch (err) {
        return err.message
    }
})

export const createMaterial = createAsyncThunk('material/create', async ({projectId}) => {
    try {
        console.log('Create stack: ', projectId)
        return await axios.post(`/api/projects/${projectId}/material`)
    } catch (err) {
        return err.message
    }
})

export const updateMaterial = createAsyncThunk('material/update', async ({projectId, material}) => {
    try {
        console.log('Update material: ', projectId)
        return await axios.put(`/api/projects/${projectId}/material`, material)
    } catch (err) {
        return err.message
    }
})

export const resetMaterials = createAsyncThunk('material/reset', async ({projectId}) => {
    try {
        console.log('Reset materials: ', projectId)
        return await axios.put(`/api/projects/${projectId}/material/reset`)
    } catch (err) {
        return err.message
    }
})

export const deleteMaterial = createAsyncThunk('material/delete', async ({projectId, materialId}) => {
    try {
        console.log('Delete material: ', projectId, materialId)
        const result = await axios.delete(`/api/projects/${projectId}/material/${materialId}`)

        return result
    } catch (err) {
        return err.message
    }
})

const materialSlice = createSlice({
    name: 'material',
    initialState: {
        materials: [],
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getMaterials.pending, (state) => {
                state.loading = true
            })
            .addCase(getMaterials.fulfilled, (state, {payload}) => {
                if (payload.data === undefined) state.materials = []
                else state.materials = payload.data
                state.loading = false
                setMaterials(state.materials)
            })
            .addCase(getMaterials.rejected, (state) => {
                state.loading = false
            })

            .addCase(updateMaterialsFromCore.pending, (state) => {
                state.loading = true
            })

            .addCase(updateMaterialsFromCore.fulfilled, (state, {payload}) => {
                if (payload.data === undefined) state.materials = []
                else state.materials = payload.data
                state.loading = false
                setMaterials(state.materials)
            })
            .addCase(updateMaterialsFromCore.rejected, (state) => {
                state.loading = false
            })

            .addCase(createMaterial.pending, (state) => {
                state.loading = true
            })
            .addCase(createMaterial.fulfilled, (state, {payload}) => {
                const {data: material} = payload
                if (state.materials === undefined) {
                    state.materials = [material]
                } else {
                    state.materials = state.materials.concat([material])
                }
                setMaterials(state.materials)
            })
            .addCase(createMaterial.rejected, (state) => {
                state.loading = false
            })

            .addCase(updateMaterial.pending, (state) => {
                state.loading = true
            })
            .addCase(updateMaterial.fulfilled, (state, {payload}) => {
                const {data: material} = payload
                state.materials = state.materials.map(m => m.id === material.id ? material : m)
                state.loading = false
                setMaterials(state.materials)
            })
            .addCase(updateMaterial.rejected, (state) => {
                state.loading = false
            })

            .addCase(deleteMaterial.pending, (state) => {
                state.loading = true
            })
            .addCase(deleteMaterial.fulfilled, (state) => {
                state.loading = false
            })
            .addCase(deleteMaterial.rejected, (state) => {
                state.loading = false
            })

            .addCase(resetMaterials.pending, (state) => {
                state.loading = true
            })
            .addCase(resetMaterials.fulfilled, (state, {payload}) => {
                state.loading = false
                state.materials = payload
            })
            .addCase(resetMaterials.rejected, (state) => {
                state.loading = false
            })
    }
})

export default materialSlice.reducer