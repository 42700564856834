import React from "react";
import {SortableHandle} from "react-sortable-hoc";
import {makeStyles} from "@mui/styles";
import {Typography} from "@mui/material";

import HandleSortIcon from "../../utils/icons/handleSort.svg";
import RenameIcon from "../../utils/icons/rename.svg";
import DeleteIcon from "../../utils/icons/trash.svg";
import StoInput from "../../utils/components/StoInput";
import StoStepperInput from "../../utils/components/StoStepperInput";
import StoSwitchButton from "../../utils/components/StoSwitchButton";
import {
    getCalculationUnitTime,
    getCalculationUnitTimes,
    translateCalculationUnitTime,
    translateCalculationUnitTimes
} from "../CalculationType";

const useStyle = makeStyles(() => ({
    item: {
        height: "4.8rem",
        background: "white",

        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",

        paddingLeft: ".8rem",
        paddingRight: ".8rem",

        "&:hover": {
            backgroundColor: "#F4F6F7",
        }
    },
    content: {
        display: "flex",
        alignItems: "center",
        gap: "2.4rem",
        paddingLeft: "3rem",
    },
    name: {
        display: "flex",
        alignItems: "center",
    },
    iconGroup: {
        display: "flex",
        alignItems: "center",
        gap: ".8rem",
    },
    icon: {
        width: "2.4rem",
    },
}))

const RowHandler = SortableHandle((props) =>
    <img alt={'icon'} src={HandleSortIcon} className={props.className}/>);

const WorkPackageRow = ({
                            item,
                            renameItem,
                            editItem,
                            removeItem,
                            updateTeamSize,
                            updateCalculationUnit
                        }) => {
    const classes = useStyle()

    const [showActions, setShowActions] = React.useState(false)

    const showGanttChartViewType = false
    const showTimeUnit = false

    return (
        <div className={classes.item}
             onMouseEnter={_ => setShowActions(true)}
             onMouseLeave={_ => setShowActions(false)}>
            <div className={classes.content}>
                <RowHandler className={classes.icon}/>
                {!item.rename ?
                    <Typography className={classes.name} variant={"subtitle1"}
                                onDoubleClick={() => editItem(item.id)}>{item.name}</Typography> :
                    <StoInput className={classes.name} title={item.name} value={item.name} id={item.id} autoFocus={true}
                              onBlur={(event) => renameItem(item.id, event.target.value, item.name)}
                              invokeNoChanges={true}/>}
            </div>
            <div className={classes.content}>
                {showGanttChartViewType &&
                    <StoSwitchButton
                        activeOption={['framing', 'finish tickets', 'installation tickets', 'sheathing', 'gold coat', 'basecoat', 'top finish', 'installation'].includes(item.name.toLowerCase()) ? 'Floor/Elevation' : 'Work Package'}
                        options={['Work Package', 'Floor/Elevation']}
                        onChange={(option) => console.log(option)}/>
                }
                {showTimeUnit &&
                    <StoSwitchButton activeOption={translateCalculationUnitTime(item.calculationUnit)}
                                     options={translateCalculationUnitTimes(getCalculationUnitTimes())}
                                     onChange={(option) => updateCalculationUnit(item.id, getCalculationUnitTime(option))}/>
                }
                <StoStepperInput defaultValue={item.teamSize} id={item.id} step={1} digits={0}
                                 onBlur={(value) => updateTeamSize(item.id, value)}/>
                {showActions ?
                    <div className={classes.iconGroup}>
                        <img className={classes.icon} alt={'icon'} src={RenameIcon} onClick={() => editItem(item.id)}/>
                        <img className={classes.icon} alt={'icon'} src={DeleteIcon}
                             onClick={() => removeItem(item.id)}/>
                    </div>
                    : <div style={{width: "5.6rem"}}/>
                }
            </div>
        </div>
    )
};

export default WorkPackageRow
