

function arrayMoveMutate(array, from, to)
{
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
}

export function arrayMove(array, from, to) {
    array = array.slice();
    arrayMoveMutate(array, from, to);
    return array;
}