import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getSchedulingSettings, updateSchedulingSettings} from "./schedulingSettingsSlice";
import StoRoundCheckboxGroup from "../../utils/components/StoRoundCheckboxGroup";
import StoStepperInput from "../../utils/components/StoStepperInput";
import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useEffect, useState} from "react";

const useStyle = makeStyles(() => ({
    workingCapacity: {
        display: "flex",
        flexDirection: "column",

        maxWidth: "46rem",
    },

    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "4.8rem",
    }
}))

function WorkingCapacity() {
    const classes = useStyle()

    const dispatch = useDispatch();
    const {projectId} = useParams();

    const { schedulingSettings, ignoreUpdate } = useSelector(state => state.schedulingSettings)

    const [selectedDays, setSelectedDays] = useState(schedulingSettings.selectedDays)
    const [workHoursPerDay, setWorkHoursPerDay] = useState(-1)

    useEffect(() => {
        if (!schedulingSettings) {
            dispatch(getSchedulingSettings({projectId: projectId}))
        }
    }, [dispatch, projectId, schedulingSettings])


    useEffect(() => {
        if (!ignoreUpdate) {
            setSelectedDays(schedulingSettings.selectedDays)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedulingSettings?.selectedDays])

    useEffect(() => {
        if (workHoursPerDay < 0) {
            setWorkHoursPerDay(Number(schedulingSettings?.workHoursPerDay?.toFixed(2)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedulingSettings?.workHoursPerDay])


    function updateSelectedDays(options) {
        setSelectedDays(options)
        updateWorkCapacity("selectedDays", options)
    }

    function updateWorkHoursPerDay(value) {
        setWorkHoursPerDay(value)
        updateWorkCapacity("workHoursPerDay", value)
    }

    function updateWorkCapacity(option, value) {
        const schedulingSettings = {}
        schedulingSettings["selectedDays"] = selectedDays
        schedulingSettings["workHoursPerDay"] = workHoursPerDay
        schedulingSettings[option] = value

        dispatch(updateSchedulingSettings(
            {
                projectId: projectId,
                schedulingSettings: schedulingSettings
            }))
    }

    const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
    const showWorkingHours = false

    return <div className={classes.workingCapacity}>
        <Typography variant={'h6'}>General Settings</Typography>
        <Typography variant={'h1'}>Working Capacity</Typography>

        <div className={classes.row} style={{paddingTop: "2rem"}}>
            <Typography variant={'h6'}>Working days per week</Typography>
            <StoRoundCheckboxGroup
                options={weekDays}
                initialOptions={selectedDays}
                onChange={updateSelectedDays}
            />
        </div>

        {showWorkingHours &&
            <div className={classes.row}>
                <Typography variant={'h6'}>Working hours per day</Typography>
                <StoStepperInput defaultValue={workHoursPerDay}
                                 onBlur={updateWorkHoursPerDay}/>
            </div>
        }

    </div>
}

export default WorkingCapacity