import React from "react";
import {makeStyles} from "@mui/styles";
import SchedulingSidebar from "./SchedulingSidebar";

import GanttChart from "./GanttChart/GanttChart";

const useStyle = makeStyles(() => ({
    body: {
        overflow: "hidden",
        height: "100%",
    },
    scheduling: {
        display: "flex",
        height: "100%",
    },
    content: {
        overflowY: "auto",
        width: "calc(100% - 30rem)",
        boxSizing: "border-box",
    },
}));

function Scheduling() {
    const classes = useStyle();

    /*
    function addWorkPackage(phaseId) {
        const phase = phases.find(p => p.id === phaseId)

        if (!phase) {
            return
        }

        const newWorkPackage = {
            id: v4(),
            name: "",
            rename: true,
            newItem: true
        }

        phase.workPackages.push(newWorkPackage)
        setPhases(phases)
    }
    */



    return (
        <div className={classes.body}>
            <div className={classes.scheduling}>
                <SchedulingSidebar/>
                <div className={classes.content}>
                    <GanttChart/>
                </div>
            </div>
        </div>
    )
}

export default Scheduling