import React, {Fragment} from 'react';

import StoDialog from "../../utils/components/StoDialog";
import {Typography} from "@mui/material";
import {useSelector} from "react-redux";

function ExportInfoDialog() {
    const isExportInfoOpen = useSelector(state => state.unity.isExportInfoOpen);

    const getContent = () => {
        return (
            <div style={{maxWidth: '36rem'}}>
                <Typography variant={'h6'} style={{paddingTop: '2.4rem'}}>
                    Please wait while Screenshots are uploaded.
                </Typography>
                <Typography variant={'h6'}>
                    This may take some time....
                </Typography>
            </div>)
    }

    return (
        <Fragment>
            <StoDialog open={isExportInfoOpen}
                       closeHidden={true}
                       maxWidth={"36rem"}
                       title={"Export"}
                       content={getContent()}
            />
        </Fragment>
    );
}

export default ExportInfoDialog;