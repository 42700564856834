

export function translateWeekDays(weekDays) {
    return weekDays.map(weekDay => translateWeekDay(weekDay))
}

function translateWeekDay(weekDay) {
    switch (weekDay.toLowerCase()) {
        case 'mo':
            return 'Monday'
        case 'tu':
            return 'Tuesday'
        case 'we':
            return 'Wednesday'
        case 'th':
            return 'Thursday'
        case 'fr':
            return 'Friday'
        case 'sa':
            return 'Saturday'
        case 'su':
            return 'Sunday'
        default:
            return null
    }
}