import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import SchedulingSettingSidebar from "./SchedulingSettingSidebar";
import Phases from "./Phases";
import WorkingCapacity from "./WorkingCapacity";
import SchedulingPanelTypeSettings from "../SchedulingPanelTypeSettings";

const useStyle = makeStyles(() => ({
    body: {
        overflow: "hidden",
        height: "100%",
    },
    scheduling: {
        display: "flex",
        height: "100%",
    },
    content: {
        overflowY: "auto",
        width: "calc(100% - 30rem)",
        boxSizing: "border-box",

        paddingTop: '3.6rem',
        paddingLeft: '3.2rem',
        paddingRight: '3.2rem',
    },
}));


function SchedulingSettings() {
    const classes = useStyle();

    const [sideBarItem, setSideBarItem] = useState("Working Capacity");

    function getContent() {
        if (sideBarItem === "Phases") {
            return <Phases/>
        } else if (sideBarItem === "Working Capacity") {
            return <WorkingCapacity/>
        } else {
            return <SchedulingPanelTypeSettings currentPanelType={sideBarItem}/>
        }
    }

    return (
        <div className={classes.body}>
            <div className={classes.scheduling}>
                <SchedulingSettingSidebar setSidebarItem={setSideBarItem} sidebarItem={sideBarItem}/>
                <div className={classes.content}>
                    {getContent()}
                </div>
            </div>
        </div>
    )
}

export default SchedulingSettings