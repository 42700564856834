import axios from "axios";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setLookTypes} from "../unity/react-unity-adapter";

export const getLookTypes = createAsyncThunk('lookTypes/get', async () => {
    try {
        console.log("Get all look types")
        return await axios.get(`/api/look-types`)
    } catch (err) {
        return err.message
    }
})

const lookTypeSlice = createSlice({
    name: 'lookTypes',
    initialState: {
        lookTypes: [],
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getLookTypes.pending, (state) => {
                state.loading = true
            })
            .addCase(getLookTypes.fulfilled, (state, action) => {
                state.loading = false
                state.lookTypes = action.payload.data
                setLookTypes(state.lookTypes)
            })
            .addCase(getLookTypes.rejected, (state) => {
                state.loading = false
            })

    }
})

export default lookTypeSlice.reducer
