import {createSlice} from "@reduxjs/toolkit";

export const viewTypes = {
    None: 'None',
    ClientInfo: 'ClientInfo',
    ProjectInfo: 'ProjectInfo',
    SettingsPanelTypes: 'SettingsPanelTypes',
    PricingLevel: 'PricingLevel',
    Dimensions: 'Dimensions',
    PanelSettings: 'PanelSizes',
    SchedulingSettings: 'SchedulingSettings',
    Stacking: 'Stacking',
    IndividualSettings: 'IndividualSettings',
    SchedulingPlanning: 'SchedulingPlanning',
    PricingSummary: 'PricingSummary',
    CsvExportSetup: 'CsvExportSetup',
}

/*
        None,
        ClientInfo,
        ProjectInfo,
        Settings,
        Dimensions,
        SettingsPanelTypes,
        PricingLevel,
        FloorPlan,
        Stacking,
        Elevation,
        Cropping,
        Projection,
        Areas,
        Mouldings,
        Materials,
        ArchitecturalLines,
        Opening,
        Panels,
        SuperPanels,
        GlobalSettings,
        IndividualSettings,
        PanelTypesVariety,
        FinishingTop,
        Finishing,
        HiddenFaces,
        PricingSummary
 */

export const view = createSlice({
    name: 'view',
    initialState: {
        currentView: viewTypes.None,
        loading: false,
    },
    reducers: {
        setCurrentView: (state, action) => {
            state.currentView = action.payload
        }
    },
})

export const {setCurrentView} = view.actions
export default view.reducer