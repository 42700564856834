import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getStacking, updateStacking} from "./stackingSlice";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import Stack from "./Stack";
import {arrayMove} from "../utils/arrayUtils";

import PlusIcon from "../utils/icons/plus.svg"

import {v4} from "uuid";
import {Typography} from "@mui/material";

const useStyle = makeStyles(() => ({
    body: {
        overflow: "hidden",
        height: "100%",
    },
    content: {
        width: "max-content",

        overflowY: "auto",
        boxSizing: "border-box",

        paddingBottom: '3.6rem',
    },
    header: {
        display: "flex",
        alignItems: "center",
        height: "2.4rem",
        gap: '.8rem',
        paddingTop: '1.6rem',
        paddingLeft: '4rem',
    },
    tbody: {
        display: "block",
        width: "100%",
        background: "white"
    },
    addStack: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        paddingRight: '.8rem',
        paddingLeft: '.8rem',
        height: '3.6rem',

        "&:hover": {
            background: "#f2f2f2"
        }
    },
    icon: {
        width: "2.4rem",
        height: "2.4rem",
    }
}));

const SortableCont = SortableContainer(({children}) => {
    const classes = useStyle();

    return <div className={classes.tbody}>{children}</div>;
});

const StackWrapper = SortableElement(props => <Stack {...props}/>)

function Stacking() {
    const classes = useStyle();

    const stackingFetch = useSelector(state => state.stacking.stacking);
    const {buildingDimensions} = useSelector(state => state.settings.settings)

    const floors = useSelector(state => state.floors.floors) || []

    const dispatch = useDispatch()
    const {projectId} = useParams()

    const [stacking, setStacking] = useState([])

    useEffect(() => {
        if (stacking.length === 0) {
            dispatch(getStacking({projectId: projectId}))
        } else {
            return
        }

        let newStacking = JSON.parse(JSON.stringify(stackingFetch))
        newStacking.sort((a, b) => a.fromLevel - b.fromLevel)

        setStacking(setFromToLevels(newStacking))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stackingFetch, dispatch, projectId])

    function onSortEnd({oldIndex, newIndex}) {
        let newStacking = JSON.parse(JSON.stringify(stacking))
        newStacking = arrayMove(newStacking, oldIndex, newIndex)
        newStacking = setFromToLevels(newStacking)

        updateStackingInBackend(newStacking)
    }

    function setFromToLevels(stacking) {
        let currentFloorCount = 1

        stacking.forEach((stack) => {
            stack.fromLevel = currentFloorCount
            stack.toLevel = currentFloorCount + stack.floorsCount - 1
            currentFloorCount += stack.floorsCount
        })

        return stacking
    }

    function onChangeValues(stack) {
        const currentIndex = stacking.findIndex(s => s.id === stack.id)

        let newStacking = JSON.parse(JSON.stringify(stacking))

        newStacking[currentIndex] = stack

        updateStackingInBackend(newStacking)
    }

    function onChangeFloorCount(stack, floorsCount) {

        const newStacking = JSON.parse(JSON.stringify(stacking))

        const newStack = newStacking.find(s => s.id === stack.id)

        if (!newStack) {
            return
        }

        newStack.floorsCount = floorsCount

        const resortedStacking = JSON.parse(JSON.stringify(setFromToLevels(newStacking)))

        updateStackingInBackend(resortedStacking)
    }

    function onDuplicateStack(stack) {
        const currentIndex = stacking.findIndex(s => s.id === stack.id)

        let newStacking = JSON.parse(JSON.stringify(stacking))

        stack.id = v4()

        newStacking.splice(currentIndex, 0, stack)

        newStacking = setFromToLevels(newStacking)

        updateStackingInBackend(newStacking)
    }

    function onDeleteStack(stack) {
        let newStacking = JSON.parse(JSON.stringify(stacking.filter(s => s.id !== stack.id)))

        newStacking = setFromToLevels(newStacking)

        updateStackingInBackend(newStacking)
    }

    function onResetStack(stack) {
        const currentIndex = stacking.findIndex(s => s.id === stack.id)

        let newStacking = JSON.parse(JSON.stringify(stacking))

        newStacking[currentIndex].floorHeight = buildingDimensions.defaultFloorHeight
        newStacking[currentIndex].finishedFloorThickness = buildingDimensions.defaultFinishedFloorThickness
        newStacking[currentIndex].structuralSlabThickness = buildingDimensions.defaultStructuralSlabThickness
        newStacking[currentIndex].parapetHeight = buildingDimensions.parapetHeight

        newStacking = setFromToLevels(newStacking)

        updateStackingInBackend(newStacking)
    }

    function addStack() {
        const newStack = {
            id: v4(),
            floorsCount: 1,
            floorHeight: buildingDimensions.defaultFloorHeight,
            finishedFloorThickness: buildingDimensions.defaultFinishedFloorThickness,
            structuralSlabThickness: buildingDimensions.defaultStructuralSlabThickness,
            parapetHeight: buildingDimensions.parapetHeight,
        }

        let newStacking = JSON.parse(JSON.stringify(stacking))
        newStacking.push(newStack)

        newStacking = setFromToLevels(newStacking)

        updateStackingInBackend(newStacking)
    }

    function updateStackingInBackend(stacking) {
        dispatch(updateStacking({projectId: projectId, stacking: stacking}))
        setStacking(stacking)
    }

    return (
        <div className={classes.content}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant={'h1'}>Stacking Overview</Typography>
                <div className={classes.addStack} onClick={addStack}>
                    <img src={PlusIcon} alt="icon" className={classes.icon}/>
                    <Typography variant="body1" style={{marginLeft: ".8rem"}}>Add Stack</Typography>
                </div>
            </div>
            <div className={classes.header}>
                <Typography variant={'labelRegular'} style={{width: '20rem'}}>Floor Plan</Typography>
                <div style={{width:'.8rem'}}/>
                <Typography variant={'labelRegular'} style={{width: '11.8rem'}} align={'center'}>Floors</Typography>
                <Typography variant={'labelRegular'} style={{width: '3rem'}} align={'center'}>From</Typography>
                <Typography variant={'labelRegular'} style={{width: '3rem'}} align={'center'}>To</Typography>
                <div style={{width:'.8rem'}}/>
                <Typography variant={'labelRegular'} style={{width: '10rem'}}>Floor Height</Typography>
                <Typography variant={'labelRegular'} style={{width: '10rem'}}>SST</Typography>
                <Typography variant={'labelRegular'} style={{width: '10rem'}}>FFT</Typography>
                <Typography variant={'labelRegular'} style={{width: '10rem'}}>Parapet Height</Typography>
                <div style={{width:'.8rem'}}/>
                <Typography variant={'labelRegular'} style={{width: '10rem'}}>Actions</Typography>
            </div>

            <div>
                <SortableCont
                    onSortEnd={onSortEnd}
                    axis="y"
                    lockAxis="y"
                    lockToContainerEdges={true}
                    lockOffset={["30%", "50%"]}
                    helperClass="sortableHelper"
                    useDragHandle>
                    {stacking.map((item, index) => {
                        return (<StackWrapper key={index}
                                              index={index}
                                              stack={item}
                                              orderIndex={index}
                                              floors={floors}
                                              onChangeValues={onChangeValues}
                                              onResetStack={onResetStack}
                                              onChangeFloorsCount={onChangeFloorCount}
                                              onDuplicateStack={onDuplicateStack}
                                              onDeleteStack={onDeleteStack}/>)
                    })}
                </SortableCont>
            </div>
        </div>
    )
}

export default Stacking