import axios from "axios";
import {useAuth0} from "@auth0/auth0-react";

export const setupResponseInterceptor = (showNotification) => {

    axios.interceptors.response.use(
        (response) => {
            if (response.data &&
                Object.prototype.hasOwnProperty.call(response.data, 'message')) {

                const {title, type, message} = response.data.message
                if (!response.data.success) {
                    showNotification(title, type, message);
                }

                response.data = response.data.data;
            }


            return response;
        },
        (error) => {
            console.error("axios response error:", error);
            return Promise.reject(error);
        }
    );
};
export const AxiosInterceptorSetup = (_unavigate) => {
    const {getAccessTokenSilently} = useAuth0();

    axios.interceptors.request.use(
        async config => {
            const accessToken = await getAccessTokenSilently();
            config.headers['AUTHORIZATION'] = 'Bearer ' + accessToken;
            return config
        }, error => {
            //TODO add redirect to login page
            console.log("axios error: " + error)
        }
    )
}