
export function getArea(value, unitSystem) {
     var suffix = unitSystem === "METRICAL" ? " m²" : " sqft"
    var multi = 1; //unitSystem === "METRICAL" ? 1 : 10.7636
    return nicerNumber(value*multi, 2)+suffix
}

export function getAreaConverted(value, unitSystem) {
    var suffix = unitSystem === "METRICAL" ? " m²" : " sqft"
    var multi = unitSystem === "METRICAL" ? 1 : 10.7636
    return nicerNumber(value*multi, 2)+suffix
}

export function getLength(value, unitSystem) {
    var suffix = unitSystem === "METRICAL" ? " m" : " lft"
    var multi = unitSystem === "METRICAL" ? 1 : 1
    //var multi = unitSystem === "METRICAL" ? 1 : 3.2808
    return nicerNumber(value*multi, 2)+suffix
}

export function nicerNumber(value, digits) {

        //return Number(value).toFixed(digits).replace(/\.?0*$/,'').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return Number(value).toFixed(digits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}


export function selectText(id) {
    var sel, range;
    var el = document.getElementById(id); //get element id
    if (window.getSelection && document.createRange) { //Browser compatibility
        sel = window.getSelection();
        if (sel.toString() === '') { //no text selection
            window.setTimeout(function () {
                range = document.createRange(); //range object
                range.selectNodeContents(el); //sets Range
                sel.removeAllRanges(); //remove all ranges from selection
                sel.addRange(range);//add Range to a Selection.
            }, 1);
            copyToClipboard()
        }

    } else if (document.selection) { //older ie
        sel = document.selection.createRange();
        if (sel.text === '') { //no text selection
            range = document.body.createTextRange();//Creates TextRange object
            range.moveToElementText(el);//sets Range
            range.select(); //make selection.

        }
    }
}

export function copyToClipboard() {
    const cb = navigator.clipboard
    cb.writeText(window.getSelection())
    fadeOut()
}

export function fadeOut() {
    var fadeTarget = document.getElementById("copied");
    fadeTarget.style.display = "block"
    fadeTarget.style.opacity = 1;
    var fadeEffect = setInterval(function () {
        if (!fadeTarget.style.opacity) {
            fadeTarget.style.opacity = 1;
        }
        if (fadeTarget.style.opacity > 0) {
            fadeTarget.style.opacity -= 0.05;
        } else {
            clearInterval(fadeEffect);
        }
    }, 50);
}

export function copyImage(img) {
    let image = document.getElementById(img)
    image.setAttribute("contenteditable", true)
    selectText(img)
    const cb = navigator.clipboard
    cb.writeText(window.getSelection())
    fadeOut()
    image.setAttribute("contenteditable", false)
}