import {Box, CircularProgress, Typography} from "@mui/material";
import {useEffect, useState} from "react";

function CircularProgressWithContent(props) {
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress <= 100 ? prevProgress - 3 : 0));
        }, 100);

        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant={'determinate'}
                value={progress}
                thickness={1}
                style={{
                    color: '#000000'

                }}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">
                    {props.content}
                </Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithContent;