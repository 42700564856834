import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@mui/styles";
import StoTextInput from "../../utils/components/StoTextInput";
import {Typography} from "@mui/material";
import StoRadioCard from "../../utils/components/StoRadioCard";
import StoPrimaryButton from "../../utils/components/StoPrimaryButton";
import StoDialog from "../../utils/components/StoDialog";
import {createProject} from "../projectsSlice";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {MeasurementUtils} from "../../utils/MeasurementUtils";

const useStyle = makeStyles(() => ({
    root: {},
    radioGroup: {
        display: "flex",
        paddingTop: "0.6rem",
        justifyContent: "space-between",
    },
    radioGroupType: {
        display: "flex",
        paddingTop: "0.3rem",
        justifyContent: "space-between",
    },
    dialogActions: {
        height: "8.4rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },

}));

function CreateProjectDialog({open, setOpen, ...props}) {
    const classes = useStyle(props.theme);
    const [projectName, setProjectName] = React.useState("")
    const [selectedMetric, setSelectedMetric] = React.useState(MeasurementUtils.IMPERIAL)
    const [selectedProjectType, setSelectedProjectType] = React.useState(MeasurementUtils.STOPANEL)
    //const [country, setCountry] = React.useState('')
    const roles = useSelector(state => state.projects.userRoles)
    const [createTriggered, setCreateTriggered] = React.useState(false)

    const dispatch = useDispatch()
    let navigate = useNavigate();


    useEffect(() => {
        if (roles.includes('user:reno') && !roles.includes('user:panelizer')) {
            setSelectedProjectType(MeasurementUtils.STORENO)
        } else if (!roles.includes('user:reno') && roles.includes('user:panelizer')) {
            setSelectedProjectType(MeasurementUtils.STOPANEL)
        }

        window.addEventListener("keydown", handleKeyPress)
        return () => {
            window.removeEventListener("keydown", handleKeyPress)
        }
        //eslint-disable-next-line
    }, [projectName, createTriggered])
    const handleRadioCards = (event) => {
        setSelectedMetric(event.target.value)
    }
    /*
        const handleChange = (event) => {
            setCountry(event.target.value);
        };
    */

    const handleRadioCardsProject = (event) => {
        setSelectedProjectType(event.target.value)
    }

    const closeDialog = () => {
        setCreateTriggered(false)
        setProjectName("")
        setSelectedMetric(MeasurementUtils.IMPERIAL)
        setOpen(false)
        //setCountry('')
    }


    /*
        const countries = [
            'Germany',
            'America',
            'Canada',
            'Narnia'
        ];
    */
    const createNewProject = async () => {
        setCreateTriggered(true)
        const newProject = {
            name: projectName,
            unitSystem: selectedMetric,
            projectType: selectedProjectType,
            country: ""
        }
        if(createTriggered) return
        let res = await dispatch(createProject({project: newProject}))
        navigate(`/${res.payload.data.id}`)
        closeDialog()
    }

    const handleKeyPress = (event) => {
        if (event.keyCode === 13 || event.which === 13) {
            if (projectName !== "") {
                createNewProject()
            }
        }
    }

    return (
        <StoDialog open={open} title={"New Project"} onClose={closeDialog} maxWidth={"36rem"}
                   content={

                       <div>
                           {/*
                           <div style={{
                               display: 'flex',
                               flexFlow: 'row nowrap',
                               width: '100%',
                               justifyContent: 'space-between',
                               alignItems: 'center',
                               paddingBottom: '1rem',
                               paddingTop: '1rem'
                           }}>


                               <Typography variant={'body2'} style={{paddingBottom: "0.3rem", paddingTop: "0.5rem"}}>Building
                                   Location</Typography>
                               <FormControl sx={{m: 1, minWidth: 120}} size="small">

                                   <InputLabel id="select-country">Country</InputLabel>


                                   <Select
                                       labelId="select-country"
                                       id="demo-select-small"
                                       value={country}
                                       onChange={handleChange}
                                       autoWidth={true}
                                       label="Select Country"
                                   >
                                       {countries.map((name) => (

                                           <MenuItem
                                               key={name}
                                               value={name}
                                               style={{backgroundColor: '#F4F6F7'}}
                                               onMouseEnter={(e) => e.target.style.backgroundColor = '#FFD700'}
                                               onMouseLeave={(e) => e.target.style.backgroundColor = '#F4F6F7'}

                                           >
                                               {name}
                                           </MenuItem>
                                       ))}
                                   </Select>
                               </FormControl>

                           </div>
                           */}
                           {roles.includes('user:reno') && roles.includes('user:panelizer') && (false) &&
                               <div className={classes.radioGroupType} onChange={handleRadioCardsProject}>


                                   <StoRadioCard value={MeasurementUtils.STORENO} selected={selectedProjectType}
                                                 title={"Renovation"}
                                   />


                                   <StoRadioCard value={MeasurementUtils.STOPANEL} selected={selectedProjectType}
                                                 title={"New Building"}

                                   />


                               </div>
                           }
                           <StoTextInput title={"Name"} value={projectName}
                                         placeholder={"Enter the Projectname here"}
                                         onChange={(event) => setProjectName(event.target.value)}/>
                           <Typography variant={"subtitle1"}>{"Measurement system"}</Typography>
                           <div className={classes.radioGroup} onChange={handleRadioCards}>
                               <StoRadioCard value={MeasurementUtils.IMPERIAL} selected={selectedMetric}
                                             title={"Imperial"}
                                             subtitle={"inch / foot"} type={'unit'}/>
                               <StoRadioCard value={MeasurementUtils.METRICAL} selected={selectedMetric}
                                             title={"Metric"}
                                             subtitle={"mm / cm / m"} type={'unit'}/>


                           </div>
                       </div>
                   }
                   actions={
                       <div className={classes.dialogActions}>
                           <StoPrimaryButton title={"Create Project"} onClick={createNewProject}
                                             disabled={projectName === ""}/>
                           <StoPrimaryButton title={"Cancel"} onClick={closeDialog} secondary/>
                       </div>
                   }/>
    )
        ;
}

CreateProjectDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};
CreateProjectDialog.defaultPropTypes = {};
export default CreateProjectDialog