import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {TextField} from "@mui/material";


const useStyle = makeStyles(_ => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: 'max-content',
    },
    textField: {
        height: '3.6rem !important',

        '& .MuiOutlinedInput-root': {
            height: '100%'
        }
    }
}));

function StoInput({
                      value, placeholder, style, onClick = () => {
    }, onChange = () => {
    }, onBlur = () => {
    }, autoFocus = false, selectAll = true, invokeNoChanges = false
                  }) {
    const classes = useStyle();

    const [inputValue, setInputValue] = React.useState(value)

    useEffect(() => {
        setInputValue(value)
    }, [value])

    function onKeyDown(event) {
        if (event.key === "Enter" ||
            event.key === "Escape") {
            onBlur(event)
        }
    }

    function onChangeValue(event) {
        setInputValue(event.target.value)
        onChange(event)
    }

    function onBlurValue(event) {
        if (event.target.value.toString() === value.toString() && !invokeNoChanges) {
            return
        }

        onBlur(event)
    }

    return (
        <div className={classes.root} style={style}>
            <TextField fullWidth size={"small"}
                       className={classes.textField}
                       variant="outlined"
                       value={inputValue}
                       onClick={onClick}
                       placeholder={placeholder}
                       onChange={onChangeValue}
                       onBlur={onBlurValue}
                       onFocus={event => selectAll ? event.target.select() : null}
                       autoFocus={autoFocus}
                       onKeyDown={onKeyDown}
            />
        </div>
    );
}

StoInput.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.string,
    padding: PropTypes.string,

};
StoInput.defaultProps = {
    padding: "2.4rem 0",
};
export default StoInput;
