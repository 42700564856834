import {makeStyles} from "@mui/styles";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {FloorMeasurementVariants, updateBuildingDimensions} from "./settingsSlice";

import SSL from '../assets/png/SSL.png';
import FFL from '../assets/png/FFL.png';
import StoImageOptions from "../utils/components/StoImageOptions";
import StoLengthInput from "../utils/components/StoLengthInput";

const useStyle = makeStyles(() => ({
    formular: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2rem',
        width: '52.2rem',
        gap: '1rem',
    },
}))

function Dimension() {
    const dispatch = useDispatch();
    const {projectId} = useParams();

    const {
        defaultFloorHeight,
        defaultStructuralSlabThickness,
        defaultFinishedFloorThickness,
        groundStructuralSlabThickness,
        groundFinishedFloorThickness,
        parapetHeight,
        floorMeasurementVariant} = useSelector(state => state.settings.settings.buildingDimensions)

    function updateDimension(setting, value) {
        const buildingDimensions = {}
        buildingDimensions[setting] = value

        dispatch(updateBuildingDimensions(
            {
                projectId: projectId,
                buildingDimensions: buildingDimensions
            }))
    }

    const isFinishFloorLevel = FloorMeasurementVariants.FINISH_FLOOR_LEVEL === floorMeasurementVariant

    const classes = useStyle();

    const options = [
        {
            option: FloorMeasurementVariants.STRUCTURAL_FLOOR_LEVEL,
            checked: !isFinishFloorLevel,
            subtitle: 'Structure Slab Level to\nStructural Slab Level',
            image: SSL,
        },
        {
            option: FloorMeasurementVariants.FINISH_FLOOR_LEVEL,
            checked: isFinishFloorLevel,
            subtitle: 'From Finished floor level to\nFinished Floor Level',
            image: FFL,
        }
    ]

    return <div>
        <Typography variant="h1">Dimensions</Typography>
        <div className={classes.formular}>
            <StoLengthInput title={"Default Floor height"}
                            onBlur={(value) => updateDimension("defaultFloorHeight", value)}
                            value={defaultFloorHeight}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoLengthInput title={"Default level structural slab thickness"}
                            onBlur={(value) => updateDimension("defaultStructuralSlabThickness", value)}
                            value={defaultStructuralSlabThickness}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoLengthInput title={"Default level finished floor thickness"}
                            onBlur={(value) => updateDimension("defaultFinishedFloorThickness", value)}
                            value={defaultFinishedFloorThickness}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoLengthInput title={"Ground level structural slab thickness"}
                            onBlur={(value) => updateDimension("groundStructuralSlabThickness", value)}
                            value={groundStructuralSlabThickness}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoLengthInput title={"Ground level finished floor thickness"}
                            onBlur={(value) => updateDimension("groundFinishedFloorThickness", value)}
                            value={groundFinishedFloorThickness}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoLengthInput title={"Parapet height"}
                            onBlur={(value) => updateDimension("parapetHeight", value)}
                            value={parapetHeight}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoImageOptions title={"Please select measurement system for level height"} options={options}
                             onChange={(option) => updateDimension("floorMeasurementVariant", options.findIndex(o => o.option === option))}/>

        </div>

    </div>

}

export default Dimension