import React from 'react'
import './export.css'
import {selectText} from './Helpers'


export const ExportProjectInfo = (props) => {

    const exportData = props.data


    return (
        <div>
            <table id="infoTable" className={"export-table"}>
                <tbody>
                <tr>
                    <td colSpan="2" className="infoblockTitle copytable"  onMouseDown={() => selectText("infoTable")}>
                        {exportData.projectInfo.name}
                    </td>
                </tr>
                <tr>
                    <td className="tlabel">Project Name</td>
                    <td className="tcontent">{exportData.projectInfo.name}</td>
                </tr>
                <tr>
                    <td className="tlabel">Country</td>
                    <td className="tcontent">{exportData.projectInfo.location.country}</td>
                </tr>
                <tr>
                    <td className="tlabel">City</td>
                    <td className="tcontent">{exportData.projectInfo.location.city}</td>
                </tr>
                <tr>
                    <td className="tlabel">Postal Code</td>
                    <td className="tcontent">{exportData.projectInfo.location.postCode}</td>
                </tr>
                <tr>
                    <td className="tlabel">Address</td>
                    <td className="tcontent">{exportData.projectInfo.location.address}</td>
                </tr>
                <tr>
                    <td className="tlabel">Investor</td>
                    <td className="tcontent">{exportData.projectInfo.investor}</td>
                </tr>
                <tr>
                    <td className="tlabel">Architect</td>
                    <td className="tcontent">{exportData.projectInfo.architect}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};