import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const getSchedulingSettings = createAsyncThunk('schedulingSettings/get', async ({projectId}) => {
    try {
        console.log('Get scheduling settings: ', projectId)
        return await axios.get(`/api/projects/${projectId}/schedulingSettings`)
    } catch (err) {
        return err.message
    }
})

export const resetSchedulingSettings = createAsyncThunk('schedulingSettings/reset', async ({projectId}) => {
    try {
        console.log('Reset scheduling settings: ', projectId)
        return await axios.post(`/api/projects/${projectId}/schedulingSettings/reset`)
    } catch (err) {
        return err.message
    }
})

export const updateSchedulingSettings = createAsyncThunk('schedulingSettings/update', async ({
                                                                                                 projectId,
                                                                                                 schedulingSettings
                                                                                             }) => {
    try {
        console.log('Update scheduling settings: ', schedulingSettings)
        return await axios.put(`/api/projects/${projectId}/schedulingSettings`, schedulingSettings)
    } catch (err) {
        return err.message
    }
})

export const updateSchedulingWorkPackageValue = createAsyncThunk('schedulingSettings/updateWorkPackage', async ({
                                                                                                                    projectId,
                                                                                                                    panelTypeId,
                                                                                                                    workPackageId,
                                                                                                                    value
                                                                                                                }) => {
    try {
        console.log('Update scheduling finish type value: ', panelTypeId, workPackageId, value)
        return await axios.put(`/api/projects/${projectId}/schedulingSettings/${panelTypeId}/workPackages/${workPackageId}`, {
            value: value
        })
    } catch (err) {
        return err.message
    }
})

export const updateSchedulingFinishTypeValue = createAsyncThunk('schedulingSettings/updateFinishType', async ({
                                                                                                                  projectId,
                                                                                                                  panelTypeId,
                                                                                                                  workPackageId,
                                                                                                                  finishTypeId,
                                                                                                                  value
                                                                                                              }) => {
    try {
        console.log('Update scheduling finish type value: ', panelTypeId, workPackageId, finishTypeId, value)
        return await axios.put(`/api/projects/${projectId}/schedulingSettings/${panelTypeId}/workPackages/${workPackageId}/finishTypes/${finishTypeId}`, {
            value: value
        })
    } catch (err) {
        return err.message
    }
})

export const saveSchedulingSettingsAsCompanyDefault = createAsyncThunk('schedulingSettingsCompany/save', async ({
                                                                                                                    companyId,
                                                                                                                    schedulingSettings
                                                                                                                }) => {
    try {
        return await axios.put(`api/company/scheduling`, schedulingSettings, {params: {companyId: companyId}})
    } catch (err) {
        return err.message
    }
})

export const resetSchedulingSettingsToCompanyDefault = createAsyncThunk('schedulingSettingsCompany/reset', async ({
                                                                                                                      projectId
                                                                                                                  }) => {
    try {
        return axios.get(`api/projects/${projectId}/schedulingSettings/reset/company`)
    } catch (err) {
        return err.message
    }
})

const schedulingSettingsSlice = createSlice({
    name: 'schedulingSettings',
    initialState: {
        schedulingSettings: {
        },
        ignoreUpdate: false,
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getSchedulingSettings.pending, (state) => {
                state.loading = true
                state.ignoreUpdate = false
            })
            .addCase(getSchedulingSettings.fulfilled, (state, action) => {
                state.schedulingSettings = action.payload.data
                state.ignoreUpdate = false
                state.loading = false
            })
            .addCase(getSchedulingSettings.rejected, (state) => {
                state.loading = false
                state.ignoreUpdate = false
            })
            .addCase(resetSchedulingSettings.pending, (state) => {
                state.loading = true
                state.ignoreUpdate = false
            })
            .addCase(resetSchedulingSettings.fulfilled, (state, action) => {
                state.schedulingSettings = action.payload.data
                state.loading = false
                state.ignoreUpdate = false
            })
            .addCase(resetSchedulingSettings.rejected, (state) => {
                state.loading = false
                state.ignoreUpdate = false
            })
            .addCase(updateSchedulingSettings.pending, (state) => {
                state.loading = true
                state.ignoreUpdate = false
            })
            .addCase(updateSchedulingSettings.fulfilled, (state, action) => {
                state.schedulingSettings = action.payload.data
                state.loading = false
                state.ignoreUpdate = true
            })
            .addCase(updateSchedulingSettings.rejected, (state) => {
                state.loading = false
                state.ignoreUpdate = false
            })
            .addCase(updateSchedulingWorkPackageValue.pending, (state) => {
                state.loading = true
                state.ignoreUpdate = false
            })
            .addCase(updateSchedulingWorkPackageValue.fulfilled, (state, action) => {
                state.schedulingSettings = action.payload.data
                state.loading = false
                state.ignoreUpdate = false
            })
            .addCase(updateSchedulingWorkPackageValue.rejected, (state) => {
                state.loading = false
                state.ignoreUpdate = false
            })
            .addCase(updateSchedulingFinishTypeValue.pending, (state) => {
                state.loading = true
                state.ignoreUpdate = false
            })
            .addCase(updateSchedulingFinishTypeValue.fulfilled, (state, action) => {
                state.schedulingSettings = action.payload.data
                state.loading = false
                state.ignoreUpdate = false
            })
            .addCase(updateSchedulingFinishTypeValue.rejected, (state) => {
                state.loading = false
                state.ignoreUpdate = false
            })

            .addCase(resetSchedulingSettingsToCompanyDefault.pending, (state) => {
                state.loading = true
                state.ignoreUpdate = false
            })
            .addCase(resetSchedulingSettingsToCompanyDefault.fulfilled, (state, action) => {
                state.schedulingSettings = action.payload.data
                state.loading = false
                state.ignoreUpdate = false
            })
            .addCase(resetSchedulingSettingsToCompanyDefault.rejected, (state) => {
                state.loading = false
                state.ignoreUpdate = false
            })
    }
})

export default schedulingSettingsSlice.reducer