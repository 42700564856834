import axios from 'axios'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'


export const assignHiddenFace = createAsyncThunk('hiddenFaces/assign', async ({projectId, hiddenFace}) => {
    try {
        console.log('Update hidden face: ', projectId, hiddenFace)
        return await axios.post(`/api/projects/${projectId}/hiddenFaceMapping`, hiddenFace)
    } catch (err) {
        return err.message
    }
})

export const resetHiddenFaces = createAsyncThunk('hiddenFaces/reset', async ({projectId}) => {
    try {
        console.log('Reset hidden faces: ', projectId)
        return await axios.get(`/api/projects/${projectId}/hiddenFaceMapping/reset`)
    } catch (err) {
        return err.message
    }
})


const hiddenFacesSlice = createSlice({
    name: 'hiddenFaces',
    initialState: {
        hiddenFaces: [],
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(assignHiddenFace.pending, (state) => {
                state.loading = true
            })
            .addCase(assignHiddenFace.fulfilled, (state, {payload}) => {
                if (payload.data === undefined) state.hiddenFaces = []
                else state.hiddenFaces = payload.data
                state.loading = false
            })
            .addCase(assignHiddenFace.rejected, (state) => {
                state.loading = false
            })

            .addCase(resetHiddenFaces.pending, (state) => {
                state.loading = true
            })

            .addCase(resetHiddenFaces.fulfilled, (state, {payload}) => {
                if (payload.data === undefined) state.hiddenFaces = []
                else state.hiddenFaces = payload.data
                state.loading = false
            })
            .addCase(resetHiddenFaces.rejected, (state) => {
                state.loading = false
            })


    }
})

export default hiddenFacesSlice.reducer