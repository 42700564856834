import {makeStyles} from "@mui/styles";
import React from "react";
import PricingSummaryItem from "./PricingSummaryItem";




const useStyle = makeStyles(() => ({
    root: {
        display: 'block',
        width: '100%',
        //paddingBottom: '2rem',
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        },
        //width: '80rem',


    },

    box: {
        display: 'block',

        gridTemplateColumns: '1fr 1fr 1fr',
        justifyContent: 'center',
        "&.MuiButtonBase-root-MuiAccordionSummary-root": {
            alignItems: 'flex'
        }

    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        paddingLeft: '1.5em',
    },
    focusVisible: {
        backgroundColor: 'white!important',
    },
    backup: {
        paddingTop: '0.5rem',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: '50px',
        paddingBottom: '1rem'
        // paddingLeft: '7px'
    },
    level3: {},
    pricingCategoryFont: {
        fontFamily: 'FrutigerNext-Bd',
        fontSize: '16px !important',
        display: 'flex',
        alignItems: 'center',
    },
    tooltipIcon: {
        marginLeft: '0.5rem',
    },
    pricingItemFont: {

        fontSize: '16px !important',
        fontFamily: 'FrutigerNext !important',
        fontWeight: '400 !important'

    },
    placeHolder: {
        width: '16rem',
        height: '1rem',
    }

}));

function PricingSummaryResult({category,  unitSystem, isResult,  ...props}) {
    const classes = useStyle({...props.theme});


    return (

        <div className={classes.box}>
            <div style={{margin: "1rem 0 0 0"}}>
                {category.items && category.items.length > 0 && category.items.map((item, i) => {
                    return (
                        <div key={i} >
                            <PricingSummaryItem item={item} unitSystem={unitSystem}
                                                backgroundColor={i % 2 === 0 ? '#ffffff' : 'rgba(244, 246, 247, 0.50)'} isResult={isResult}></PricingSummaryItem>
                        </div>
                    )
                })}
            </div>
                {category.summaries && category.summaries.length > 0 && category.summaries.map((item, i) => {
                    return (
                        <div key={i}>
                            <PricingSummaryItem item={item} unitSystem={unitSystem}
                                                backgroundColor={i % 2 === 0 ? '#ffffff' : 'rgba(244, 246, 247, 0.50)'} lastElement={i+1 === category.summaries.length} isResult={isResult}></PricingSummaryItem>
                        </div>
                    )
                })}

        </div>


    );
}

export default PricingSummaryResult;