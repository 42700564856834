import axios from 'axios'
import {saveAs} from 'file-saver';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

export const getExportData = createAsyncThunk('projects/export', async ({projectId}) => {
    try {
        console.log(`Export Data: api/projects/${projectId}/export`)
        return await axios.get(`/api/projects/${projectId}/export`)
    } catch (err) {
        return err.message
    }
})

export const downloadPanelCsv = createAsyncThunk('projects/downloadPanelCsv', async ({ projectId }) => {
    try {
        // create random id with 10 digits
        const randomId = Math.floor(Math.random() * 10000000000);
        const response = await axios.get(`/api/projects/${projectId}/panelizationResult/getPanelListCsv?rid=`+randomId, {
            responseType: 'blob' // Wichtig, um die Antwort als Blob zu erhalten
        });

        // Verwendung von file-saver, um die Datei zu speichern
        saveAs(response.data, 'panellist.csv');
    } catch (err) {
        console.error(err);
        return err.message;
    }
});


const exportSlice = createSlice({
    name: 'export',
    initialState: {
        export: {
            export: {
                panelizationResult: {
                    amountOfPanels: 42,
                },
            },
        },
        loading: false,
        currentProjectId: null,
    },
    reducers: {
        //add frontend only actions
        setCurrentProjectId(state, action) {
            state.currentProjectId = action.payload
        },

    },
    extraReducers(builder) {
        builder
            .addCase(getExportData.pending, (state) => {
                state.loading = true
            })
            .addCase(getExportData.fulfilled, (state, action) => {
                state.loading = false
                console.log(action.payload.data)
                state.export = action.payload.data
                console.log(state.export)

            })
            .addCase(getExportData.rejected, (state) => {
                state.loading = false
                //todo: add error message
            })
    }

})

export default exportSlice.reducer