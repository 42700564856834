import {UnityContext} from "react-unity-webgl";

const path = 'unity/PanelizerCloud'
let unityContext = null

export function createUnityContext() {
    if (unityContext != null) {
        destroyUnityContext(unityContext)
    }

    unityContext = new UnityContext({
        loaderUrl: path + '.loader.js',
        dataUrl: path + '.data',
        frameworkUrl: path + '.framework.js',
        codeUrl: path + '.wasm',
        TOTAL_STACK: 12*1024*1024,
        webglContextAttributes: {
            preserveDrawingBuffer: true,
        }
    })

    return unityContext
}

export function getUnityContext() {
    return !unityContext ? createUnityContext() : unityContext
}

export function destroyUnityContext(unityContext, callback) {
    if (unityContext) {
        unityContext.quitUnityInstance().then(_ => {
            console.log('unity quit finish', callback)
            if (callback) {
                callback()
            }
        })
    }
    unityContext = null
}