import axios from "axios";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setFloor, setFloors} from "../unity/react-unity-adapter";
import {createFloor, deleteFloor, renameFloor, setNorthOrientation} from "../floor/floorSlice";

export const getFloors = createAsyncThunk('floors/getFloors', async ({projectId}) => {
    try {
        console.log("Get all floors")
        return await axios.get(`/api/projects/${projectId}/floorPlan`)
    } catch (err) {
        return err.message
    }
})

const floorsSlice = createSlice({
    name: 'floors',
    initialState: {
        floors: [],
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getFloors.pending, (state) => {
                state.loading = true
            })
            .addCase(getFloors.fulfilled, (state, action) => {
                state.loading = false
                state.floors = action.payload.data
                setFloors(state.floors)
            })
            .addCase(getFloors.rejected, (state) => {
                state.loading = false
            })
            .addCase(createFloor.fulfilled, (state, {payload}) => {
                const {data: floor} = payload
                if (!floor) {
                    return
                }
                const floors = state.floors
                floors.push(floor)
                state.floors = floors
                setFloor(floor)
            })
            .addCase(renameFloor.fulfilled, (state, {payload}) => {
                const {data: floor} = payload
                if (!floor) {
                    return
                }
                if (state.floors && state.floors.length) {
                    const floorInRedux = state.floors.find(f => f.id === floor.id)
                    if (floorInRedux) {
                        floorInRedux.name = floor.name
                    }
                    setFloor(floor)
                }
            })
            .addCase(deleteFloor.fulfilled, (state, {payload}) => {
                state.floors = state.floors.filter(f => f.id !== payload.data.id)
            })

            //region North Orientation

            .addCase(setNorthOrientation.pending, (state) => {
                state.loading = true
            })
            .addCase(setNorthOrientation.fulfilled, (state, {payload}) => {
                state.floors = payload.data
                state.loading = false
                setFloors(state.floors)
            })
            .addCase(setNorthOrientation.rejected, (state) => {
                state.loading = false
            })

    }
})

export default floorsSlice.reducer
