import {useDispatch, useSelector} from "react-redux";
import {Fragment, useEffect, useState} from "react";
import {Collapse, Typography} from "@mui/material";
import SchedulingWorkPackageSettings from "./Settings/SchedulingWorkPackageSettings";
import {updateSchedulingFinishTypeValue, updateSchedulingWorkPackageValue} from "./Settings/schedulingSettingsSlice";
import {useParams} from "react-router-dom";
import CollapseIcon from "../utils/icons/collapse.svg";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(_ => ({
    collapse: {
        display: "flex",
        alignItems: "center",
        gap: ".6rem",
        paddingTop: ".8rem",
        cursor: "pointer",
    },
    icon: {
        width: "1.6rem",
        height: "1.6rem",
    }
}))

let collapsePhase = {}
let collapseWorkPackage = {}

function SchedulingPanelTypeSettings({currentPanelType}) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const {projectId} = useParams();

    const {schedulingSettings} = useSelector(state => state.schedulingSettings)

    const [panelType, setPanelType] = useState(null)

    const [rerender, setRerender] = useState(false)

    useEffect(() => {
        if (!schedulingSettings) {
            return
        }

        const tmp = schedulingSettings.panelTypes.find(p => p.panelTypeName === currentPanelType)
        setPanelType(tmp)

        const tmpCollapsePhase = collapsePhase

        schedulingSettings.phases.forEach(phase => {
            if (!tmpCollapsePhase[phase.id]) {
                tmpCollapsePhase[phase.id] = false
            }
        })

        const tmpCollapseWorkPackage = collapseWorkPackage

        schedulingSettings.phases.flatMap(phase => phase.workPackages).forEach(workPackage => {
            if (!tmpCollapseWorkPackage[workPackage.id]) {
                tmpCollapseWorkPackage[workPackage.id] = false
            }
        })

        collapsePhase = tmpCollapsePhase
        collapseWorkPackage = tmpCollapseWorkPackage
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedulingSettings, currentPanelType])

    function onChangeFinishTypeValue(workPackageId, finishType, value) {
        dispatch(updateSchedulingFinishTypeValue({
            projectId: projectId,
            panelTypeId: panelType.panelTypeId,
            workPackageId: workPackageId,
            finishTypeId: finishType,
            value: value
        }))
    }

    function onChangeValue(workPackageId, value) {
        dispatch(updateSchedulingWorkPackageValue({
            projectId: projectId,
            panelTypeId: panelType.panelTypeId,
            workPackageId: workPackageId,
            value: value
        }))
    }

    function toggleCollapse(phase, altKey) {
        const tmpCollapsePhase = collapsePhase
        const isCollapsed = !tmpCollapsePhase[phase.id]
        if (altKey) {
            schedulingSettings.phases.forEach(phase => {
                tmpCollapsePhase[phase.id] = isCollapsed
            })
        } else {
            tmpCollapsePhase[phase.id] = isCollapsed
        }

        collapsePhase = tmpCollapsePhase
        setRerender(!rerender)
    }

    function toggleCollapseWorkPackage(workPackage, altKey) {
        const tmpCollapseWorkPackage = collapseWorkPackage
        const isCollapsed = !tmpCollapseWorkPackage[workPackage.workPackageId]

        if (altKey) {
            schedulingSettings.phases.flatMap(phase => phase.workPackages).forEach(wp => {
                tmpCollapseWorkPackage[wp.id] = isCollapsed
            })
        } else {
            tmpCollapseWorkPackage[workPackage.workPackageId] = isCollapsed
        }

        collapseWorkPackage = tmpCollapseWorkPackage
        setRerender(!rerender)
    }

    function getContent() {
        if (!panelType || !panelType.workPackages) {
            return
        }

        return schedulingSettings.phases.map((phase, index) => {
            const isCollapsed = collapsePhase[phase.id]

            const workPackageByPhase = panelType.workPackages.filter(wp => phase.workPackages.find(w => w.id === wp.workPackageId))

            return (<Fragment key={index}>
                <div className={classes.collapse} onClick={(event) => toggleCollapse(phase, event.altKey)}>
                    <img className={classes.icon} src={CollapseIcon} alt={'icon'}
                         style={{transform: !isCollapsed ? "rotate(0deg" : "rotate(270deg)"}}/>
                    <div>
                        <Typography
                            variant={"h4"}>{phase.name}</Typography>
                    </div>
                </div>
                <Collapse in={!isCollapsed}>
                    {workPackageByPhase.map((workPackage, index) => {
                        return <SchedulingWorkPackageSettings key={index} workPackage={workPackage}
                                                              onChangeFinishTypeValue={(finishTypeId, value) => onChangeFinishTypeValue(workPackage.workPackageId, finishTypeId, value)}
                                                              onChangeValue={(value) => onChangeValue(workPackage.workPackageId, value)}
                                                              collapse={collapseWorkPackage[workPackage.workPackageId]}
                                                              toggleCollapse={toggleCollapseWorkPackage}/>
                    })}
                </Collapse>
            </Fragment>)
        })
    }

    return (
        <div>
            <Typography variant={"h6"}>Timesettings</Typography>
            <Typography variant={"h1"} style={{paddingBottom: "2rem"}}>{currentPanelType}</Typography>
            <Typography variant={"h6"} whiteSpace={'pre-line'}>
                {"Estimate for each finishing the amount of panels or square feet\n" +
                    "your team is capable to craft per hour or day."}
            </Typography>
            {getContent()}
        </div>
    )

}

export default SchedulingPanelTypeSettings