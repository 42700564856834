import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyle = makeStyles(_ =>
    ({
        option: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
        },
        radioButton: {
            accentColor: '#ffd700'
        },
        image: ({
            width: "100%",
            height: "100%",

            marginBottom: "1rem",
            cursor: "pointer",
        }),
    }))

function StoImageOption({option, checked, image, onChange, subtitle}) {
    const classes = useStyle()
    
    return <div className={classes.option}
                onClick={_ => onChange(option)}>
        <img className={classes.image}
             style={{border: checked ? "solid .3rem #ffd700" : "solid .3rem transparent"}}
             alt={image} src={image}/>
        <Typography variant={"subtitle1"} align={"center"}
                    whiteSpace={"pre-line"}>{subtitle}</Typography>
        <input className={classes.radioButton} type={"radio"}
               checked={checked}
               onChange={_ => onChange(option)}
               onClick={_ => onChange(option)}/>
    </div>
}

export default StoImageOption