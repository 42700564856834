import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@mui/styles";
import {IconButton, Typography} from "@mui/material";
import TrashIcon from "../icons/TrashIcon";
import theme from "../../theme";

const useStyle = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: '12.8rem',
        backgroundColor: theme.palette.neutral.white,
        border: `1px dashed ${theme.palette.neutral.primary40}`,
        borderRadius: "2px!important",
        boxShadow: 'none',
    },
    text: {
        display: "flex",
        position: "absolute",
        alignItems: 'center',
        zIndex: 1
    },
    secondaryText: {
        color: `${theme.palette.secondary.main}!important`,
        textDecoration: "underline"
    },
    input: {
        width: '100%',
        height: '100%',
        opacity: 0,
        zIndex: 2
    },
    fileText: {
        display: "flex",
        justifyContent: "space-between",
        width: "27rem",
        position: "absolute",
        alignItems: 'center',
        textAlign: "left",
        zIndex: 3
    },
    filename: {
        maxWidth: "25rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }

}));

function StoFileInput({onChange, onDelete, file, ...props}) {
    const classes = useStyle(props.theme);

    const handleDeleteFile = (e) => {
        e.stopPropagation();
        onDelete()
    }

    return (
        <div className={classes.root}>

            {file ?
                <div className={classes.fileText}>
                    <Typography variant={"subtitle1"} className={classes.filename}>{file.name}</Typography>
                    <IconButton size="small" onClick={handleDeleteFile}>
                        <TrashIcon width={"20px"} height={"20px"} color={theme.palette.secondary.main}/>
                    </IconButton>
                </div>
                :
                <div className={classes.text}>
                    <Typography variant={"subtitle1"}>{"Drag & Drop your files here or"}</Typography>
                    <Typography style={{color: "transparent"}}>{"_"}</Typography>
                    <Typography variant={"subtitle1"} className={classes.secondaryText}>{"browse"}</Typography>
                </div>
            }

            <input accept="application/pdf" type="file" className={classes.input}
                   onChange={onChange}/>
        </div>
    );
}

StoFileInput.propTypes = {
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    file: PropTypes.any
};
StoFileInput.defaultPropTypes = {};
export default StoFileInput;