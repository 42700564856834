import React from 'react'
//import {Avatar, Button, Popover, Typography} from "@mui/material";
import StoLogo from '../../stoPan_Logo@2x.svg';
import {makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ProfileIcon from '../../utils/icons/profile.svg'
import TextIcon from "../../utils/components/TextIcon";
import {Fade, Menu, MenuItem, Typography} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import StoDialog from "../../utils/components/StoDialog";
import StoTextInput from "../../utils/components/StoTextInput";
import StoPrimaryButton from "../../utils/components/StoPrimaryButton";
import {changePassword, shareProject} from "../projectsSlice";
import StoSnackbar from "../../utils/components/StoSnackbar";

const useStyles = makeStyles(theme => ({
        root: {
            position: 'relative',
            top: 0,
            background: theme.palette.neutral.primary10,
            height: '3rem',
            padding: '1rem 1.8rem',
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.neutral.greyLight}`,
            zIndex: theme.zIndex.drawer + 1,
            '& .MuiMenu-root .MuiMenu-list': {
                backgroundColor: 'white'
            }
        },
        stoLogo: {
            height: '2.2rem',
            position: 'absolute',
            top: '0',
            bottom: '0',
            margin: 'auto',
            cursor: 'pointer',
            userSelect: 'none'
        },
        buttonGroup: {
            display: "flex",
            margin: "auto 0 auto auto"
        },
        avatar: {
            width: "28px!important",
            height: "28px!important"
        },
        username: {
            paddingTop: "8px",
            paddingRight: "1rem"
        },
        projectName: {
            position: 'absolute',
            right: '13rem',
            userSelect: 'none',
            display: 'flex',
            alignItems: 'center'
        },

        profileIcon: {
            position: 'absolute',
            right: '0',
            paddingRight: '3rem',
            userSelect: 'none'

        },
        logout: {
            marginTop: "1.8rem",
            '& .MuiPaper-elevation8': {
                backgroundColor: theme.palette.neutral.primary10,
                boxShadow: "0px 2px 10px rgb(0,0,0,0.1)",
                blur: 10,
                border: `1px solid ${theme.palette.neutral.greyLight}`,
                borderRadius: "3px"
            }
        },
        logoutContent: {
            width: "20.6rem",
            height: "3.8rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 10px 0 20px",
            color: `${theme.palette.neutral.primary80}!important`,
            '&:hover': {
                backgroundColor: `${theme.palette.neutral.primary20}!important`
            }
        },

        '&.MuiListRoot': {
            backgroundColor: 'white'
        }

    }))
;

function Header({...props}) {

    const classes = useStyles(props.theme);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [changePwOpen, setChangePwOpen] = React.useState(false)
    const [newPassword, setNewPassword] = React.useState('')
    const [newPasswordR, setNewPasswordR] = React.useState('')
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const dispatch = useDispatch();

    const projectName = useSelector(state => state.projectInfo.projectInfo.name)
    const projectId = useSelector(state => state.projects.currentProjectId)
    const isSupport = useSelector(state => state.projects.userRoles).some(role => role === 'admin:support')
    const userId = useSelector(state => state.projects.userId)
    const userEmail = useSelector(state => state.projects.userEmail)

    const isOwnProject = useSelector(state => state.projects.project.creator) === userId

    const {logout} = useAuth0();

    const handleLogout = () => {
        logout({
            returnTo: window.location.origin,
        });
    };
    const getHeaderText = () => {
        const re = new RegExp("[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}")
        if (re.test(window.location.href)) {
            return projectName
        } else {
            return userEmail
        }
    }

    const isOverview = () => {
        const re = new RegExp("[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}")
        return !re.test(window.location.href)
    }

    /*
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    */
    const goHome = () => {
        navigate("/")
    }

    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePassword = () => {
        dispatch(changePassword({newPassword: newPassword}))
        closeChangePasswordDialog()

    }

    const handleChangePwClick = () => {
        setChangePwOpen(true)
    }
    const closeChangePasswordDialog = () => {
        setChangePwOpen(false)
        setNewPassword('')
        setNewPasswordR('')
        handleClose()
    }

    /*
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onLogout = () => {
        console.log("LOGOUT")
    }
    */

    const validatePassword = () => {
        if (newPassword.length < 8) {
            return false
        }
        const re = new RegExp("(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])")
        if (!re.test(newPassword)) {
            return false
        }
        if (newPassword === newPasswordR) {
            return true
        } else {
            return false
        }
    }

    const handleClick = () => {
        dispatch(shareProject({projectId: projectId, userId: userId, sharedFromSupport: true})).then(() => {
            setSnackbarOpen(true)
        })
    }


    return (
        <div className={classes.root}>
            <img className={classes.stoLogo} src={StoLogo} alt={'stoLogo'} onClick={goHome}/>
            {/*
            <div className={classes.buttonGroup} onClick={handleClick}>
                <Typography className={classes.username} variant={"body2"}>{"Daria Duisburg"}</Typography>
                <Avatar className={classes.avatar}/>
            </div>

            <Popover className={classes.logout}
                     id={"id"}
                     open={open}
                     anchorEl={anchorEl}
                     onClose={() => setAnchorEl(null)}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     marginThreshold={25}
            >
                <Button className={classes.logoutContent} onClick={onLogout}>
                    {"Logout"}
                    // <img src={LogoutIcon} alt={"Profile"}/>
                </Button>
            </Popover>
    */}
            {isOverview() ?
                <div className={classes.profileIcon}>
                    <TextIcon text={getHeaderText()} icon={ProfileIcon} onClick={(event) => handleProfileClick(event)}
                              altText={'profileIcon'} hideIcon={process.env.REACT_APP_PROFILE === 'dev'}></TextIcon>

                    <Menu

                        MenuListProps={{
                            'aria-labelledby': 'fade-button',
                        }}
                        autoFocus={true}

                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}

                    >
                        <MenuItem onClick={handleChangePwClick} style={{backgroundColor: '#F4F6F7'}}
                                  onMouseEnter={(e) => e.target.style.backgroundColor = '#FFD700'}
                                  onMouseLeave={(e) => e.target.style.backgroundColor = '#F4F6F7'}>Change
                            Password</MenuItem>
                        <MenuItem onClick={handleLogout} style={{backgroundColor: '#F4F6F7'}}
                                  onMouseEnter={(e) => e.target.style.backgroundColor = '#FFD700'}
                                  onMouseLeave={(e) => e.target.style.backgroundColor = '#F4F6F7'}>Logout</MenuItem>
                    </Menu>
                </div>
                :

                <div className={classes.projectName}>
                    {isSupport && !isOwnProject && <StoPrimaryButton onClick={handleClick} title={'Copy Project'}/>}
                    <Typography style={{paddingLeft: '1rem'}}>{getHeaderText()}</Typography>
                    <StoSnackbar horizontal={'center'} vertical={'top'} onClose={() => setSnackbarOpen(false)}
                                 message={'Project successfully copied '} open={snackbarOpen}
                                 duration={3000}></StoSnackbar>

                </div>
            }


            <StoDialog onClose={(event) => closeChangePasswordDialog(event)} title={""}
                       open={changePwOpen}
                       content={
                           <div>

                               <Typography variant={"h1"}
                                           className={classes.deleteProjectTitle}>{"Enter a new password"}</Typography>

                               <StoTextInput title={"New password"} value={newPassword || ''} tabIndex={2}
                                             icon={"password"}
                                             isPassword={true} showPassword={false}
                                             onChange={(event) => setNewPassword(event.target.value)}

                               />

                               <StoTextInput title={"Repeat new password"} value={newPasswordR || ''} tabIndex={2}
                                             icon={"password"}
                                             isPassword={true} showPassword={false}
                                             onChange={(event) => setNewPasswordR(event.target.value)}
                               />

                           </div>
                       }
                       actions={<div className={classes.dialogActions}>
                           <StoPrimaryButton onClick={(event) => handleChangePassword(event)}
                                             title={"Change Password"}
                                             disabled={!validatePassword()}
                           />
                       </div>}
            ></StoDialog>

        </div>
    )
}

export default Header;
