import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import {setPanelizationResult} from '../unity/react-unity-adapter'
import {saveAs} from 'file-saver';

export const getPanelizationResult = createAsyncThunk('panelizationResult/get', async ({projectId}) => {
    console.log("GETTING RESULT")
    try {
        return await axios.get(`/api/projects/${projectId}/panelizationResult`)
    } catch (err) {
        return err.message
    }
})

export const createPanelCsv = createAsyncThunk('panelizationResult/createPanelCsv', async ({projectId}) => {
    console.log("Creating PanelCsv")
    try {
        const response = await axios.get(`/api/projects/${projectId}/panelizationResult/getPanelListCsv`, {
            responseType: 'blob' // Wichtig, um die Antwort als Blob zu erhalten
        });

        // Verwendung von file-saver, um die Datei zu speichern
        saveAs(response.data, 'panels.csv');
    } catch (err) {
        return err.message
    }
})


const panelizationResultSlice = createSlice({
    name: 'panelizationResult',
    initialState: {
        panelizationResult: null,
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getPanelizationResult.pending, (state) => {
                state.loading = true
            })
            .addCase(getPanelizationResult.fulfilled, (state, {payload}) => {
                state.panelizationResult = payload.data
                state.loading = false
                setPanelizationResult(state.panelizationResult)
            })
            .addCase(getPanelizationResult.rejected, (state) => {
                state.loading = false
            })

    }
})

export default panelizationResultSlice.reducer